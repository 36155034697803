import styled from 'styled-components';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import ChevronDown from 'public/icons/chevron-down.svg';

const TabsListDesktop = styled.ul`
    display: none;

    ${mediaUp('tablet')} {
        display: block;
    }
`;

const TabsListMobile = styled.div`
    position: relative;
    height: ${rem(45)};
    background-color: ${p => p.theme.colors.gray('200')};
    margin-bottom: ${rem(35)};

    ${mediaUp('tablet')} {
        display: none;
    }

    &:before {
        content: '';
        width: 100%;
        height: ${rem(3)};
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: ${p => p.theme.gradient()};
    }

    select {
        width: 100%;
        padding: ${rem(15)};
        background: transparent;
        border: none;
        -webkit-appearance: none;
        font-weight: bold;
        text-transform: uppercase;

        &:focus,
        &:active {
            outline: 0;
        }
    }
`;

const Icon = styled(ChevronDown)`
    position: absolute;
    right: ${rem(10)};
    top: 50%;
    transform: translateY(-50%);
    width: ${rem(15)};
    color: ${p => p.theme.colors.primary};
`;

const Tab = styled.li`
    cursor: pointer;
`;

const Body = styled.div`
    &.hidden {
        display: none;
        opacity: 0;
    }
`;

const TabsWrapper = styled.div``;

export {
    TabsListDesktop,
    TabsListMobile,
    Tab,
    Body,
    TabsWrapper,
    Icon
}
