import { useEffect, useState } from "react";
import {TabsListDesktop, TabsListMobile, Tab, Body, TabsWrapper, Icon} from 'styles/components/tabs';

export default function Tabs({tabBody, tabs, activeFirst}) {
    const [current, setCurrent] = useState();

    const setCurrentOption = ({ target }) => {
        setCurrent(target.value);
    }

    useEffect(() => {
        if (activeFirst) setCurrent('tab-0');
    }, []);

    return <>
        {(tabs && tabs.length) ? (<TabsListDesktop>
            {tabs.map((tab, index) => (<Tab
                key={index}
                className={current === `tab-${index}` ? 'active' : 'hidden'}
                onClick={() => setCurrent(`tab-${index}`)}>
                {tab.title}
            </Tab>))}
        </TabsListDesktop>) : null}

        {(tabs && tabs.length) ? (<TabsListMobile>
            <select onChange={setCurrentOption}>
                {tabs.map((tab, index) => (<option key={index} value={`tab-${index}`}>
                    {tab.title}
                </option>))}
            </select>
            <Icon />
        </TabsListMobile>) : null}

        {(tabs && tabs.length) ? (<TabsWrapper>
            {tabs.map((tab, index) => (<Body
                key={index}
                className={current === `tab-${index}` ? 'active' : 'hidden'}>
                {typeof tabBody === 'function' ? tabBody(tab) : null}</Body>))}    
        </TabsWrapper>) : null}
    </>
}