import { theme } from 'providers/theme';
import rem from 'util/rem'

const ratio = (width, height) => `
    position: relative;
    max-width: ${rem(width)};
    max-height: ${rem(height)};
    font-size: 0;

    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        padding-top: ${((height / width) * 100)}%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const plusIcon = size => `
    display: block;
    position: relative;
    width: ${size};
    height: ${size};

    &:before,
    &:after {
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background-color: currentColor;
        transition: all ${theme.transitions.normal} ease;
    }

    &:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: ${rem(2)};
    }

    &:after {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: ${rem(2)};
    }
`;

export { ratio, plusIcon }