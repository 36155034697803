import {Picture as P} from 'styles/components/picture';

export default function Picture ({src, alt, data = null, size = 'large', fill = true, aspectRatio = 'auto'}) {
	if (data) {
		src = data.sizes[size];
		alt = data.alt;
	}

	return (
		<P fill={fill} aspectRatio={aspectRatio}>
			<img src={src} alt={alt}/>
		</P>
	);
}