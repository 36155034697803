import { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import rem from 'util/rem';

const LottieStripe = styled.div`
    pointer-events: none;
    position: absolute;

    > div {
        margin: 0 !important;
        min-width: ${rem(690)};
    }
`;

function StripeAnimation({ className, animationData }) {
    const lottieRef = useRef(null);
    const options = {
        loop: false,
        autoplay: false,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        if (lottieRef.current && lottieRef.current.anim) {
            const animation = lottieRef.current.anim;
            animation.goToAndStop(animation.totalFrames - 1, true); // Go to the last frame and stop
        }
    }, []);

    return (
        <LottieStripe className={className}>
            <Lottie
                ref={lottieRef}
                className="decoration"
                options={options}
            />
        </LottieStripe>
    );
}

export default StripeAnimation;