import styled, {css} from 'styled-components';

const ImgVariantAuto = css`
  display: block;
  width: 100%;
`;

const ImgVariantFill = css`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Picture = styled.picture`
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: ${p => p.fill && p.aspectRatio ? p.aspectRatio : 'auto'};

  img {
    ${p => p.fill ? ImgVariantFill : ImgVariantAuto};
    aspect-ratio: ${p => !p.fill && p.aspectRatio ? p.aspectRatio : 'auto'};
    user-select: none;
  }
`;

export default {Picture};
export {Picture};
