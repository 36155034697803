import styled from 'styled-components';
import fluid from 'util/fluid';
import Slider from 'components/slider';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';

export default Object.freeze({
	Section: styled.section`
        background-color: ${p => p.theme.colors.light};
        position: relative;
        z-index: 1;
        padding-top: ${fluid(50, 175)};
        padding-bottom: ${fluid(20, 100)};
	`,

    Heading: styled(motion.h3)`
        text-align: center;
        font-size: ${p => p.theme.fontSizes.h3};
        font-weight: 400;
    `,

    FrontSlider: styled.div`
        transform-origin: 0 0;
        background-color: ${p => p.theme.colors.primary};
        transform: rotate(-5deg);
        width: 105vw;
        transform: rotate(-5deg) translateX(${rem(-10)});
        margin-top: ${rem(-40)};

        ${mediaUp('phone')} {
            margin-top: 0;
        }
    `,

    BackSlider: styled.div`
        transform-origin: 0 0;
        background-image: ${p => p.theme.gradient()};
        transform: rotate(5deg);
        width: 103vw;
    `,

    Slider: styled(Slider)`
        padding-top: ${fluid(15, 25)};
        padding-bottom: ${fluid(15, 25)};
        margin-top: ${fluid(20, 60)};

        .swiper-wrapper {
            transition-timing-function:linear !important; 
        }

        .swiper-slide {
            height: ${fluid(22, 55)};
            width: max-content;
            margin-right: ${fluid(40, 100)};

            img {
                width: 100%;
                height: ${fluid(22, 55)};
                object-fit: contain;
            }
        }
    `,
});