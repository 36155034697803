import React, { useState } from 'react';
import {ProgressBarSliderWrapper, ProgressBarSlider} from 'styles/components/progress-bar';

export default function ProgressBar({ items, getInstance, control, showIndicator }) {
	const emptySlider = items => items.map((_, index) => <span key={index}></span>);

    return (
        <ProgressBarSliderWrapper>
            {/*<p>Click & drag for more</p>*/}
            <ProgressBarSlider
                items={emptySlider(items)}
                getInstance={getInstance}
                control={control}
                pagination={{type:'progressbar'}}
                showIndicator={showIndicator}
            />
        </ProgressBarSliderWrapper>
    )
}