import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import Decoration from 'public/icons/quarter-dashed-circle.svg';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import Slider from 'components/slider';
import { ProgressBarSlider, ProgressBarSliderWrapper } from 'styles/components/progress-bar';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-top: ${fluid(35, 170)};
        padding-bottom: ${fluid(40, 170)};

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 50%;
            background-color: ${p => p.theme.colors.gray('100')};
            z-index: -1;
        }
 
        ${ProgressBarSlider} {
            margin-top: ${fluid(10, 40)};
        }

        ${ProgressBarSliderWrapper} {
            ${mediaUp('phone')} {
                display: none;
            }
        }
	`,

    Feature: styled(motion.div)`
        ${mediaUp('phone')} {
            position: relative;
        }
    `,

    Decoration: styled(Decoration)`
        display: none;

        ${mediaUp('phone')} {
            position: absolute;
            display: block;
            color: ${p => p.theme.colors.secondary};
            width: ${fluid(150, 250, 'phone')};
        }
    `,

    Figure: styled.figure`
        position: relative;
        z-index: 2;
        width: ${fluid(280, 530)};
        height: ${fluid(315, 600)};
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${fluid(35, 50, 'phone-xsmall', 'phone')};

        ${mediaUp('phone')} {
            margin-bottom: 0;
            width: ${fluid(200, 530, 'phone')};
            height: ${fluid(250, 600, 'phone')};
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
        }
    `,

    Overline: styled.small`
        display: inline-block;
        color: ${p => p.theme.colors.gray('500')};
        margin-bottom: ${fluid(20, 25)};
    `,

    Text: styled.div`
        color: ${p => p.theme.colors.gray('500')};
        line-height: 1.4;
        font-size: ${p => p.theme.fontSizes.large};
    `,

    Title: styled.h3`
        margin-bottom: ${fluid(20, 45)};
        font-size: ${p => p.theme.fontSizes.h3};
        font-weight: 400;
    `,

    Slider: styled(Slider)`
        .swiper-wrapper {
            ${mediaUp('phone')} {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: ${fluid(350, 850, 'phone')};
                row-gap: ${fluid(15, 220, 'phone')};
            }
        }

        .swiper-slide {
            ${mediaDown('phone')} {
                &:not(.swiper-slide-active) {
                    opacity: 0.5;
                }
            }

            &:first-child, 
            &:nth-child(3) {
                ${mediaUp('phone')} {
                    text-align: right;
                }
            }

            &:nth-child(1) {
                .decoration {
                    left: calc(100% - ${rem(-30)});
                    top: 50%;
                    transform: translateY(0);
                }
            }
            
            &:nth-child(2) {
                .decoration {
                    right: calc(100% - ${rem(-30)});
                    top: 50%;
                    transform: translateY(0) scaleX(-1);
                }
            }

            &:nth-child(3) {
                .decoration {
                    left: calc(100% - ${rem(-30)});
                    top: 50%;
                    transform: translateY(-100%) scaleY(-1);
                }
            }

            &:nth-child(4) {
                .decoration {
                    right: calc(100% - ${rem(-30)});
                    top: 50%;
                    transform: translateY(-100%) scale(-1);
                }
            }
        }
    `,
});