import {HeaderProvider} from 'providers/header';
import {ModalsProvider} from 'providers/useModals';
import Script from 'next/script';

import Theme from 'providers/theme';

// All sections.
import 'sections/awards';
import 'sections/banner';
import 'sections/example';
import 'sections/intro';
import 'sections/mosaic-image-text';
import 'sections/post-content';
import 'sections/stats';
import 'sections/stats-2';
import 'sections/callout';
import 'sections/resources-callout';
import 'sections/accordion-tabs';
import 'sections/testimonials';
import 'sections/hero-2';
import 'sections/hero-3';
import 'sections/hero-4';
import 'sections/hero-5';
import 'sections/feature-columns-2';
import 'sections/feature-columns-3';
import 'sections/feature-columns-4';
import 'sections/job-openings';
import 'sections/image-features';
import 'sections/large-text-image';
import 'sections/text-columns';
import 'sections/text-image';
import 'sections/text-image-2';
import 'sections/text-image-columns';
import 'sections/text-slides';
import 'sections/table';
import 'sections/form-callout';
import 'sections/team';
import 'sections/careers-callout';
import 'sections/author-information';
import 'sections/video';
import 'sections/resources';
import 'sections/hero-resources';
import Head from 'next/head';

function MyApp ({Component, pageProps}) {
	return (
		<>
			<Head>
				<link rel="shortcut icon"
				      href="/favicon.png"/>
			</Head>

			<Script
				id="gtm-1"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=Xih4v9-dYQg_VfxxrUsRuA&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-PKJ8WV2');`}}
			/>

			{/* Hotjar Tracking Code for https://blocpower.io */}
			<Script
				id="hotjar"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{__html: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3003022,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}}
			/>

			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-PKJ8WV2&gtm_auth=Xih4v9-dYQg_VfxxrUsRuA&gtm_preview=env-1&gtm_cookies_win=x"
					height="0"
					width="0"
					style={{display: 'none', visibility: 'hidden'}}>
				</iframe>
			</noscript>

			<script type="text/javascript" strategy="lazyOnload" id="hs-script-loader" async defer src="//js.hs-scripts.com/20299415.js"></script>

			<ModalsProvider>
				<Theme>
					<HeaderProvider>
						<Component {...pageProps}/>
					</HeaderProvider>
				</Theme>
			</ModalsProvider>
		</>
	);
}

export default MyApp;
