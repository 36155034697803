import styled from 'styled-components';
import { ratio } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import Button from 'components/button';
import Wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';
import StripeAnimation from 'components/stripe-animation';

export default Object.freeze({
    Section: styled.section`
        position: relative;
        z-index: 2;
        background-color: ${p => p.theme.colors.light};
    `,

    Wrapper: styled(Wrapper)`
        position: relative;
    `,

    Main: styled.div`
        display: grid;
        row-gap: ${rem(35)};
        background-color: ${p => p.theme.colors.dark};
        min-height: 75vh;
        
        ${mediaUp('phone')} {
            column-gap: ${fluid(50, 120, 'phone')};
        }

        ${p => p.imageDirection == 'left' && `
            ${mediaUp('phone')} {                
                grid-template-columns: ${fluid(300, 410, 'phone')} 1fr;
                grid-template-areas: 'figure content';

                .content {
                    padding-left: 0;
                }
            }

            ${mediaUp('tablet')} {
                margin-right: ${rem(50)};
            }
        `}

        ${p => p.imageDirection == 'right' && `
            ${mediaUp('phone')} {
                grid-template-columns: 1fr ${fluid(300, 410, 'phone')};
                grid-template-areas: 'content figure';

                .content {
                    padding-right: 0;
                }
            }
            
            ${mediaUp('tablet')} {
                margin-left: ${rem(50)};
            }
        `}
	`,

    Content: styled.div`
        padding-left: ${fluid(20, 65, 'phone-xsmall', 'phone')};
        padding-right: ${fluid(20, 65, 'phone-xsmall', 'phone')};
        padding-top: ${rem(100)};

        ${mediaUp('phone')} {
            grid-area: content;
            padding-top: ${rem(200)};
            padding-bottom: ${fluid(50, 135, 'phone')};
        }

        ${mediaUp('desktop-small')} {
            display: grid;
            column-gap: ${rem(25)};
            grid-template-columns: 1fr 1fr;
            padding-top: ${fluid(150, 180, 'desktop-small')};
        }

        ${mediaUp('desktop-medium')} {
            display: grid;
            column-gap: ${rem(50)};
            grid-template-columns: 2fr 1fr;
            padding-top: ${fluid(50, 135, 'phone')};
        }
    `,

    Figure: styled.figure`
        ${ratio(768, 384)};

        max-width: none;
        order: 2;

        ${mediaUp('phone')} {
            ${ratio(410, 800)};

            grid-area: figure;
            position: relative;
            order: initial;
            max-height: 100%;
            z-index: 10;
        }
    `,

    Headline: styled.h1`
        position: relative;
        z-index: 1;
        font-weight: 900;
        font-size: ${fluid(30, 80)};
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        margin-bottom: ${rem(30)};

        ${mediaUp('phone')} {
            font-size: ${p => p.theme.fontSizes.d2};
        }
    `,

    Body: styled.div`
        position: relative;
        padding-left: ${rem(40)};

        ${mediaUp('phone')} {
            padding: 0;
        }
    `,

    DescriptionWrapper: styled.div`
        &:before {
            content: '';
            margin-bottom: ${fluid(5, 25)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.light};
            border-left: ${rem(1)} solid ${p => p.theme.colors.light};
        }
    `,

    Description: styled.div`
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.normal};
        margin-bottom: ${rem(30)};
        line-height: 1.4;
        
        ${mediaUp('tablet')} {
            font-size: ${p => p.theme.fontSizes.large};
        }
    `,

    Button: styled(Button)`
        font-size: ${fluid(12, 28)}
    `,

    Stripe: styled(StripeAnimation)`
        right: 0;
        top: 40vh;
        transform: scale(-1);

        ${mediaUp('tablet')} {
            top: 70%;
        }

        ${mediaUp('desktop-medium')} {
            top: 45%;
        }
    `,

    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        left: ${rem(30)};
        top: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        transform: scaleY(-1);
        z-index: 2;
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        left: ${rem(30)};
        bottom: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        z-index: 2;
    `, 
});