import styled from 'styled-components';
import {Tab, Body, TabsListMobile} from 'styles/components/tabs';
import {plusIcon} from 'styles/mixins';
import fluid from 'util/fluid';
import {mediaUp} from 'util/media-query';
import rem from 'util/rem';
import {motion} from 'framer-motion';
import Collapsible from 'components/collapsible';
import RectDots from 'public/images/rect-dots.svg';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        background-color: ${p => p.theme.colors.light};
	`,

	DecorativeTitle: styled(motion.h2)`
        font-size: ${fluid(35, 205)};
        font-weight: 900;
        color: ${p => p.theme.colors.primary};
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;

        ${mediaUp('phone-small')} {
            width: 100vw;
        }

        ${mediaUp('desktop-small')} {
            margin-left: ${rem(-125)};
        }
	`,

	SectionWrapper: styled.div`
        padding-top: ${fluid(50, 150)};
        padding-bottom: ${fluid(50, 150)};
        background-color: ${p => p.theme.colors.gray('100')};

        ${mediaUp('tablet')} {
            margin-left: ${fluid(75, 125, 'tablet')};
        }
	`,

	AccordionWrapper: styled.div`
        margin-top: ${fluid(40, 150)};
		padding: 0 ${fluid(10, 80)};
		min-height: ${fluid(10, 650)};

        ${mediaUp('tablet')} {
            display: grid;
            grid-template-columns: ${fluid(230, 430, 'tablet')} auto;
            column-gap: ${fluid(100, 145, 'tablet')};
        }

        ${Tab} {
            position: relative;
            font-size: ${p => p.theme.fontSizes.large};
            text-transform: uppercase;
            color: ${p => p.theme.colors.gray('500')};
            margin-bottom: ${fluid(10, 25)};
            padding-bottom: ${fluid(3, 8)};

            &:before {
                content: '';
                height: ${rem(3)};
                width: 0;
                transition: all ${p => p.theme.transitions.normal} ease-out;
                position: absolute;
                left: 0;
                bottom: 0;
                background-image: ${p => p.theme.gradient()};
            }

            &.active:before {
                width: 100%;
            }
        }

        ${Body} {
            padding-left: ${rem(10)};
            padding-right: ${rem(10)};

            ${mediaUp('tablet')} {
                padding-left: 0;
                padding-right: 0;
            }
        }

        ${TabsListMobile} {
            max-width: ${rem(580)};
        }
	`,

	Collapsible: styled(Collapsible)`
        margin-bottom: ${fluid(15, 90)};

        &.active {
            em:after {
                transform: rotate(90deg) translateX(-50%);
                opacity: 0;
            }
        }
	`,

	CollapsibleHeader: styled.header`
        display: grid;
        grid-template-columns: 1fr max-content;
        column-gap: ${rem(10)};
        cursor: pointer;

        &:before {
            content: '';
            margin-bottom: ${fluid(15, 30)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
            grid-column-start: 1;
            grid-column-end: 3;
        }
	`,

	Icon: styled.em`
        ${plusIcon(fluid(15, 30))};
	`,

	CollapsibleTitle: styled.h3`
        font-size: ${props => props.theme.fontSizes.h3};
        font-weight: 400;

        &:before {
            content: '';
            width: 100%;
            height: ${rem(2)};
        }
	`,

	CollapsibleBody: styled.div`
        margin-top: ${fluid(15, 50)};
        font-size: ${p => p.theme.fontSizes.xlarge};
        color: ${p => p.theme.colors.gray('600')};
        line-height: 1.4;
	`,

	Figure: styled.figure`
        position: relative;

        ${mediaUp('tablet')} {
            max-width: ${fluid(280, 580, 'tablet')};
            position: absolute;
            left: 0;
            bottom: -5%;
        }

        ${mediaUp('desktop-small')} {
            bottom: -15%;
        }
	`,

	FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${rem(30)};
        bottom: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.dark};
        z-index: 1;
	`,

	SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${rem(30)};
        top: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        transform: scaleY(-1);
        z-index: 1;
	`,
});