import styled from 'styled-components';
import rem from 'util/rem';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: ${p => p.isOpen ? 1 : 0};
  visibility: ${p => p.isOpen ? 'visible' : 'hidden'};
  pointer-events: ${p => p.isOpen ? 'unset' : 'none'};
  transition: opacity 500ms, visibility 500ms;
`;

const ModalInner = styled.div`
  position: absolute;

  ${p => p.fill ? `
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  ` : `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

const CloseButton = styled.div`
  position: absolute;
  top: ${rem(40)};
  right: ${rem(40)};
  z-index: 5;

  button {
    display: flex;
    align-items: center;
    color: currentColor;
    font-size: ${rem(18)};
    transition: color 300ms;

    i {
      position: relative;
      display: block;
      width: ${rem(20)};
      height: ${rem(20)};
      margin-left: 6px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 2px;
        background: currentColor;
        margin: -1px 0 0 -6px;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
`;

export default {Modal, ModalInner, CloseButton};
export {Modal};