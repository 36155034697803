import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/stats-2';
import Wrapper from 'components/wrapper';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function Stats2 ({fields}) {
	const {right_content, left_content, decorative_text, title_size} = fields;
	const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (<s.Section id={fields.anchor_id}>
		<Wrapper noPadding={true} size={'full'}>
			{decorative_text ? (
				<s.DecotativeText {...revealLeftVariants} ref={ref} animate={control} size={title_size} dangerouslySetInnerHTML={{__html: decorative_text}}/>) : null}
		</Wrapper>

		<Wrapper size={'full'}>
			<s.Content>
				<s.Left>
					{left_content.headline && <s.Title dangerouslySetInnerHTML={{__html: left_content.headline}}/>}
					<s.Description dangerouslySetInnerHTML={{__html: left_content.small_text}}/>
				</s.Left>

				{right_content.image && (
					<s.ImageWrapper>
						<s.Figure>
							<Image src={right_content.image.url} alt={right_content.image.alt} layout="fill"/>
						</s.Figure>
					</s.ImageWrapper>
				)}

				{right_content && right_content.stats && (
					<s.Stats>
						{right_content.stats.map((stat, index) => <s.Stat key={index}>
							{stat.value && <s.StatValue>{stat.value}</s.StatValue>}
							{stat.description && <s.StatDescription>{stat.description}</s.StatDescription>}
						</s.Stat>)}
					</s.Stats>
				)}
			</s.Content>
			<s.FirstDots/>
			<s.SecondDots/>
		</Wrapper>

	</s.Section>);
}

sectionsManager.register('stats-2', Stats2);