import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/hero-2';
import Image from 'next/image';
import Button from 'components/button';
import animationData from 'animations/stripe-animation.json' assert {type: 'json'};
import IframeModal from 'modals/iframe';
import { useRef } from 'react';
import { mediaQuery } from 'util/media-query';

function Hero2({fields}) {
    const iframeModal = useRef();
	const {cta, headline, image, small_text, image_direction} = fields;

	const handleIframe = event => {
		// Bail if should not trigger the iframe on a modal
		if (!fields.open_iframe) return;

		event.preventDefault();
		openModal();
	}
	

    const openModal = () => {
        iframeModal.current.setIsOpen(true);
    }

    const setRef = ({setIsOpen}) => {
        iframeModal.current = {setIsOpen};
    }

	return (
		<>
			{fields.open_iframe ? <IframeModal
				id='iframe-hero-2'
				src={fields.iframe}
				getRef={setRef}
			/> : null}
			<s.Section id={fields.anchor_id}>
				<s.Wrapper size='full' noPadding={true}>
					<s.FirstDots />
					<s.SecondDots />
					<s.Main imageDirection={image_direction}>
						<s.Figure className='figure'>
							<Image src={image.url} alt={image.alt} blurDataURL={image.url} placeholder='blur' layout='fill'/>
						</s.Figure>
						<s.Content>
							{headline ? <s.Headline className='headline'>{headline}</s.Headline> : null}
							<s.Stripe animationData={animationData} />
							<s.Body className='body'>
								{small_text ? (<s.DescriptionWrapper>
									<s.Description dangerouslySetInnerHTML={{__html: small_text}} />
								</s.DescriptionWrapper>) : null}
								{cta.title && (<Button
									type={fields.open_iframe && 'button' }
									onClick={event => handleIframe(event)}
									styleName={'light'}
									href={cta.url}
									target={cta.target}
									iconName={'arrow-right'}>
									{cta.title}
								</Button>)}
							</s.Body>
						</s.Content>
					</s.Main>
				</s.Wrapper>
			</s.Section>
		</>
	);
}

sectionsManager.register('hero-2', Hero2);