import s from 'styles/modals/video';
import YouTube from 'react-youtube';
import { useEffect, useState } from 'react';

export default function VideoModal({ url, id, getRef }) {
    const [videoId, setVideoId] = useState();
    const [player, setPlayer] = useState();
    const youtubeIdRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    
    useEffect(() => {
        if (youtubeIdRegex.test(url)) {
            const [_, id] = url.match(youtubeIdRegex);
        
            setVideoId(id);
        }
    }, []);

    const close = () => {
        player.pauseVideo();
    }

    const onReady = event => {
        setPlayer(event.target);
    }

    return (
        <s.ModalStyled id={id} mRef={getRef} onClose={close}>
            <s.ModalInner fill={1}>
                <YouTube videoId={videoId} onReady={onReady} />
            </s.ModalInner>
        </s.ModalStyled>
    );
}