import sectionsManager from 'util/sections-manager';
import Image from 'next/image';
import s from 'styles/sections/hero-resources';
import Link from 'next/link';
import Button from 'components/button';

function HeroResources({fields}) {
	const {resources, image} = fields;

	return (
		<s.Section id={fields.anchor_id}>
			<s.TopDecoration />
			<s.FirstDots />
			<s.Wrapper size={'full'}>
				<s.Content>
					<s.TextWrapper>
						<s.MainInfo>
							{resources[0].terms.cat.length ? (<>
								<s.MainInfoType>
									{resources[0].terms.cat[0].title}
								</s.MainInfoType>
								<s.MainInfoDate>
									{resources[0].date_formatted}
								</s.MainInfoDate>
							</>) : null}
						</s.MainInfo>
						{resources ? (<s.Title dangerouslySetInnerHTML={{__html: resources[0].title}} />) : null}
						{resources ? (<s.Text dangerouslySetInnerHTML={{__html: resources[0].excerpt}} />) : null}
					</s.TextWrapper>
					<s.ImageWrapper>
						<s.Figure>
							{image.url && <Image blurDataURL={image.url} placeholder='blur' src={image.url} alt={image.alt} layout='fill' objectFit='cover' />}
						</s.Figure>
						<s.ButtonWrapper>
							<Button
								styleName={'light'}
								href={resources[0].permalink}
								target={'_self'}
								iconName={'arrow-right'}>
								Read full article
							</Button>
						</s.ButtonWrapper>
					</s.ImageWrapper>
					<s.FeaturedWrapper>
						<s.FeaturedLabel>Latest News</s.FeaturedLabel>
						{resources ? (
							<s.RowWrapper>
								{resources.slice(1).map((resource, index) => {
									return index < 3 ?
									<s.Row>
										<s.ResourceHeader>
											{resource.terms.cat.length ? (<>
												<s.ResourceType>
													{resource.terms.cat[0].title}
												</s.ResourceType>
												<s.Spacer/>
											</>) : null}
											<s.ResourceDate>
												{resource.date_formatted}
											</s.ResourceDate>
										</s.ResourceHeader>
										<Link passHref href={resource.permalink || '#'}>
											<s.ResourceTitle>
												<s.LinkWrapper>
													{resource.title.replace('&#8217;', "'")}<s.ArrowRight className={'arrow-link'}/>
												</s.LinkWrapper>
											</s.ResourceTitle>
										</Link>
									</s.Row>
									: null
								})}
							</s.RowWrapper>
						) : null}
					</s.FeaturedWrapper>
				</s.Content>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('hero-resources', HeroResources);