import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';
import Button from 'components/button';
import wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';
import Pipe from 'public/images/pipes.svg';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-bottom: ${fluid(50, 130)};

        ${p => `
            background-color: ${p.theme.colors[p.bgColor]};

            ${p.bgColor == 'light' && `
            .second-dots {
                color: ${p.theme.colors.light};
            }
            `}

            ${p.pullImageUp && `
            padding-top: 0 !important;

            .text-wrapper {
                padding-right: ${fluid(20, 40)};
                padding-left: ${fluid(20, 40)};

                ${mediaUp('phone')} {
                    padding-left: 0;
                }
            }

            ${mediaUp('desktop-small')} {
                .figure {
                    margin-top: calc(${fluid(50, 100, 'desktop-small')} * -1);
                }
            }
            `}
        `}
    `,
    
    TopDecoration: styled(Pipe)`
        position: absolute;
        top: 0;
        right: 0;
        width: ${fluid(320, 1090)};
        height: ${fluid(150, 680)};
        opacity: 0.15;
        pointer-events: none;
    `,
    
    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        top: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        z-index: 1;
        transform: scaleY(-1);
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        bottom: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        z-index: 1;
    `,

    Wrapper: styled(wrapper)``,

    Content: styled(motion.div)`
        display: grid;
        grid-template-columns: 1fr;
        row-gap: ${rem(35)};

        ${mediaUp('phone-medium')} {
            grid-template-columns: 1fr 1fr;
            column-gap: ${fluid(80, 300, 'phone-medium')};
        }

        ${p => `color: ${p.theme.colors[p.color]};`};

        ${p => (p.imagePosition === 'right') && `
        > div {
            order: 1;
        }

        figure {
            order: 2;
        }
        `}
    `,

    Text: styled.div`
        font-size: ${p => p.theme.fontSizes.xlarge};
        margin-top: ${fluid(25, 50)};
        line-height: 1.6;

        strong {
            color: ${p => p.theme.colors.tertiary};
        }
    `,
    
    Title: styled.div`
        font-size: ${p => p.theme.fontSizes.h2};
        margin-top: ${fluid(25, 50, 'phone-xsmall', 'phone-medium')};

        strong {
            color: ${p => p.theme.colors.tertiary};
        }

        ${mediaUp('phone-medium')} {
            margin-top: ${fluid(80, 170)};
        }
    `,

    TextWrapper: styled.div`    
        ${mediaUp('desktop-small')} {
            max-width: ${rem(625)};
        }
    `,

    ButtonWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(35)};
        margin-top: ${fluid(25, 65)};
    `,

    Button: styled(Button)`
    `,

    Figure: styled.figure`
        ${ratio(735, 870)};
    `,
});