import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/intro';
import Wrapper from 'components/wrapper';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function Intro({fields}) {
    const {large_text, small_text, cta, has_decoration} = fields;
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (<s.Section hasBgDecoration={has_decoration} id={fields.anchor_id}>
        <Wrapper size={'small'}>
            <s.Content
                {...revealLeftVariants}
                ref={ref}
                animate={control}
            >
            {large_text && (<s.Title dangerouslySetInnerHTML={{__html: large_text}} />)}
                <s.SideContent>
                    {small_text && (<s.Text dangerouslySetInnerHTML={{__html: small_text}} />)}
                    {cta.title && (<s.Button
                        styleName={'dark'}
                        href={cta.url}
                        target={cta.target}
                        iconName={'arrow-right'}>
                        {cta.title}
                    </s.Button>)}
                </s.SideContent>
           </s.Content>
        </Wrapper>
    </s.Section>);
}

sectionsManager.register('intro', Intro);