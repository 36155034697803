import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/feature-columns-3';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function FeatureColumns3({fields}) {
    const { image, content } = fields;
	const { columns, decorative_text, small_callout } = content;
	const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (
		<s.Section id={fields.anchor_id}>
			<s.Wrapper size={'full'} noPadding={true}>
				{image.url ? (<s.Figure>
					<Image src={image.url} alt={image.alt} layout={'fill'} />
				</s.Figure>) : null}
				{content ? (<s.Content {...revealLeftVariants} ref={ref} animate={control}>
					{decorative_text ? (
						<s.Headline dangerouslySetInnerHTML={{__html: decorative_text}} />
					) : null}
					{small_callout ? (
						<s.SmallCallout dangerouslySetInnerHTML={{__html: small_callout}} />
					) : null}
					{columns.length ? (
						<s.ColumnWrapper>
							{columns.map(column => (
								<s.Column>
									<s.ColumnTitle>{column.title}</s.ColumnTitle>
									<s.ColumnDescription>{column.text}</s.ColumnDescription>
								</s.Column>
							))}
						</s.ColumnWrapper>
					) : null}
				</s.Content>) : null}
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('feature-columns-3', FeatureColumns3);