import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/feature-columns-4';
import Wrapper from 'components/wrapper';
import {useState} from 'react';
import ProgressBar from 'components/progress-bar';
import Image from 'next/image';
import Button from 'components/button';
import {revealLeftVariants} from 'animations/framer-motion';
import {useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useEffect} from 'react';

function FeatureColumns4 ({fields}) {
	const {headline, columns} = fields;
	const [progressBarInstance, setProgressBarInstance] = useState();
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start('visible');
		}
	}, [control, inView]);

	const columnSlider = () => columns.map((column, index) => {
		return <>
			<s.Column key={index}>
				{column.title_type === 'image' && (<s.ColumnTitle>
					<Image src={column.logo.url} alt={column.logo.alt} width="150" height="70" objectFit="contain"/>
				</s.ColumnTitle>)}
				{column.title_type === 'text' && <s.ColumnTitle>{column.title}</s.ColumnTitle>}
				<s.ColumnDescription dangerouslySetInnerHTML={{__html: column.text}}/>
			</s.Column>
		</>;
	});

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size={'small'}>
				<s.IntroWrapper
					{...revealLeftVariants}
					ref={ref}
					animate={control}
				>
					{headline && (<s.Headline dangerouslySetInnerHTML={{__html: headline}}/>)}
					{fields.cta && (
						<Button
							href={fields.cta.url}
							target={fields.cta.target}>
							{fields.cta.title}
						</Button>
					)}
				</s.IntroWrapper>
			</Wrapper>

			<s.Wrapper size={'large'}>
				<s.Content>
					<s.SliderWrapper>
						{columns.length ? (<>
							<s.TextSlider
								spaceBetween={10}
								items={columnSlider()}
								control={progressBarInstance}
								swiperOptions={{allowTouchMove: true}}
								slidesPerView={1.2}
								mobileOnly={true}
								breakpoint={'tablet'}
							/>
							<ProgressBar
								items={columns}
								getInstance={setProgressBarInstance}
								className={'progress-bar'}
							/>
						</>) : null}
					</s.SliderWrapper>
				</s.Content>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('feature-columns-4', FeatureColumns4);