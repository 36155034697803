import sectionsManager from "util/sections-manager";
import s from "styles/sections/resources";
import Image from "next/image";
import Wrapper from "components/wrapper";
import { useEffect, useRef, useState } from "react";
import useSiteOptions from "providers/useOptions";
import wpApi from "util/wp-api";
import Link from "next/link";

function Resources({ fields }) {
  const siteOptions = useSiteOptions();
  const { default_thumbnail: defaultThumbnail } = siteOptions;
  const [query, setQuery] = useState(fields?.query?.base_query);
  const [posts, setPosts] = useState(fields?.query?.posts);
  const [pagination, setPagination] = useState(fields?.query?.pagination);
  const [page, setPage] = useState(1);
  const [allPosts, setAllPosts] = useState([]);
  const [category, setCategory] = useState();
  const [sorting, setSorting] = useState();
  const [search, setSearch] = useState();
  const [isSearchingByText, setIsSearchingByText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef();
  const catRef = useRef();
  const sortRef = useRef();

  const createCategoriesArray = (posts) => {
    let categories = new Map();

    posts.forEach((post) => {
      post.terms.cat.forEach((cat) => {
        if (!categories.has(cat.slug)) {
          categories.set(cat.slug, {
            slug: cat.slug,
            title: new Set([cat.title]),
          });
        } else {
          categories.get(cat.slug).title.add(cat.title);
        }
      });
    });

    return Array.from(categories.values()).map((cat) => ({
      slug: cat.slug,
      title: Array.from(cat.title).join(", "),
    }));
  };

  const fetchAllPosts = async () => {
    let allPosts = [];
    let page = 1;
    let hasMorePages = true;

    query = {
      post_type: "resource",
      posts_per_page: 1000,
      post_status: "publish",
      paged: page,
      fields: "ids",
    };

    while (hasMorePages) {
      const response = await wpApi.request("query", query);
      allPosts = allPosts.concat(response.posts);

      if (response.posts.length < 1000) {
        hasMorePages = false;
      } else {
        page += 1;
      }
    }

    return allPosts;
  };

  const updateDate = () => {
    const target = sortRef.current;
    setSorting(target.value);
    customPage(1);

    if (query && target.value) {
      query.date_query = [
        {
          after: target.value,
        },
      ];

      return;
    }

    if (query && query.date_query) {
      delete query.date_query;
    }
  };

  const updateCategory = () => {
    const target = catRef.current;
    setCategory(target.value);
    customPage(1);

    if (query && target.value) {
      query.tax_query = [
        {
          taxonomy: target.dataset.tax,
          terms: target.value,
          field: "slug",
        },
      ];

      return;
    }

    if (query && query.tax_query) {
      delete query.tax_query;
    }
  };

  const setSearchTerm = (event) => {
    event.preventDefault();

    const { value } = searchRef.current;

    if (!value) {
      searchRef.current.reportValidity();
      searchRef.current.setCustomValidity("You're missing me!");

      return;
    }

    // Update the query with the searched term
    query.s = value;

    setSearch(value);
    setIsSearchingByText(true);
  };

  const clearSearchBox = () => {
    setIsSearchingByText(false);
    setSearch("");

    searchRef.current.value = "";

    // Remove S term from the query
    if (query) {
      delete query.s;
    }
  };

  const prevPage = () => {
    setQuery((query) => Object.assign(query, pagination?.prev_query));
    setPage((page) => page - 1);
  };

  const nextPage = () => {
    setQuery((query) => Object.assign(query, pagination?.next_query));
    setPage((page) => page + 1);
  };

  const customPage = (paged) => {
    const newQuery = Object.assign(query, { paged });
    setQuery(newQuery);
    setPage(paged);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await wpApi.request("query", query);
      const allPosts = await fetchAllPosts(query);

      if (response.base_query) setQuery(response.base_query);
      setAllPosts(allPosts);
      setPagination(response.pagination);
      setPosts(response.posts);
      setIsLoading(false);
    })();
  }, [search, category, sorting, page]);

  const categoriesArray = createCategoriesArray(allPosts);

  const Posts = () =>
    posts && posts.length
      ? posts.map((post) => {
          const categories = post?.terms?.cat?.map((cat) => cat.title);

          return (
            <s.Post>
              <Link href={post.permalink || "#"}>
                <s.Link />
              </Link>
              <s.Thumbnail>
                <Image
                  src={post?.thumbnail?.sizes.medium ?? defaultThumbnail}
                  alt={post?.thumbnail?.alt}
                  layout="fill"
                  objectFit="cover"
                />
              </s.Thumbnail>
              <s.Content>
                <s.Hat>
                  {categories && categories.length ? (
                    <s.Taxonomy>{categories.join(",")}</s.Taxonomy>
                  ) : null}
                  <s.Date datetime={post.date}>{post.date_formatted}</s.Date>
                </s.Hat>
                {post.title ? (
                  <s.Title>{post.title.replace("&#8217;", "'")}</s.Title>
                ) : null}
                {post.excerpt ? (
                  <s.Description>{post.excerpt}</s.Description>
                ) : null}
              </s.Content>
            </s.Post>
          );
        })
      : null;

  const PagesList = () => {
    if (!pagination) return;

    const { current, max } = pagination;
    const pages = Array.from(Array(max - 1).keys());
    const offset = 3;
    const visiblePages = () => {
      // Workaround to ignore the first page to get into the below conditional.
      if (page == 1) return pages.slice(0, offset);

      let from = page - 2;
      let to = from + offset;

      if (page + offset > max) {
        from = pages.length - offset;
        to = pages.length;
      }

      return pages.slice(from, to);
    };

    return (
      <>
        {pages.length > offset + 1 ? (
          <>
            {" "}
            {/** Only show this step when there's more page than the offset size */}
            {page > 2 ? (
              <>
                <s.Page
                  onClick={() => customPage(1)}
                  className={page === 1 ? "active" : null}
                >
                  1
                </s.Page>
                {page > 3 ? (
                  <s.Page className="not-clickable">...</s.Page>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}

        {visiblePages().map((curPage) => {
          curPage += 1;
          return (
            <s.Page
              onClick={() => customPage(curPage)}
              className={curPage === current ? "active" : null}
            >
              {curPage}
            </s.Page>
          );
        })}

        {pages.length > offset + 1 ? (
          <>
            {page + offset <= max ? (
              <s.Page className="not-clickable">...</s.Page>
            ) : null}
          </>
        ) : null}

        <s.Page
          onClick={() => customPage(max)}
          className={page === max ? "active" : null}
        >
          {max}
        </s.Page>
      </>
    );
  };

  return (
    <s.Section id={fields.anchor_id}>
      <s.Filters>
        <Wrapper size="medium" className="wrapper">
          <s.Taxonomies>
            <s.SelectWrapper>
              <s.SelectLabel>Filter By</s.SelectLabel>
              <s.Select onChange={updateCategory} ref={catRef} data-tax="cat">
                <option value="">All</option>
                {categoriesArray.map((category) => (
                  <option value={category.slug}>{category.title}</option>
                ))}
              </s.Select>
              <s.ChevronDown />
            </s.SelectWrapper>

            <s.SelectWrapper>
              <s.SelectLabel>Sort By</s.SelectLabel>
              <s.Select ref={sortRef} onChange={updateDate}>
                <option value="">All</option>
                <option value="1 day ago">Today</option>
                <option value="7 days ago">Last 7 days</option>
                <option value="30 days ago">Last 30 days</option>
                <option value="1 year ago">Year ago</option>
              </s.Select>
              <s.ChevronDown />
            </s.SelectWrapper>
          </s.Taxonomies>

          <s.Search>
            <s.InputWrapper>
              <input
                type="text"
                placeholder="Type to Search"
                ref={searchRef}
                required
              />
              {isSearchingByText && !isLoading ? (
                <s.ClearText onClick={clearSearchBox}>Clear</s.ClearText>
              ) : null}
              {isLoading ? <s.SearchByTextLoader /> : null}
              {!isLoading ? (
                <s.SearchBtn type="submit" onClick={setSearchTerm}>
                  <s.SearchIcon />
                </s.SearchBtn>
              ) : null}
            </s.InputWrapper>
          </s.Search>
        </Wrapper>
      </s.Filters>

      <Wrapper>
        <s.List>
          {!isLoading ? <Posts /> : <s.PostsLoader />}
          {(!posts || !posts.length) && !isLoading ? (
            <s.NotFound>Nothing Found</s.NotFound>
          ) : null}
        </s.List>
        {!isLoading ? (
          <s.Pagination>
            {pagination?.prev_query ? (
              <>
                <s.Prev onClick={prevPage}>
                  <s.Arrow className="arrow" />
                </s.Prev>
              </>
            ) : null}
            <s.Pages>
              <PagesList />
            </s.Pages>
            {pagination?.next_query ? (
              <>
                <s.Next onClick={nextPage}>
                  <s.Arrow className="arrow" />
                </s.Next>
              </>
            ) : null}
          </s.Pagination>
        ) : null}
      </Wrapper>
    </s.Section>
  );
}

sectionsManager.register("resources", Resources);
