import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/text-image-columns';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function TextImage3({fields}) {
    const {image, content, columns, small_title} = fields;
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (<s.Section id={fields.anchor_id}>
        <s.Wrapper size={'full'} noPadding={true} className='wrapper'>
            <s.Content
                {...revealLeftVariants}
                ref={ref}
                animate={control}
            >
                <s.FirstColumns>
                    <s.Figure className='figure'>
                        {image.url && <Image src={image.url} alt={image.alt} layout='fill' objectFit='cover' />}
                        <s.FirstDots />
                        <s.SecondDots className='second-dots' />
                    </s.Figure>
                    <s.TextWrapper className='text-wrapper'>
                        {content.headline ? (<s.Title dangerouslySetInnerHTML={{__html: content.headline}} />) : null}
                        {content.text ? (<s.Text dangerouslySetInnerHTML={{__html: content.text}} />) : null}
                        {content.cta.title ? (<s.Button
                            styleName={'dark'}
                            href={content.cta.url}
                            target={content.cta.target}
                            iconName={'arrow-right'}>
                            {content.cta.title}
                        </s.Button>) : null}
                    </s.TextWrapper>
                </s.FirstColumns>

                <s.SecondColumns>
                    <s.Wrapper size={'small'} noPadding={true}>
                        <s.Columns>
                            {small_title ? (
                                <s.SmallTitleWrapper>
                                    <s.SmallTitle>{small_title}</s.SmallTitle>
                                </s.SmallTitleWrapper>
                            ) : null}
                            {columns.length ? columns.map(text => (
                                <s.Description dangerouslySetInnerHTML={{__html: text}} />
                            )) : null}
                        </s.Columns>
                    </s.Wrapper>
                </s.SecondColumns>
            </s.Content>
        </s.Wrapper>
    </s.Section>);
}

sectionsManager.register('text-image-columns', TextImage3);