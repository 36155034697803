import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/hero-3';
import Image from 'next/image';
import animationData from 'animations/stripe-animation-2.json' assert {type: 'json'};

function Hero3 ({fields}) {
	const {cta, headline, background_image, small_text} = fields;

	return (
		<s.Section id={fields.anchor_id}>
			<s.Wrapper size="full" noPadding={true}>
				<s.Main>
					<s.Figure className="figure">
						<Image
							src={background_image.url}
							alt={background_image.alt}
							placeholder="blur"
							blurDataURL={background_image.url}
							layout="fill"
							objectFit="cover"
						/>
					</s.Figure>
					<s.Content>
						{headline ? <s.Headline className="headline">{headline}</s.Headline> : null}
						<s.Stripe animationData={animationData}/>
						<s.Body className="body">
							{small_text ? (<s.DescriptionWrapper>
								<s.Description dangerouslySetInnerHTML={{__html: small_text}}/>
							</s.DescriptionWrapper>) : null}

							{cta && (
								<s.ButtonWrapper>
									{cta.title && (<s.Button
										styleName={'light'}
										href={cta.url}
										target={cta.target}
										iconName={'arrow-right'}>
										{cta.title}
									</s.Button>)}
								</s.ButtonWrapper>
							)}
						</s.Body>
					</s.Content>
				</s.Main>
			</s.Wrapper>
			<s.FirstDots/>
			<s.SecondDots/>
		</s.Section>
	);
}

sectionsManager.register('hero-3', Hero3);