import Button from 'components/button';
import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import { ratio } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import RectDots from 'public/images/rect-dots.svg';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        background: ${p => p.theme.colors.light};
	`,

    BgWrapper: styled.div`
        position: relative;
        z-index: 1;
    `,

    Wrapper: styled(Wrapper)`
        position: relative;
        display: grid;
        background: ${p => p.theme.colors.primary};

        ${mediaUp('phone')} {
            margin-right: auto;
            padding-left: ${fluid(30, 65, 'phone')};
            padding-right: ${fluid(30, 65, 'phone')};
            grid-template-columns: ${fluid(300, 850, 'phone')} auto;
        }
    `,

    TextWrapper: styled.div`
        padding-left: ${rem(25)};
        padding-right: ${rem(25)};

        ${mediaUp('phone')} {
            padding-bottom: ${fluid(30, 180, 'phone')};
            padding-left: 0;
            padding-right: 0;
        }
    `,

    Text: styled.div`
        margin-top: ${fluid(30, 60)};
        margin-bottom: ${fluid(30, 100)};
        font-size: ${p => p.theme.fontSizes.xlarge};
        color: ${p => p.theme.colors.light};
        line-height: 1.4;
    `,

    Headline: styled.h3`
        font-size: ${p => p.size === 'default' ? `${p.theme.fontSizes.d1}` : p.theme.fontSizes[p.size]};
        font-weight: 900;
        color: ${p => p.theme.colors.light};
        padding-top: ${fluid(50, 180)};
        padding-left: 1em;
        text-transform: uppercase;

        span {
            display: block;

            &:first-child {
                transform: translateX(${rem(-5)});

                ${mediaUp('phone')} {
                    transform: translateX(${rem(-80)});
                }
            }
        }
    `,

    Figure: styled.figure`
        ${ratio(940, 930)};

        transform: translateX(30%);
        margin-top: ${rem(-40)};

        ${mediaUp('phone')} {
            transform: none;
        }

        @media (max-width: 800px){
            margin-right: 90px;
        }
    `,

    Image: styled.img`
        pointer-events: none;
        object-fit: contain !important;
        object-position: bottom;
    `,

    Content: styled(motion.div)`
        order: -1;

        ${mediaUp('phone')} {
            order: initial;
        }
    `,

    Button: styled(Button)`
        ${mediaDown('phone-small')} {
            max-width: 75%;
        }
    `,
    
    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        bottom: calc(100% + ${rem(5)});
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        z-index: 1;
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        top: ${rem(5)};
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        transform: scaleY(-1);
        z-index: 1;
    `, 
});