import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp, mediaDown } from 'util/media-query';
import Button from 'components/button';
import { ratio } from 'styles/mixins';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import RectDots from 'public/images/rect-dots.svg';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-top: ${fluid(40, 100)};

        ${mediaUp('tablet')} {
            &::before {
                content: '';
                background-color: ${p => p.theme.colors.dark};
                position: absolute;
                left: 0;
                bottom: 0;
                height: ${fluid(250, 400, 'tablet')};
                width: 100%;
            }
        }
    `,

    Content: styled.div`
        display: grid;
        grid-template-columns: 1fr;
        row-gap: ${rem(35)};
        
        ${mediaUp('tablet')} {
            padding-top: ${fluid(40, 100)};
            padding-bottom: ${fluid(40, 100)};
            grid-template-columns: 1.5fr 1fr;
            column-gap: ${fluid(40, 100, 'phone')};
        }

        figure {
            margin-left: -10vw;
        }

        ${mediaDown('tablet')} {
            > div {
                order: 1;
            }
    
            figure {
                order: 2;
            }
        }

        ${p => (p.imagePosition === 'right') && `
            > div {
                order: 1;
            }

            figure {
                order: 2;
                margin-right: -10vw;
                margin-left: 0;
            }
        `}
    `,

    Text: styled.div`
        font-size: ${fluid(12, 36)};
        line-height: 1.4;
        padding-left: ${fluid(35, 65)};
        padding-bottom:  ${fluid(25, 55)};
        z-index: 2;
        position: relative;

        strong {
            color: ${p => p.theme.colors.tertiary};
        }

        ${mediaUp('tablet')} {
            margin-top: ${rem(-50)};
            padding-left: 0;
            padding-bottom: 0;
        }
    `,

    TextWrapper: styled(motion.div)`    
        ${mediaUp('desktop-small')} {
            max-width: ${rem(625)};
        }
    `,

    Button: styled(Button)`
        margin-top: ${fluid(25, 65)};
    `,

    Figure: styled.figure`
        ${ratio(250, 180)};
        z-index: 2;

        ${mediaUp('phone-small')} {
            ${ratio(350, 240)};
        }

        ${mediaUp('phone-medium')} {
            ${ratio(500, 275)};
        }

        ${mediaUp('phone')} {
            ${ratio(650, 350)};
        }

        ${mediaUp('tablet')} {
            ${ratio(1085, 689)};
        }
    `,

    ColumnContent: styled.div`
        padding-top: ${fluid(120, 180)};
        padding-bottom: ${fluid(60, 150)};
        background-color: ${p => p.theme.colors.gray('100')};
        margin-top: ${rem(-100)};
        position: relative;
        z-index: 1;
        
        ${mediaUp('phone-small')} {
            margin-top: ${rem(-130)};
        }

        ${mediaUp('phone-medium')} {
            margin-top: ${rem(-140)};
            padding-top: ${fluid(200, 250)};
        }
        
        ${mediaUp('phone')} {
            padding-top: ${fluid(120, 180)};
        }

        ${mediaUp('tablet')} {
            margin-top: ${rem(-220)};
            margin-right: ${rem(25)};
            padding-top: ${fluid(170, 230)};
        }

        ${mediaUp('desktop-medium')} {
            margin-right: ${rem(75)};
        }
    `,

    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${rem(10)};
        bottom: calc(100% + ${rem(5)});
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        z-index: 1;

        ${mediaUp('phone-medium')} {
            right: ${fluid(30, 80)};
        }
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${rem(10)};
        top: ${rem(5)};
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        z-index: 1;
        transform: scaleY(-1);

        ${mediaUp('phone-medium')} {
            right: ${fluid(30, 80)};
        }
    `,

    ContentWrapper: styled.div`
        margin-top: ${rem(-35)};
        
        ${mediaUp('phone-medium')} {
            margin-top: ${rem(-60)}
        }
        
        ${mediaUp('phone')} {
            margin-top: ${rem(-20)}
        }
        
        ${mediaUp('desktop-medium')} {
            margin-top: ${rem(-60)};
        }
    `,

    ColumnWrapper: styled(Wrapper)`
        display: grid;
        gap: ${fluid(40, 100)};
        margin-top: ${fluid(60, 120)};
        padding-left: ${fluid(40, 80)};
        padding-right: ${fluid(40, 80)};

        ${mediaUp('phone-medium')} {
            margin-top: ${fluid(60, 100)};
        }
        
        ${mediaUp('phone-small')} {
            grid-template-columns: 1fr 1fr;
        }

        ${mediaUp('desktop-small')} {
            grid-template-columns: none;
            grid-auto-flow: column;
            padding-left: ${fluid(20, 40)};
            padding-right: ${fluid(20, 40)};
        }
    `,

    Column: styled.div`
        ${mediaUp('tablet')} {
            min-width: ${rem(240)};
            padding-right: ${rem(20)};
        }
    `,

    ColumnTitle: styled.h3`
        font-size: ${props => props.theme.fontSizes.h3};
        font-weight: 400;
        text-align: center;
    `,

    ColumnText: styled.p`
        font-size: ${props => props.theme.fontSizes.large};
        line-height: 1.4;
        color: ${p => p.theme.colors.gray('500')};
        margin-top: ${fluid(15, 30)};
    `,

});