import Slider from "components/slider"
import styled from "styled-components"
import fluid from "util/fluid";
import { mediaUp } from "util/media-query";
import rem from "util/rem";

const ProgressBarSlider = styled(Slider)`
    margin-top: ${fluid(10, 20)};
`;

const ProgressBarSliderWrapper = styled.div`
    width: 100%;
    text-align: center;
    padding-right: ${fluid(10, 20, 'phone-xsmall', 'tablet')};
    
    ${mediaUp('tablet')} {
        padding-right: ${fluid(20, 130, 'tablet')};
    }
    
    .swiper-pagination-progressbar {
        display: flex !important;
        height: ${rem(3)};
        background-color: ${p => p.theme.colors.gray('200')};

        &-fill {
            display: block;
            transform-origin: left;
            width: 100%;
            height: 100%;
            background: ${p => p.theme.gradient()};
        }
    }

    p {
        text-transform: uppercase;
        font-size: ${p => p.theme.fontSizes.small};
        font-weight: 900;
        color: ${p => p.theme.colors.gray('500')};
    }
` 

export {ProgressBarSlider, ProgressBarSliderWrapper};