import styled from 'styled-components';
import fluid from 'util/fluid';
import rem from 'util/rem';

const ButtonIcon = styled.div`
  margin-left: ${fluid(10, 15)};
  width: ${fluid(12, 22)};
  transition: transform ease ${p => p.theme.transitions.normal};
  transform: translateX(0);

  @media (max-width: 800px){
    width: 1.5em;
  }
`;

const ButtonContents = styled.span`
  position: relative;
  font-size: ${rem(16)};
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  color: ${p => p.theme.colors[(p.styleName ?? 'dark')]};
  line-height: 1.45;
  padding-bottom: ${fluid(3, 5)};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${rem(2)};
    background-image: ${p => p.theme.gradient()};
  }

  &:hover {
    ${ButtonIcon} {
      transform: translateX(${rem(5)});
    }
  }

  @media (max-width: 800px){
    font-size: 12px;
    align-content: center;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export default {ButtonIcon, ButtonContents};