import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/post-content';
import useSiteOptions from 'providers/useOptions';
import Link from 'next/link';
import ImageDecoration from 'public/images/rect-dots.svg';
import BackLinkChevron from 'public/icons/back-link-chevron';
import Image from 'next/image';
import socialLinkIcons from 'util/social-link-icons';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function PostContent ({fields, pageData}) {
	const options = useSiteOptions();
	const featuredImage = fields.custom_image ? fields.custom_image : pageData.thumbnail;
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	const getSocialMediaIcon = name => {
		const Icon = socialLinkIcons[name];

		if (!Icon) return <></>;

		return <Icon/>;
	};

	return (
		<s.Section id={fields.anchor_id}>
			<s.PostIntro display_latest_posts={fields.display_latest_posts}>
				<s.PostIntroWrapper>
					<s.BackLink href="/posts">
						<s.BackLinkIcon>
							<BackLinkChevron/>
						</s.BackLinkIcon>
						Back to all resources
					</s.BackLink>

					<s.ContentWrapper>
						{fields.display_post_information ? (
							<s.Meta>
								{pageData.terms && pageData.terms.cat.length > 0 && (
									<s.MetaCategory>{pageData.terms.cat[0].title}</s.MetaCategory>
								)}
								<s.MetaDate>{pageData.date_formatted}</s.MetaDate>
							</s.Meta>
						) : null}

						<s.PostTitle>{pageData.title}</s.PostTitle>

						{/*{fields.display_author_information ? (*/}
						{/*	<s.AuthorInfo>Written by <strong>{pageData.author.name}</strong></s.AuthorInfo>*/}
						{/*) : null}*/}
					</s.ContentWrapper>

					<s.ImageWrapper>
						<s.Picture data={featuredImage} aspectRatio="25 / 9"/>
						<s.ImageDecoration>
							<ImageDecoration/>
						</s.ImageDecoration>
					</s.ImageWrapper>

					<s.SocialMediaWrapper>
						<s.ShareText>SHARE +</s.ShareText>

						{(options.social_media).map((media) => {
							return media.icon.value ?
								<Link key={media.icon.value} href={media.url || '#'} passHref>
									<s.MediaLink>{getSocialMediaIcon(media.icon.value)}</s.MediaLink>
								</Link>
								: null;
						})}
					</s.SocialMediaWrapper>
				</s.PostIntroWrapper>
			</s.PostIntro>

			<s.PostContent {...revealLeftVariants} ref={ref} animate={control} display_latest_posts={fields.display_latest_posts}>
				<s.AllContentWrapper size={'large'} display_latest_posts={fields.display_latest_posts}>
					<s.PostContentWrapper>
						<s.ContentTitle>{pageData.title}</s.ContentTitle>
						<s.Content dangerouslySetInnerHTML={{__html: fields.post_content}}/>
						{fields.pdf_download && (
							<s.Button
								styleName="dark"
								href={fields.pdf_download.url}
								download
								iconName="arrow-right">
								Download PDF
							</s.Button>
						)}
					</s.PostContentWrapper>

					{fields.posts.length > 0 && fields.display_latest_posts && (
						<s.FeaturedContentWrapper>
							<s.FeaturedSectionTitle>Latest Insights</s.FeaturedSectionTitle>
							<s.FeaturedColumns>
								{fields.posts.length ? fields.posts.map((data, index) => {
									return (
										<s.Column key={index}>
											<s.ColumnImageWrapper>
												<s.Figure>
													<Image src={data.thumbnail.url} alt={data.thumbnail.alt} layout={'fill'} objectFit={'cover'}/>
												</s.Figure>
											</s.ColumnImageWrapper>
											<s.ColumnContentWrapper>
												<s.ResourceHeader>
													<s.ResourceType>News</s.ResourceType>
													<s.Spacer/>
													<s.ResourceDate>{data.date_formatted}</s.ResourceDate>
												</s.ResourceHeader>
												<Link passHref href={data.permalink || '#'}>
													<s.ResourceTitle>
														<s.LinkWrapper>
															{data.title}
															<s.ArrowRight className={'arrow-link'}/>
														</s.LinkWrapper>
													</s.ResourceTitle>
												</Link>
											</s.ColumnContentWrapper>
										</s.Column>);
								}) : null}

								<s.Button
									styleName="dark"
									href="/posts"
									iconName="arrow-right">
									See all insights
								</s.Button>
							</s.FeaturedColumns>
						</s.FeaturedContentWrapper>
					)}
				</s.AllContentWrapper>
			</s.PostContent>
		</s.Section>
	);
}

sectionsManager.register('post-content', PostContent);
