import styled from 'styled-components';
import fluid from 'util/fluid';
import rem from 'util/rem';

export default styled.div`
    display: inline-block;
    position: relative;
    width: ${rem(80)};
    height: ${rem(80)};

    span {
        color: ${p => p.theme.colors.light};
        position: absolute;
        border: ${fluid(2, 4)} solid currentColor;
        opacity: 1;
        border-radius: 50%;
        animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

        &:nth-child(2) {
            animation-delay: -0.5s;
        }
    }

    @keyframes ripple {
        0% {
            top: ${rem(36)};
            left: ${rem(36)};
            width: 0;
            height: 0;
            opacity: 0;
        }

        4.9% {
            top: ${rem(36)};
            left: ${rem(36)};
            width: 0;
            height: 0;
            opacity: 0;
        }

        5% {
            top: ${rem(36)};
            left: ${rem(36)};
            width: 0;
            height: 0;
            opacity: 1;
        }

        100% {
            top: 0;
            left: 0;
            width: ${rem(72)};
            height: ${rem(72)};
            opacity: 0;
        }
    }
`;