import w from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import {mediaUp} from 'util/media-query';
import rem from 'util/rem';
import {motion} from 'framer-motion';

import PhoneIcon from 'public/icons/phone-icon.svg';
import RectDots from 'public/images/rect-dots.svg';

const FirstDots = styled(RectDots)`
    display: block;
    position: absolute;
    right: ${fluid(30, 80)};
    bottom: 100%;
    width: ${fluid(60, 130)};
    height: ${fluid(20, 45)};
    color: ${props => props.theme.colors.secondary};
    z-index: 1;
`;

const SecondDots = styled(RectDots)`
    display: block;
    position: absolute;
    right: ${fluid(30, 80)};
    top: 0;
    width: ${fluid(60, 130)};
    height: ${fluid(20, 45)};
    color: ${props => props.theme.colors.light};
    transform: scaleY(-1);
    z-index: 1;
`;

const Wrapper = styled(w)`
    display: grid;
    row-gap: ${rem(40)};
    position: relative;
    padding: ${fluid(50, 100)} ${fluid(30, 100)};

    ${mediaUp('phone')} {
        grid-template-columns: 1fr 1fr;
        column-gap: ${fluid(50, 100, 'phone')};
        row-gap: 0;
    }
`;

const LeftContent = styled.div`
    margin-left: auto;
`;

const Headline = styled(motion.h2)`
    font-size: ${p => p.theme.fontSizes.d1};
    font-weight: 900;
    margin-bottom: ${fluid(25, 80)};
    text-align: right;
    text-transform: uppercase;

    .trace {
        display: inline-block;
        position: relative;
        height: ${fluid(5, 11)};
        background-image: ${p => p.theme.gradient('to left')};
        width: 100%;
        max-width: 30%;
        vertical-align: middle;
    }
`;

const Content = styled.div`
    margin-left: auto;
    max-width: ${rem(470)};
`;

const SmallText = styled.p`
    font-size: ${p => p.theme.fontSizes.large};
    line-height: 1.4;
    margin-bottom: ${fluid(25, 88)};
`;

const ButtonWrapper = styled.div`
    display: flex;

    ${mediaUp('tablet')} {
        justify-content: flex-end;
    }
`;

const Icon = styled(PhoneIcon)`
    width: ${fluid(20, 32)};
    margin-right: ${fluid(10, 15)};
`;

const Section = styled.section`
	color: ${p => p.bgColor === 'white' ? 'black' : 'white'};
    position: relative;
    z-index: 1;

    ${p => {
        let bgColor;

        switch (p.bgColor) {
            case 'black':
            case 'white':
                bgColor = p.bgColor;
                break;

            default:
                bgColor = p.theme.colors.primary;
                break;
        }

        return `background-color: ${bgColor}`;
    }};

    ${SmallText} {
        a {
            transition: color 300ms;
            color: ${p => p.theme.colors[p.bgColor === 'white' ? 'primary' : 'tertiary']};

            &:hover {
                color: ${p => p.bgColor === 'white' ? 'black' : p.theme.colors.secondary};
            }
        }
    }
`;

const styles = {
	Section,
	FirstDots,
	SecondDots,
	Wrapper,
	LeftContent,
	Headline,
	Content,
	SmallText,
	ButtonWrapper,
	Icon,
};

export default styles;