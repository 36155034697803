import axios from 'axios';

const buildWPAPI = () => {
	const apiUri = process.env.NEXT_PUBLIC_API_URL;

	return Object.freeze({
		request,
		staticPaths,
		page,
		submitForm,
	});

	function requestUri (action) {
		return `${apiUri}?action=handle&a=${action}`;
	}

	async function staticPaths () {
		return request('static-paths');
	}

	async function page (path) {
		return request('page', {path});
	}

	async function submitForm (id, data) {
		return request('submit-form', {id, data});
	}

	async function request (action, body = {}) {
		try {
			const request = await axios.post(requestUri(action), body);
			const response = request.data;

			if (typeof response.result === 'undefined') {
				return response.status === 'SUCCESS';
			} else {
				return response.status === 'SUCCESS' ? response.result : null;
			}
		} catch (_e) {
			return null;
		}
	}
};

export default buildWPAPI();
