import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/callout';
import Wrapper from 'components/wrapper';
import Image from 'next/image';
import {revealLeftVariants} from 'animations/framer-motion';
import {useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useEffect} from 'react';

function TextImageCallout ({fields}) {
	const {image: imageGroup, content} = fields;

	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start('visible');
		}
	}, [control, inView]);

	if (!imageGroup || !content) return null;

	return (
		<s.Section id={fields.anchor_id}>
			<s.TopDecoration/>
			<s.FirstDots/>
			<s.SecondDots/>
			<Wrapper size={'full'} noPadding={true}>
				{content && content.decorative_text && <s.DecorativeText {...revealLeftVariants} ref={ref}
				                                                         animate={control}>{content.decorative_text}</s.DecorativeText>}
			</Wrapper>

			<s.Wrapper size={'full'}>
				<s.Content imagePosition={imageGroup.image_position}>
					<s.Figure pullTop={content.decorative_text.length}>
						{imageGroup.image.url &&
							<Image src={imageGroup.image.url} alt={imageGroup.image.url} layout="fill"/>}
						{content.headline && (<s.FigCaption dangerouslySetInnerHTML={{__html: content.headline}}/>)}
					</s.Figure>
					<s.TextWrapper>
						{content.text && (<s.Text dangerouslySetInnerHTML={{__html: content.text}}/>)}
						<s.ButtonWrapper>
							{content && content.ctas.length > 0 && content.ctas.map(cta =>
								<s.Button
									styleName={'light'}
									href={cta.url}
									target={cta.target}
									iconName={'arrow-right'}>
									{cta.title}
								</s.Button>,
							)}
						</s.ButtonWrapper>
					</s.TextWrapper>
				</s.Content>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('callout', TextImageCallout);