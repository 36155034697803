import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/mosaic-image-text';
import Wrapper from 'components/wrapper';
import Image from 'next/image';
import fluid from 'util/fluid';
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { opacityVariants, revealLeftVariants } from 'animations/framer-motion';

function MosaicImageText({fields}) {
    const {decorative_text, columns, image } = fields;
    const imageControl = useAnimation()
    const [imageRef, imageInView] = useInView()
    const headlineControl = useAnimation()
    const [headlineRef, headlineInView] = useInView()

    useEffect(() => {
        if (imageInView) {
            imageControl.start('visible');
        }

        if (headlineInView) {
            headlineControl.start('visible')
        }
    }, [imageControl, imageInView, headlineControl, headlineInView]);

	return (<s.Section id={fields.anchor_id}>
        <svg width="0" height="0">
            <defs>
                <clipPath id="mosaic">
                    <rect x="0" y="0" width={fluid(130, 680)} height={fluid(180, 830)} />
                    <rect x={fluid(140, 700)} y={fluid(30, 200)} width={fluid(160, 855)} height={fluid(110, 480)} />
                </clipPath>
            </defs>
        </svg>

        {image.url && (
            <motion.div
                {...opacityVariants}
                ref={imageRef}
                animate={imageControl}>
                <s.ImageWrapper noPadding={true} size={'medium'}>
                    <s.FirstDots />
                    <s.SecondDots />
                    <s.Figure>
                        <Image src={image.url} alt={image.alt} quality={50} priority="false" blurDataURL={image.url} placeholder="blur" layout="fill" objectFit='contain' />
                    </s.Figure>
                </s.ImageWrapper>
            </motion.div>
        )}

        <s.Content>
            <s.ContentWrapper>
                <Wrapper size={'full'} noPadding={true}>
                    {decorative_text && (<motion.div
                        {...revealLeftVariants}
                        ref={headlineRef}
                        animate={headlineControl}
                    >
                        <s.DecorativeText text={decorative_text} />
                    </motion.div>)}
                </Wrapper>
                {columns.length && (
                <s.ColumnWrapper size={'medium'}>
                    {columns.map(item => (
                        <s.Column>
                            {item.title && (<s.ColumnTitle>{item.title}</s.ColumnTitle>)}
                            {item.text && (<s.ColumnText>{item.text}</s.ColumnText>)}
                        </s.Column>
                    ))}
                </s.ColumnWrapper>)}
            </s.ContentWrapper>
        </s.Content>
    </s.Section>);
}

sectionsManager.register('feature-columns-1', MosaicImageText);