import s from 'styles/modals/iframe';

export default function Iframe ({ id, src = '', getRef }) {
	return (
		<s.ModalStyled id={id} mRef={getRef}>
            <s.ModalInner fill={1}>
                <div>
                    <iframe src={src} />
                </div>
            </s.ModalInner>
		</s.ModalStyled>
	);
}