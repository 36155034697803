import { mediaUp } from 'util/media-query';
import styled from 'styled-components';
import fluid from 'util/fluid';
import rem from 'util/rem';
import Button from 'components/button';

export default Object.freeze({
    Form: styled.form`
        display: grid;
        column-gap: ${rem(25)};
        margin-top: ${rem(20)};

        ${mediaUp('phone')} {
            align-items: center;
            grid-template-columns: 1fr max-content;
        }

        .geocoder {
            position: relative;

            svg {
                display: none;
            }
        }

        .mapboxgl-ctrl-geocoder--input {
            background-color: transparent;
            border: ${rem(1)} solid #F8F7F7;
            height: ${fluid(40, 55)};
            width: 100%;
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.normal};
            padding: ${fluid(5, 10)};
            margin-bottom: ${rem(20)};

            &:focus {
                outline: none;
            }

            ${mediaUp('phone')} {
                margin-bottom: 0;
            }
        }

        .mapboxgl-ctrl-geocoder--pin-right {
            display: none;
        }

        .suggestions-wrapper {
            position: absolute;
            width: 100%;
            z-index: 50;
            background: ${p => p.theme.colors.light};

            .suggestions li {
                padding: ${fluid(12, 18)};

                &:not(:last-child) {
                    border-bottom: 1px solid #eee;
                }
            }

            .suggestions a {
                cursor: pointer;

                &:hover {
                    color: ${p => p.theme.colors.gray('600')};
                }

                .mapboxgl-ctrl-geocoder--suggestion {
                    display: flex;
                    gap: ${rem(5)};
                    flex-wrap: wrap;
                }
            }

            .suggestions > div {
                font-size: 12px;
                padding: 8px ${fluid(8, 12)}
            }
        }
    `,

    Fieldset: styled.fieldset`
        position: relative;
    `,

    Label: styled.label`
        position: absolute;
        top: 0;
        left:  ${rem(10)};
        transform: translateY(-50%);
        font-size: ${p => p.theme.fontSizes.normal};
        color: ${p => p.theme.colors.tertiary};
        background-color: ${p => p.theme.colors.dark};
        padding-left: ${rem(5)};
        padding-right: ${rem(5)};
        font-style: italic;
    `,

    Input: styled.input`
        background-color: transparent;
        border: ${rem(1)} solid #F8F7F7;
        height: ${fluid(40, 55)};
        width: 100%;
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.normal};
        padding: ${fluid(5, 10)};
        margin-bottom: ${rem(20)};
        
        &:focus {
            outline: none;
        }

        ${mediaUp('phone')} {
            margin-bottom: 0;
        }
    `,
    
    Submit: styled(Button)`
        padding-bottom: 0;
        margin-right: ${rem(5)};
        font-size: ${fluid(16, 24)};

        &::after {
            content: none;
        }
    `,
})