import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';
import Button from 'components/button';
import wrapper from 'components/wrapper';


export default Object.freeze({
      Section: styled.section`
            position: relative;
            z-index: 1;
            padding-bottom: ${fluid(50, 130)};
            padding-top: ${fluid(30, 60)};
            margin-top: -1px;

            ${mediaUp('phone')} {
                  padding-top: ${fluid(20, 40)};
            }
      `,

      Wrapper: styled(wrapper)`
            background-color: ${p => p.theme.colors.primary};
            position: relative;
            margin-left: ${fluid(10, 20)};
            margin-right: ${fluid(10, 20)};

            ${mediaDown('phone')} {
                  padding-left: ${fluid(10, 20, 'phone-xsmall', 'phone')};
                  padding-right: ${fluid(10, 20, 'phone-xsmall', 'phone')};
            }

            ${mediaUp('tablet')} {
                  padding-top: ${fluid(100, 120)};
                  padding-bottom: ${fluid(80, 100)};
                  padding-left: ${fluid(110, 130)};
            }

            ${mediaUp('desktop-small')} {
                  padding-top: ${fluid(100, 120)};
                  padding-bottom: ${fluid(100, 120)};
                  padding-left: ${fluid(180, 200)};
            }

            ${mediaUp('desktop-large')} {
                  margin-left: auto;
                  margin-right: auto;
            }
      `,

      TopDecoration: styled.div`
            position: absolute;
            height: 50px;
            width: 100%;
            background: black;
            z-index: 0;
            left: 0px;
            top: 0;

            ${mediaUp('tablet')} {
                  height: 50%;
            }
      `,
      
      BottomDecoration: styled.div`
            display: none
            position: absolute;
            height: 50%;
            width: 100%;
            background: white;
            z-index: 0;
            left: 0px;
            bottom: 0;

            ${mediaUp('tablet')} {
                  display: block;
            }
      `,


      BlockDecoration: styled.div`
            position: absolute;
            height: 19px;
            width: 90px;
            background: black;
            z-index: 3;
            left: -1px;
            top: 0;

            ${mediaUp('phone-small')} {
                  width: 100px;
                  height: 15px
            }

            ${mediaUp('phone-medium')} {
                  width: 120px;
                  height: 12px
            }

            ${mediaUp('phone')} {
                  width: 150px;
                  height: 22px
            }

            ${mediaUp('tablet')} {
                  width: 80px;
                  height: 54%
            }

            ${mediaUp('desktop-small')} {
                  height: 55%
            }

            ${mediaUp('desktop-medium')} {
                  height: 56%
            }

            ${mediaUp('desktop-full')} {
                  height: 56.5%
            }
      `,

      Content: styled(motion.div)`
            display: grid;
            grid-template-columns: 1fr;
            row-gap: ${rem(35)};
            padding-top: ${fluid(80, 120)};
            padding-left: ${fluid(10, 20)};
            padding-right: ${fluid(10, 20)};

            ${mediaUp('phone')} {
                  padding-left: ${fluid(40, 80)};
                  padding-right: ${fluid(40, 80)}; 
            }

            ${mediaUp('tablet')} {
                  grid-template-columns: ${fluid(360, 535, 'phone')} 1fr;
                  column-gap: ${fluid(40, 80)};
                  padding-top: 0;
                  padding-left: 0;
                  padding-right: 0;
            }
      `,

      Headline: styled.h2`
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.h3};
            text-transform: uppercase;
      `,

      Text: styled.div`
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.xlarge};
            margin-top: ${fluid(20, 40)};
            line-height: 2;

            ${mediaUp('tablet')} {
                  padding-right: 0;
            }

            strong {
                  color: ${p => p.theme.colors.tertiary};
            }
      `,

      TextWrapper: styled.div`
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: ${fluid(30, 40, 'phone')};

            ${mediaUp('tablet')} {
                  padding-left: 0;
                  padding-right: ${fluid(30, 60)};
            }
      `,


      Button: styled(Button)`
            margin-top: ${fluid(25, 80)};
      `,

      ImageWrapper: styled.div`

      `,

      Figure: styled.figure`
            position: initial;
            ${ratio(1024, 750)};

            ${mediaUp('tablet')} {
                  ${ratio(512, 419)};
            }
      `,

      SocialMedia: styled.div`
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: auto 35px 35px 35px;
            align-items: center;
            margin-top: ${fluid(20, 40)};
            padding-top: ${fluid(40, 80)};
            padding-bottom: ${fluid(40, 80)};
            position: relative;

            ${mediaUp('phone-medium')} {
                  grid-template-columns: auto 60px 60px 45px;
            }

            ${mediaUp('tablet')} {
                  padding-bottom: 0;
            }

            ${mediaUp('desktop-small')} {
                  padding-top: ${fluid(20, 40)};
                  display: flex;
                  justify-content: start;
            }

            ${mediaUp('desktop-small')} {
                  padding-top: ${fluid(40, 80)};
            }

            &:before {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width:100%;
                  margin-bottom: ${rem(5)};
                  display: block;
                  height: ${rem(12)};
                  border-bottom: ${rem(1)} solid ${p => p.theme.colors.light};
                  border-left: ${rem(1)} solid ${p => p.theme.colors.light};
            }
      `,

      SocialShare: styled.p`
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.xlarge};
            padding-right: ${fluid(10, 20)};

            ${mediaUp('desktop-small')} {
                  padding-right: ${fluid(20, 40)};
            }

      `,
  
      MediaLink: styled.a`
            display: block;
            color: ${prop => prop.theme.colors.primary};
            background: linear-gradient(90deg, #FFC600 -20.49%, #FF9160 48.12%, #F649E1 113.93%);
            height: 25px;
            width: 25px;
            border-radius: 50%;
            position: relative;

            ${mediaUp('phone-medium')} {
                  width: 45px;
                  height: 45px;
            }

            &,
            svg {
                  transition: all ${prop => prop.theme.transitions.normal} ease;
            }

            svg {
                  width: 12px;
                  height: 12px;
                  position: absolute;
                  left: 26%;
                  top: 28%;

                  ${mediaUp('phone-medium')} {
                        width: 20px;
                        height: 20px;
                  }

            }

            .social-facebook_svg__icn-facebook{
                  left: 35%;
                  top: 28%;
            }


            &:not(:last-child) {
                  margin-right: ${fluid(10, 20)};

                  ${mediaUp('tablet')} {
                        margin-right: ${fluid(5, 10)};
                  }

                  ${mediaUp('desktop-small')} {
                        margin-right: ${fluid(25, 40)};
                  }
            }

            &:hover {
                  color: ${prop => prop.theme.colors.primary};
            
                  svg {
                        transform: translateY(${rem(-5)});
                  }
            }
      `,

});