import styled from 'styled-components';
import m from 'styles/components/modal';
import Modal from 'components/modal';
import { ratio } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';

const ModalStyled = styled(Modal)`
    .close-btn {
        background: ${p => p.theme.colors.light};
        padding: ${fluid(10, 20)};
    }
`

const ModalInner = styled(m.ModalInner)`
    background: rgba(0 0 0 / 50%);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        ${ratio(1280, 720)};

        &,
        iframe {
            width: 100%;
            height: 100%;
            
            ${mediaUp('phone')} {
                height: 80vw;

                ${mediaUp('phone')} {
                    height: 65vw;
                }

                ${mediaUp('tablet')} {
                    height: 100%;
                }
            }
        }

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            padding: ${fluid(10, 50)};
        }
    }
`;

export default {
    ModalInner,
    ModalStyled
}