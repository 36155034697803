import styled from 'styled-components';
import rem from 'util/rem';
import fluid from 'util/fluid';

export default styled.div`
    max-width: ${props => rem(props.maxWidth ?? (props.theme.wrapper[props.size] ?? props.theme.wrapper.large))};
    margin-left: auto;
    margin-right: auto;

    ${p => {
        if (p.noPadding) return;

        let gutter = p.gutter || p.theme.gutter;
        if (Array.isArray(gutter)) gutter = fluid(...gutter);

        return `
            padding-right: ${gutter};
            padding-left: ${gutter};
        `;
    }}
`;