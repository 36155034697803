import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/banner';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function Banner({fields}) {
    const {headline, image, text, cta, title_size} = fields;
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);
    
    const splitText = text => {
        const splitted = text.split(' ');
        const firstPos = splitted.slice(0, 2);
        const lastPos = splitted.slice(2, splitted.length);

        return [firstPos.join(' '), lastPos.join(' ')];
    }

    const renderHeadline = text => splitText(text)
        .map( text => (<span>{text}</span>));

	return (
		<s.Section id={fields.anchor_id}>
			<s.BgWrapper>
                <s.Wrapper noPadding={true} size={'large'}>
                    <s.FirstDots />
                    <s.SecondDots />
                {image.url && (
                    <s.Figure>
                        <s.Image src={image.url} width={840} height={930} blurDataURL={image.url} placeholder="blur" quality={50} priority="false"/>
                    </s.Figure>
                )}
                <s.Content
                    {...revealLeftVariants}
                    ref={ref}
                    animate={control}
                >
                        {headline && (<s.Headline size={title_size}>{renderHeadline(headline)}</s.Headline>)}
                        <s.TextWrapper>
                            {text && <s.Text dangerouslySetInnerHTML={{__html: text}} />}
                            {cta.title && (<s.Button
                                styleName={'light'}
                                href={cta.url}
                                target={cta.target}
                                iconName={'arrow-right'}>
                                {cta.title}
                            </s.Button>)}    
                        </s.TextWrapper>                
                </s.Content>
                </s.Wrapper>
            </s.BgWrapper>
		</s.Section>
	);
}

sectionsManager.register('banner', Banner);