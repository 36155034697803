import styled from 'styled-components';
import rem from 'util/rem';

const Body = styled.div`
    overflow: hidden;
    transition: height ${props => props.theme.transitions.normal} ease,
        opacity ${props => props.theme.transitions.normal} ease-in-out ${props => props.theme.transitions.normal};
`;

const Wrapper = styled.div`
    &.hidden ${Body} {
        height: 0;
        opacity: 0;
    }

    &.active ${Body} {
        height: ${props => props.height ? rem(props.height) : 'auto'};
        opacity: 1;
    }
`;

export { Body, Wrapper }
