import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import Wrapper from 'components/wrapper';
import Button from 'components/button';
import RectDots from 'public/images/rect-dots.svg';
import StripeAnimation from 'components/stripe-animation';

export default Object.freeze({
    Section: styled.section`
        position: relative;
        z-index: 1;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 37.46%, ${p => p.theme.colors.dark} 78.28%);
        padding-bottom: ${rem(30)};
    `,

    Wrapper: styled(Wrapper)`
        position: relative;
    `,

    Main: styled.div`
        display: grid;
    `,

    Content: styled.div`
        padding-top: ${rem(50)};
        grid-area: content;
        padding-left: ${fluid(25, 80)};
        padding-right: ${fluid(25, 80)};

        ${mediaUp('phone')} {
            padding-top: ${fluid(50, 135, 'phone')};
            padding-bottom: ${fluid(20, 65, 'phone')};
            padding-left: ${fluid(60, 120, 'tablet')};
            padding-right: ${fluid(60, 120, 'tablet')};
        }

        ${mediaUp('tablet')} {
            padding-left: ${fluid(160, 220, 'tablet')};
            padding-right: ${fluid(160, 220, 'tablet')};
        }

        ${mediaUp('desktop-small')} {
            padding-left: ${fluid(220, 280, 'desktop-small')};
            padding-right: ${fluid(220, 280, 'desktop-small')};
        }
    `,

    Figure: styled.figure`
        object-fit: cover;
        mix-blend-mode: color;
        grid-area: figure;
        position: relative;
        order: initial;
        max-height: 100%;
        z-index: 0;

        ${mediaDown('phone-medium')} {
            height:70vh;
        }
    `,

    Headline: styled.h1`
        position: relative;
        z-index: 1;
        margin-top: ${fluid(50, 100, 'phone-xsmall', 'desktop-small')};
        margin-bottom: ${fluid(60, 100, 'phone-xsmall', 'desktop-small')};
        font-size: ${p => p.theme.fontSizes.d1};
        font-weight: 900;
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        text-align: center;

        ${mediaDown('phone-medium')} {
            font-size: ${p => p.theme.fontSizes.h2};
            margin-top: ${fluid(60, 120, 'phone-xsmall', 'desktop-small')};
            margin-bottom: ${fluid(60, 120, 'phone-xsmall', 'desktop-small')};
        }

        ${mediaUp('desktop-small')} {
            margin-bottom: ${fluid(80, 150, 'desktop-small')};
        }

    `,

    Body: styled.div`
        position: relative;
        z-index: 1;
        
        ${mediaUp('desktop-small')} {
            display: grid;
            grid-auto-flow: row;
            align-items: center;
            column-gap: ${fluid(35, 100, 'tablet')};
            
        }
    `,

    DescriptionWrapper: styled.div`
        padding-left: ${fluid(20, 40)};
        padding-right: ${fluid(20, 40)};

        &:before {
            content: '';
            margin-bottom: ${rem(5)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.light};
            border-left: ${rem(1)} solid ${p => p.theme.colors.light};
        }
        
        ${mediaUp('tablet')} {
            padding-left:${fluid(55, 120, 'tablet')};
            padding-right:${fluid(55, 120, 'tablet')};
        }
        
        ${mediaUp('phone')}{
            &:before {
                margin-bottom: ${rem(20)}; 
            }
        }

        ${mediaDown('phone-medium')} {
            padding-left: 0;
            padding-right: 0;
        }
    `,

    Description: styled.div`
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.normal};
        margin-bottom: ${rem(10)};
        line-height: 1.4;

        
        ${mediaUp('phone')} {
            font-size: ${p => p.theme.fontSizes.xlarge};
            margin-bottom: 0;
            padding-left:${rem(20)};
            padding-right:${rem(20)};
        }
    `,

    Decoration: styled.img`
        pointer-events: none;
        position: absolute;
        top: 25%;
        right: -100px;
        transform: translateY(-50%);
        height: 100px;

        ${mediaUp('phone-medium')} {
            top: 40%
        }
        
        ${mediaUp('phone')} {
            right: -125px;
            height: 150px;
            top: 35%;
        }
        
        ${mediaUp('tablet')} {
            right: 0;
            top: 40%;
        }

        ${mediaUp('desktop-small')} {
            height: auto;
        }
    `,

    ButtonWrapper: styled.div`
        text-align:center;
    `,

    Button: styled(Button)`
        margin-top:${rem(20)};
        font-size: ${fluid(12, 32)};

        ${mediaUp('phone')} {
            margin-top:${rem(80)};
        }
    `,

    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        left: ${fluid(30, 80)};
        top: calc(100% + ${rem(5)});
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.dark};
        z-index: 1;
        transform: scale(-1, -1)
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        left: ${fluid(30, 80)};
        bottom: ${rem(5)};
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        z-index: 1;
        transform: scaleX(-1);
    `,

    Stripe: styled(StripeAnimation)`
        right: 0;
        top: 25%;

        ${mediaUp('phone')} {
            transform: scale(1.2);
            transform-origin: right center;
            top: 40%;
        }
    `,

});