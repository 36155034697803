import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/text-slides';
import ProgressBar from 'components/progress-bar';
import { useState } from 'react';
import Image from 'next/image';
import VideoModal from 'modals/video';
import localImg from 'util/local-img';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';


function TextSlides({fields}) {
	const {headline, text, slides} = fields;
	const [progressBarInstance, setProgressBarInstance] = useState();
	const videoRefs = new Map();
	const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	const openVideo = index => {
		const id = `video-${index}`;
		const video = videoRefs.get(id);
		video.setIsOpen(true);
	}

	const imageSlider = () => slides.map((slide, index) => {
		return <>
			{slide.video_url ? (
			<>
				<s.VideoButton onClick={() => openVideo(index)}>
					<Image src={localImg('watch-clip.png')} alt="Watch the video" layout='fill' />
				</s.VideoButton>
			</>) : null }
			<s.Figure key={index}>
				{slide.image.url && <Image src={slide.image.url} alt={slide.image.alt} layout='fill' />}
			</s.Figure>
		</>
	});

	const mobileSlider = () => slides.map((slide, index) => {
		return <>
			{slide.video_url ? (
				<s.VideoButton>
					<Image src={localImg('watch-clip.png')} alt="Watch the video" layout='fill' />
				</s.VideoButton>
			) : null }
			<s.Figure key={index}>
				{slide.image.url && <Image src={slide.image.url} alt={slide.image.alt} layout='fill' />}
			</s.Figure>
		</>
	});

	const ModalVideos = () => slides.map(({video_url}, index) => {
		const id = `video-${index}`;
		return <VideoModal
			id={id}
			url={video_url}
			getRef={({ setIsOpen, isOpen }) => videoRefs.set(id, { setIsOpen, isOpen })}
		/>
	});

	return (
		<>
			<ModalVideos />
			<s.Section id={fields.anchor_id}>
				<s.FirstDots />
				<s.SecondDots />
				<s.Wrapper size={'full'}>
					<s.Content 
						{...revealLeftVariants}
						ref={ref}
						animate={control}
					>
						<s.TextWrapper>
							{headline && (<s.Headline dangerouslySetInnerHTML={{__html: headline}} />)}
							<s.MobileSliderWrapper>
								{slides.length ? (<>
									<s.MobileSlider 
										items={mobileSlider()}
										control={progressBarInstance}
										swiperOptions={{allowTouchMove:true}}
										slidesPerView ={1}
										navigation={false}
										centeredSlides={true}
									/>
								</> ) : null}
							</s.MobileSliderWrapper>
							{text && (<s.Text dangerouslySetInnerHTML={{__html: text}} />)}
							<ProgressBar
								items={slides}
								getInstance={setProgressBarInstance}
								className={'progress-bar'}
							/>
						</s.TextWrapper>
						<s.SliderWrapper>
							{slides.length ? (<>
								<s.ImageSlider 
									items={imageSlider()}
									control={progressBarInstance}
									swiperOptions={{allowTouchMove:false}}
									slidesPerView ={1}
									navigation={true}
									centeredSlides={true}
									breakpoint={'tablet'}
								/>
							</> ) : null}
						</s.SliderWrapper>
					</s.Content>
				</s.Wrapper>
			</s.Section>
		</>
	);
}

sectionsManager.register('text-slides', TextSlides);