import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/hero-5';
import Image from 'next/image';
import animationData from 'animations/stripe-animation.json' assert {type: 'json'};

function Hero5({fields}) {
	const {cta, headline, image, small_text, image_direction} = fields;

	return (
		<s.Section id={fields.anchor_id}>
			<s.Wrapper size='full' noPadding={true}>
				<s.FirstDots />
				<s.SecondDots />
				<s.Main imageDirection={image_direction}>
					<s.Figure className='figure'>
						<Image src={image.url} alt={image.alt} placeholder="blur" blurDataURL={image.url} layout='fill' />
					</s.Figure>

					<s.Content className='content'>
						{headline ? <s.Headline className='headline' dangerouslySetInnerHTML={{__html: headline}} /> : null}

						<s.Stripe animationData={animationData} />

						<s.Body>
							{small_text ? (<s.DescriptionWrapper>
								<s.Description dangerouslySetInnerHTML={{__html: small_text}} />
							</s.DescriptionWrapper>) : null}
						</s.Body>

						{cta.title && (<s.Button
							styleName={'light'}
							href={cta.url}
							target={cta.target}
							iconName={'arrow-right'}>
							{cta.title}
						</s.Button>)}
					</s.Content>
				</s.Main>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('hero-5', Hero5);