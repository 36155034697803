import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/text-image';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function TextImage ({fields}) {
	const {image, content, image_position, background_color, pull_image_up} = fields;
	const textColor = (background_color != 'dark') ? 'dark' : 'light';
	const wrapper = pull_image_up ? 'full' : 'xlarge';
	const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (<s.Section bgColor={background_color} pullImageUp={pull_image_up} id={fields.anchor_id}>
		<s.TopDecoration/>
		<s.Wrapper size={wrapper} className="wrapper" noPadding={pull_image_up}>
			<s.Content {...revealLeftVariants} ref={ref} animate={control} imagePosition={image_position} color={textColor}>
				<s.Figure className="figure">
					{image.url && <Image src={image.url} alt={image.alt} layout="fill" objectFit="contain" objectPosition={`bottom ${image_position}`}/>}
					<s.FirstDots/>
					<s.SecondDots className="second-dots"/>
				</s.Figure>
				<s.TextWrapper className="text-wrapper">
					{content.headline ? (<s.Title dangerouslySetInnerHTML={{__html: content.headline}}/>) : null}
					{content.text ? (<s.Text dangerouslySetInnerHTML={{__html: content.text}}/>) : null}
					<s.ButtonWrapper>
						{content.ctas.length ? (content.ctas.map(cta =>
							<s.Button
								styleName={textColor}
								href={cta.url}
								target={cta.target}
								iconName={'arrow-right'}>
								{cta.title}
							</s.Button>,
						)) : null}
					</s.ButtonWrapper>
				</s.TextWrapper>
			</s.Content>
		</s.Wrapper>
	</s.Section>);
}

sectionsManager.register('text-image', TextImage);