import sectionsManager from 'util/sections-manager';
import { ArrowRightWrapper, CircledText, ReadMore, s, StyledArrowRight, StyledHalfCircleDashed, StyledCircledText } from 'styles/sections/resources-callout';
import Link from 'next/link';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function ResourcesCallout({ fields }) {
	const {headline, resources} = fields;
	const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	const buildResource = (resource, index) => (
		<s.Post key={index}>
			<Link href={resource.permalink || '#'} passHref>
				<s.CoverLink />
			</Link>
			<s.Content>
				{/*{resource.author && resource.author.name && (*/}
				{/*<s.Author>Written by <strong>{resource.author.name}</strong></s.Author>*/}
				{/*)}				*/}
				{resource.title && (<s.Title>{resource.title.replace('&#8217;', "'")}<s.Icon /></s.Title>)}
			</s.Content>
			<s.Figure className={'figure'}>
				<s.Thumbnail>
					<Image src={resource.thumbnail?.url} alt={resource.thumbnail?.alt} width={400} height={470} objectFit={'cover'} quality={50} priority="false" />
				</s.Thumbnail>
				<Link href={resource.permalink || '#'}>
					<ReadMore>
						<StyledCircledText />
						<ArrowRightWrapper>
							<StyledArrowRight />
						</ArrowRightWrapper>
						<StyledHalfCircleDashed />
					</ReadMore>
				</Link>
			</s.Figure>
		</s.Post>);

	return (
		<s.Section id={fields.anchor_id}>
			{headline && <s.Headline {...revealLeftVariants} ref={ref} animate={control}>{headline}</s.Headline>}

			<s.Wrapper size={'large'}>
				<s.PostList>
					{(resources && resources.length) && resources.map(buildResource)}
				</s.PostList>

				<div style={{ textAlign: 'center' }}>
					<s.Button
						styleName={'light'}
						href={'/posts'}
						target={'_self'}
						iconName={'arrow-right'}>See all Insights</s.Button>
				</div>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('resources-callout', ResourcesCallout);