import styled from 'styled-components';

import Play from 'public/icons/play.svg';
import fluid from 'util/fluid';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';

export default Object.freeze({
	Section: styled.section`
        padding-top: ${fluid(40, 80)};
    `,

    Headline: styled(motion.h2)`
        font-size: ${p => p.theme.fontSizes.h2};
        font-weight: 400;
        text-align: center;
        margin-bottom: ${fluid(25, 65)};
    `,

    Play: styled.button`
        position: absolute;
        right: ${fluid(50, 160)};
        bottom: 100%;
        transform: translateY(50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: ${fluid(35, 200)};
        height: ${fluid(35, 200)};
        background-color: ${p => p.theme.colors.primary};
        border: ${fluid(3, 10)} solid currentColor;
        color: ${p => p.theme.colors.light};
        z-index: 1;
        transition: all ${p => p.theme.transitions.normal} ease;
        cursor: pointer;

        &:hover {
            background-color: ${p => p.theme.colors.secondary};
        }
    `,

    PlayIcon: styled(Play)`
        width: ${fluid(6, 35)};
        color: currentColor;
    `,

    VideoWrapper: styled.div`
        position: relative;
        padding-left: ${fluid(20, 100)};
    `,

    Thumbnail: styled.figure`
        ${ratio(1800, 800)};
        
        margin-left: auto;

        img {
            pointer-events: none;
        }
    `
});