import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/example';

function Example({fields}) {
	return (
		<s.Section>
			{fields.title}
		</s.Section>
	);
}

sectionsManager.register('example', Example);