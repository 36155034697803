import {createContext, useContext, useState} from 'react';

const SiteModals = createContext({});

const ModalsProvider = ({children}) => {
	const [modals, setModals] = useState({});
	const [current, setCurrent] = useState(null);

	const get = id => modals[id] || false;

	const open = id => {
		if (
			typeof modals[id] === 'undefined' ||
			typeof modals[id].open === 'undefined'
		) return () => console.log(`Modal "${id}" or method "open" not found.`);

		return () => {
			closeCurrent();
			modals[id].open();
			setCurrent(id);
		};
	};

	const close = id => {
		if (
			typeof modals[id] === 'undefined' ||
			typeof modals[id].close === 'undefined'
		) return () => console.warn(`Modal "${id}" or method "close" not found.`);

		return () => {
			modals[id].close();
			setCurrent(null);
		};
	};

	const closeCurrent = () => {
		if (!current) return;
		close(current)();
	}

	const register = (id, methods) => {
		if (!id || typeof modals[id] !== 'undefined') return;

		setModals(list => ({...list, [id]: methods}));
	};

	return (
		<SiteModals.Provider value={{current, get, open, close, closeCurrent, register}}>
			{children}
		</SiteModals.Provider>
	);
};

const useModals = () => useContext(SiteModals);

export {ModalsProvider};
export default useModals;
