// Transitions
const transition = {
    transition: {
        type: 'spring',
        stiffness: 100,
        delay: 0.3
    }
}

// Animations
const revealLeft = {
    visible: {
        opacity: 1,
        x: 0,
        ...transition
    },
    hidden: {
        x: -50,
        opacity: 0
    },
}

const opacity = {
    visible: {
        opacity: 1,
        ...transition
    },
    hidden: {
        opacity: 0
    },
}

// Variants
const revealLeftVariants = {
    variants: revealLeft,
    initial: 'hidden',
}

const opacityVariants = {
    variants: opacity,
    initial: 'hidden',
}

export { revealLeftVariants, opacityVariants }