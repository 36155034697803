import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';
import Slider from 'components/slider';
import { ProgressBarSliderWrapper } from 'styles/components/progress-bar';
import Wrapper from 'components/wrapper';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        z-index: 1;
        background: ${p => p.theme.colors.light};
        padding-top: ${fluid(20, 120)};
        padding-bottom: ${fluid(50, 180)};
	`,

    Headline: styled(motion.div)`
        font-size: ${p => p.theme.fontSizes.h2};
        font-weight: 400;
        margin-bottom: ${rem(25)};
        margin-left: ${rem(10)};
        margin-right: ${rem(10)};

        ${mediaUp('tablet')} {
            font-size: ${p => p.theme.fontSizes.d1};
        }
    `,

    Wrapper: styled(Wrapper)`
        margin-left: ${fluid(10, 20, 'phone-xsmall', 'tablet')};
        margin-right: ${fluid(10, 20, 'phone-xsmall', 'tablet')};

        ${mediaUp('tablet')} {
            margin-left: ${fluid(20, 130, 'tablet')};
            margin-right: auto;
        }

        ${mediaUp('desktop-full')} {
            margin-left: auto;
        }
    `,

    SliderWrapper: styled.div`
        display: grid;
        align-items: center;

        ${mediaUp('tablet')} {
            grid-template-columns: ${fluid(300, 500, 'tablet')} 1fr;
            column-gap: ${fluid(70, 170, 'tablet')};
        }

        .swiper {
            width: 100%;
        }

        ${ProgressBarSliderWrapper} {
            ${mediaUp('tablet')} {
                grid-area: 2/1/2/3;
            }
        }
    `,

    TextSlider: styled(Slider)`
        position: relative;
        z-index: 4;
        padding-left: ${rem(10)};
        padding-right: ${rem(10)};
        
        ${mediaUp('tablet')} {
            padding-left: 0;
            padding-right: 0;
        }
    `,

    TextSliderTopContent: styled.div`
        position: relative;
        display: grid;
        column-gap: ${fluid(20, 40)};
        grid-template-columns: 1fr 1fr;
        margin-bottom: ${fluid(25, 75)};
        
        &:before {
            content: '';
            margin-bottom: ${fluid(10, 20)};
            display: block;
            height: ${rem(8)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
            grid-column-start: 1;
            grid-column-end: 3;
        }
    `,

    Label: styled.div`
        text-transform: uppercase;
        font-size; ${p => p.theme.fontSizes.small};
        font-weight: 800;
        color: ${p => p.theme.colors.gray('600')};
        margin-bottom: ${fluid(5, 20)};
    `,

    Value: styled.div`
        font-size; ${p => p.theme.fontSizes.small};
        line-height: 1.4;
        color: ${p => p.theme.colors.dark};
    `,

    Testimonial: styled.div`
        font-size: ${p => p.theme.fontSizes.h4};
        line-height: 1.4;

        strong {
            color: ${p => p.theme.colors.primary};
        }
    `,

    ThumbnailSlider: styled(Slider)`
        order: -1;

        .swiper-wrapper {
            margin-left: calc( (${fluid(300, 980, 'phone-xsmall', 'tablet')} - ${fluid(200, 340, 'phone-xsmall', 'tablet')})/ 2 * -1);
        }

        ${mediaUp('tablet')} {
            order: initial;

            .swiper-wrapper {
                /* 1st fluid is the full wrapper size */
                /* 2nd fluid is the right slider width */
                margin-left: calc( (${fluid(1005, 1775, 'tablet')} - ${fluid(635, 1250)})/ 2 * -1 ); 
            }
        }

        .swiper-slide {
            width: ${fluid(200, 460)};
            margin-right: ${fluid(10, 40, 'phone-xsmall', 'desktop-small')};
            margin-bottom: ${rem(25)};
            opacity: 0.5;
            transition: all ${p => p.theme.transitions.normal} ease;
            
            ${mediaUp('desktop-small')} {
                margin-right: ${fluid(50, 100, 'tablet')};
                transform-origin: center;
            }

            &:is(.swiper-slide-active, .swiper-slide-next) {
                opacity: 1;
            }

            &:not(.swiper-slide-active) {
                transform: scale(0.9);
            }
        }

        .swiper-nav-wrapper {
            position: absolute;
            top: 50%;
            left: ${fluid(200, 600)};
            transform: translate(-50%, -50%);
            z-index: 1;
        }
    `,

    Figure: styled.figure`
        ${ratio(200, 215)};

        max-width: none !important;
        max-height: none !important;
        margin-bottom: 0;

        ${mediaUp('tablet')} {
            ${ratio(460, 650)};
        }
    `,
});