import styled from 'styled-components';
import { ratio } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import Wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';
import StripeAnimation from 'components/stripe-animation';

export default Object.freeze({
    Section: styled.section`
        position: relative;
        z-index: 2;
        background-color: ${p => p.theme.colors.light};
    `,

    Wrapper: styled(Wrapper)`
        position: relative;
    `,

    Main: styled.div`
        display: grid;
        row-gap: ${rem(35)};
        background-color: ${p => p.theme.colors.dark};
        
        ${mediaUp('phone')} {
            column-gap: ${fluid(50, 120, 'phone')};
        }

        ${mediaUp('tablet')} {
            margin-right: ${rem(50)};
        }

        ${p => p.imageDirection == 'left' && `

            ${mediaUp('phone')} {                
                grid-template-columns: ${fluid(300, 800, 'phone')} 1fr;
                grid-template-areas: 'figure content';

                .figure {
                    margin-left: ${fluid(35, 125, 'phone')};
                }

                .headline {
                    padding-left: 0;
                    padding-right: ${fluid(35, 80, 'phone')};
                }

                .body {
                    padding-left: 0;
                }

                .decoration {
                    transform: scale(-1);
                }
            }

            ${mediaUp('tablet')} {
                .body {
                    padding-right: ${fluid(50, 110, 'tablet')};
                }
            }
        `}

        ${p => p.imageDirection == 'right' && `

            ${mediaUp('phone')} {
                grid-template-columns: 1fr ${fluid(300, 800, 'phone')};
                grid-template-areas: 'content figure';

                .figure {
                    margin-right: ${fluid(35, 125, 'phone')};
                }

                .headline {
                    padding-right: 0;
                    padding-left: ${fluid(35, 80, 'phone')};
                }

                .body {
                    padding-right: 0;
                }
            }
            
            ${mediaUp('tablet')} {
                .body {
                    padding-left: ${fluid(50, 110, 'tablet')};
                }
            }
        `}
	`,

    Content: styled.div`
        padding-top: ${rem(90)};

        ${mediaUp('phone')} {
            grid-area: content;
            padding-top: ${fluid(50, 135, 'phone')};
            padding-bottom: ${fluid(50, 135, 'phone')};
        }
    `,

    Figure: styled.figure`
        ${ratio(600, 300)};

        order: 2;

        ${mediaUp('phone')} {
            ${ratio(700, 800)};

            grid-area: figure;
            position: relative;
            order: initial;
            max-height: 100%;
            z-index: 1;
        }
    `,

    Headline: styled.h1`
        position: relative;
        z-index: 1;
        margin-bottom: ${fluid(80, 150, 'phone-xsmall', 'desktop-small')};
        font-size: ${p => p.theme.fontSizes.d2};
        font-weight: 900;
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        padding-right: ${rem(25)};
        padding-left: ${rem(25)};

        ${mediaUp('desktop-small')} {
            margin-bottom: ${fluid(150, 350, 'desktop-small')};
            word-break: break-word;
        }
    `,

    Body: styled.div`
        position: relative;
        z-index: 1;
        padding-left: ${fluid(55, 65, 'phone-xsmall', 'phone')};
        padding-right: ${fluid(55, 65, 'phone-xsmall', 'phone')};

        ${mediaUp('desktop-medium')} {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            column-gap: ${fluid(35, 100, 'tablet')};
        }
    `,

    DescriptionWrapper: styled.div`
        &:before {
            content: '';
            margin-bottom: ${rem(10)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.light};
            border-left: ${rem(1)} solid ${p => p.theme.colors.light};
        }
    `,

    Description: styled.div`
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.normal};
        margin-bottom: ${rem(30)};
        line-height: 1.4;
        
        ${mediaUp('tablet')} {
            font-size: ${p => p.theme.fontSizes.xlarge};
        }
        
        ${mediaUp('desktop-medium')} {
            margin-bottom: 0;
        }
    `,

    Decoration: styled.img`
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 25%;
        max-width: 100vw;
        min-width: ${rem(680)};

        ${mediaUp('tablet')} {
            top: 40%;
        }
    `,

    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        left: ${rem(30)};
        top: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        transform: scaleY(-1);
        z-index: 2;
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        left: ${rem(30)};
        bottom: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        z-index: 2;
    `, 
    
    Stripe: styled(StripeAnimation)`
        left: -30%;
        top: 30%;
        transform: scale(-1.5);

        ${mediaUp('phone')} {
            left: 15%;
            top: 40%;
        }
    `,
});