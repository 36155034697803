import { createGlobalStyle } from 'styled-components';


export default createGlobalStyle`
    body,
    html {
        font-family: ${props => props.theme.fonts.text};
        overflow-x: hidden;
    }

    main {
        // position: relative;
    }
`;