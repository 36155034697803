import styled from 'styled-components';

export default Object.freeze({
    DecorativeText: styled.h2`
        span {
            &:nth-child(odd) {
                display: inline-block;
            }

            &:nth-child(even) {
                display: block;
            }
        }
    `
});