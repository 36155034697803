import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/author-information';
import Image from 'next/image';
import Link from 'next/link';
import socialLinkIcons from 'util/social-link-icons';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function AuthorInformation({fields}) {
	const control = useAnimation()
    const [ref, inView] = useInView()

	useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	const getSocialMediaIcon = name => {
        const Icon = socialLinkIcons[name];

        if (!Icon) return <></>;
        
        return <Icon />;
    }

	return (
		<s.Section id={fields.anchor_id}>
			<s.TopDecoration></s.TopDecoration>
			<s.BottomDecoration></s.BottomDecoration>
			<s.Wrapper size={'large'}>
			<s.BlockDecoration />
				<s.Content
					{...revealLeftVariants}
					ref={ref}
					animate={control}
				>
					<s.ImageWrapper>
						<s.Figure>
							<Image src={fields.author_info.fields.photo.url} alt={fields.author_info.fields.photo.alt} layout='fill' />
						</s.Figure>
					</s.ImageWrapper>
					<s.TextWrapper>
						{fields.author_info.name && (<s.Headline>Written by {fields.author_info.name}</s.Headline>)}
						{fields.author_info.fields.about && (<s.Text dangerouslySetInnerHTML={{__html: fields.author_info.fields.about}} />)}
						{fields.author_info.fields.social_links ?
							( <>
							<s.SocialMedia>
								<s.SocialShare>Share This Article</s.SocialShare>
								{Object.entries(fields.author_info.fields.social_links).map( ([key, val] = media) => {
									return val ? 
									<Link key={key} href={val || '#'} passHref>
										<s.MediaLink>{getSocialMediaIcon(key)}</s.MediaLink>
									</Link>
									: null
								})}
							</s.SocialMedia>
						</>) : null}
					</s.TextWrapper>
				</s.Content>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('author-information', AuthorInformation);