import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import RectDots from 'public/images/rect-dots.svg';
import ChevronDown from 'public/icons/chevron-down.svg';
import Button from 'components/button';
import Loader from 'components/loader';

export default Object.freeze({
    Section: styled.section`
        position: relative;
        padding-top: ${fluid(40, 170)};
        padding-bottom: ${fluid(40, 170)};
        background-color: ${props => props.theme.colors.dark};
    `,

    Wrapper: styled(Wrapper)``,

    Header: styled.header`
        display: grid;
        align-items: center;
        margin-bottom: ${fluid(35, 145)};

        ${mediaUp('phone')} {
            grid-template-columns: auto ${fluid(360, 700, 'phone')};
        }
    `,

    Headline: styled(motion.h2)`
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.d1};
        font-weight: 400;
        margin-bottom: ${rem(25)};

        ${mediaUp('phone')} {
            margin-bottom: 0;
        }
    `,

    Filters: styled.div`
        ${mediaUp('phone-medium')} {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: ${rem(25)};
        }
    `,


    SelectWrapper: styled.div`
        flex: 1 1 auto;
        color: ${p => p.theme.colors.light};
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: ${fluid(15, 25)};
        height: ${fluid(45, 65)};

        ${mediaUp('tablet')} {
            margin-bottom: 0;
        }
    `,

    Select: styled.select`
        font-size: ${p => p.theme.fontSizes.normal};
        background: transparent;
        border: ${rem(1)} solid;
        color: currentColor;
        font-style: italic;
        -webkit-appearance: none;
        padding-right: ${fluid(25, 50)};
        padding-left: ${fluid(15, 25)};
        width: 100%;
        height: 100%;

        &:focus,
        &:active {
            outline: none;
        }

        option {
            background-color: ${props => props.theme.colors.dark};
        }
    `,

    ChevronDown: styled(ChevronDown)`
        position: absolute;
        right: ${fluid(15, 25)};
        top: 50%;
        transform: translateY(-50%);
        width: ${fluid(12, 20)};
        color: currentColor;
    `,

    JobListing: styled.ul``,

    Job: styled.li`
        color: ${p => p.theme.colors.light};
        padding-bottom: ${fluid(25, 30)};
        border-bottom: ${rem(1)} solid ${p => p.theme.colors.gray('700')};
        margin-bottom: ${fluid(25, 30)};
        text-align: center;

        ${mediaUp('phone')} {
            display: grid;
            align-items: center;
            column-gap: ${rem(20)};
            grid-template-columns: 1fr 1fr auto;
            text-align: left;
        }
    `,

    JobHeader: styled.div`
        margin-bottom: ${rem(15)};

        ${mediaUp('phone')} {
            margin-bottom: 0;
        }
    `,

    JobTitle: styled.p`
        font-size: ${fluid(18, 32)};
        margin-bottom: ${fluid(15, 25)};
    `,

    JobCategory: styled.p`
        font-size: ${p => p.theme.fontSizes.small};
        text-transform: uppercase;
    `,

    JobType: styled.p`
        margin-bottom: ${rem(15)};
        text-align: center;

        ${mediaUp('phone')} {
            margin-bottom: 0;
        }
    `,

    LoadMoreWrapper: styled.div`
        text-align: center;
    `,

    LoadMore: styled(Button)`
        margin-top: ${fluid(35, 80)};
        font-size: ${fluid(16, 24)};
    `,

    Loader: styled(Loader)`
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: ${rem(35)};

        ${mediaUp('phone')} {
            margin-bottom: 0;
        }
    `,

    NotFound: styled.div`
        text-align: center;     
        color: ${p => p.theme.colors.light};
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        z-index: 1;
        transform: scaleY(-1);
    `,

    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: 0;
        bottom: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        z-index: 1;
    `, 
});