import { createContext, useContext, useState } from 'react';

const HeaderPos = createContext();

const HeaderProvider = ({children}) => {
    const [headerOffset, setHeaderOffset] = useState(false);
    const [contentHasLoaded, setContentHasLoaded] = useState(false);
 
    return <HeaderPos.Provider value={{ headerOffset, setHeaderOffset, contentHasLoaded, setContentHasLoaded }}>
        {children}
    </HeaderPos.Provider>;
}

const useHeaderContext = () => useContext(HeaderPos);

export {HeaderProvider};
export default useHeaderContext;