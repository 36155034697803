import { createGlobalStyle } from 'styled-components';


export default createGlobalStyle`
    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-Bold'), url(/fonts/F37Bolton-Bold.woff2) format('woff2');
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-BoldItalic'), url(/fonts/F37Bolton-BoldItalic.woff2) format('woff2');
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-ExtraBold'), url(/fonts/F37Bolton-ExtraBold.woff2) format('woff2');
        font-weight: 900;
        font-style: normal;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-ExtraBoldItalic'), url(/fonts/F37Bolton-ExtraBoldItalic.woff2) format('woff2');
        font-weight: 900;
        font-style: italic;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-Medium'), url(/fonts/F37Bolton-Medium.woff2) format('woff2');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-MediumItalic'), url(/fonts/F37Bolton-MediumItalic.woff2) format('woff2');
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-Regular'), url(/fonts/F37Bolton-Regular.woff2) format('woff2');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'F37Bolton';
        src: local('F37Bolton-RegularItalic'), url(/fonts/F37Bolton-RegularItalic.woff2) format('woff2');
        font-weight: 400;
        font-style: italic;
    }

`;