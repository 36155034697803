import {mediaQuery} from './media-query';
import rem from './rem';

const mediaQueryKeys = Object.keys(mediaQuery);
const firstBreakpoint = mediaQueryKeys[0];
const lastBrekpoint = mediaQueryKeys[mediaQueryKeys.length - 1];

export default function fluid(minValue, maxValue, minBreakpoint = firstBreakpoint, maxBreakpoint = lastBrekpoint) {
    if (minValue == 0) minValue = 0.001;

    if (maxValue == 0) maxValue = 0.001;

    let minVw = 0;
    let maxVw = 0;

    if (Object.keys(mediaQuery).includes(minBreakpoint)) {
        minVw = mediaQuery[minBreakpoint];
    } else {
        minVw = minBreakpoint;
    }

    if (Object.keys(mediaQuery).includes(maxBreakpoint)) {
        maxVw = mediaQuery[maxBreakpoint];
    } else {
        maxVw = maxBreakpoint;
    }

    const fluidBase = `${rem(minValue)} + ${(maxValue - minValue)}
        * ((100vw - ${rem(minVw)}) / ${maxVw - minVw})`;

    return `min(${fluidBase}, ${rem(maxValue)})`;
}