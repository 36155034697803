import {useState, useRef, useEffect} from 'react';
import {Body, Wrapper} from 'styles/components/collapsible';
import {mediaQuery} from 'util/media-query';

export default function Collapsible ({
	title,
	children,
	mobileOnly = false,
	breakpoint = 'tablet',
	className,
	onClick,
	active = false,
	getRef,
}) {
	const [init, setInit] = useState(true);
	const [open, setOpen] = useState(active);

	const [bodyHeight, setBodyHeight] = useState(0);
	const bodyRef = useRef();
	const [mainRef, setMainRef] = useState();

	useEffect(() => {
		const initialize = () => {
			if (active) {
				setOpen(true);
				setBodyHeight(bodyRef.current.scrollHeight);
			}

			// Bail if not mobile only, init for all device sizes.
			if (!mobileOnly) return;

			// If isn't mobile set collapsible already opened
			if (window.innerWidth >= mediaQuery[breakpoint]) {
				setInit(false);
				setOpen(true);
			}
		};

		initialize();
	}, []);

	const handleClick = () => {
		if (!init) return;

		setOpen(!open);
		bodyRef && setBodyHeight(bodyRef.current.scrollHeight);

		if (typeof onClick === 'function') onClick(mainRef);
	};

	return <Wrapper
		className={`collapsible ${open ? 'active' : 'hidden'} ${className}`}
		height={init ? bodyHeight : null}
		ref={ref => {
			setMainRef(ref);
			(typeof onClick === 'function') && getRef(ref, open, setOpen);
		}}
	>
		<div onClick={handleClick}>{title}</div>
		<Body ref={bodyRef}>
			{children}
		</Body>
	</Wrapper>;
}