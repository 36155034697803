import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/testimonials';
import Image from 'next/image';
import {useState, useEffect} from 'react';
import ProgressBar from 'components/progress-bar';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Testimonials ({fields}) {
	const {headline, testimonials} = fields;
	const [_, setThumbInstance] = useState();
	const [textInstance, setTextInstance] = useState();
	const [progressBarInstance, setProgressBarInstance] = useState();
	const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	const textSlider = () => testimonials.map(({fields, index}) => {
		return <>
			<s.TextSliderTopContent key={index}>
				{fields.info_column_1 && (
					<div>
						{fields.info_column_1.title && <s.Label>{fields.info_column_1.title}</s.Label>}
						{fields.info_column_1.text && <s.Value>{fields.info_column_1.text}</s.Value>}
					</div>
				)}
				{fields.info_column_2 && (
					<div>
						{fields.info_column_2.title && <s.Label>{fields.info_column_2.title}</s.Label>}
						{fields.info_column_2.text && <s.Value>{fields.info_column_2.text}</s.Value>}
					</div>
				)}
			</s.TextSliderTopContent>

			{fields.text ? <s.Testimonial dangerouslySetInnerHTML={{__html: fields.text}}/> : null}
		</>;
	});

	const thumbSlider = () => testimonials.map((testimonial, index) => {
		return <>
			{testimonial.thumbnail ? (<s.Figure key={index}>
				<Image src={testimonial.thumbnail.sizes.large} layout={'fill'} blurDataURL={testimonial.thumbnail.sizes.large} placeholder="blur" quality={50} priority="false" />
			</s.Figure>) : null}
		</>;
	});

	return (
		<s.Section id={fields.anchor_id}>
			<s.Wrapper size={'full'} noPadding={true}>
				{headline ? <s.Headline {...revealLeftVariants} ref={ref} animate={control} dangerouslySetInnerHTML={{__html: headline}}/> : null}
				<s.SliderWrapper>
					{testimonials.length ? (<>
						<s.TextSlider
							spaceBetween={10}
							items={textSlider()}
							control={progressBarInstance}
							getInstance={setTextInstance}
							swiperOptions={{allowTouchMove: false}}
						/>
						<s.ThumbnailSlider
							navigation={true}
							centeredSlides={true}
							items={thumbSlider()}
							control={textInstance}
							getInstance={setThumbInstance}
						/>
						<ProgressBar
							items={testimonials}
							getInstance={setProgressBarInstance}
						/>
					</>) : null}
				</s.SliderWrapper>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('testimonials', Testimonials);