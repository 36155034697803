import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/stats-image';
import Button from 'components/button';
import Wrapper from 'components/wrapper';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function Stats({fields}) {
    const {additional_information, content, image, background_color} = fields;
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (<s.Section bgColor={background_color} id={fields.anchor_id}>
        <Wrapper noPadding={true} size={'full'}>
            <s.Content>
                <s.FirstColumn 
                    {...revealLeftVariants}
                    ref={ref}
                    animate={control}
                >
                    {(content && content.stats) ? (<s.Stats>
                        {content.stats.map((stat, index) => <s.Stat key={index}>
                            {stat.value && <s.StatValue>{stat.value}</s.StatValue>}
                            {stat.description && <s.StatDescription>{stat.description}</s.StatDescription>}
                        </s.Stat>)}
                    </s.Stats>) : null}
                    {(content && content.large_text) ? (<s.Headline dangerouslySetInnerHTML={{__html: content.large_text}} />) : null}
                    {(content && content.cta.title) ? (<Button
                        styleName={'dark'}
                        href={content.cta.url}
                        target={content.cta.target}
                        iconName={'arrow-right'}>
                        {content.cta.title}
                    </Button>) : null}
                </s.FirstColumn>
                {image && <Image src={image.url} alt={image.alt} width={860} height={830} objectFit="cover" blurDataURL={image.url} placeholder="blur" quality={75} priority="false" />}
            </s.Content>
            
            {((additional_information && additional_information.headline) || (additional_information && additional_information.text)) ? (
                <Wrapper size={'xsmall'}>
                    <s.AdditionalHeadline>{additional_information.headline}</s.AdditionalHeadline>
                    <s.AdditionalText dangerouslySetInnerHTML={{__html: additional_information.text}} />
                </Wrapper>
            ) : null}
        </Wrapper>
    </s.Section>);
}

sectionsManager.register('stats', Stats);