import {Swiper} from 'swiper/react';
import styled from 'styled-components';
import rem from 'util/rem';
import ArrowRight from 'public/icons/arrow-right.svg'
import fluid from 'util/fluid';

export default {
    Slider: styled(Swiper)`
        &.swiper-initialized {
            .swiper-pagination {
                display: flex !important;
            }
        }

        .swiper-wrapper {
            position: relative;
        }

        .swiper-pagination {
            ${p => !p.hasPagination && `display: none !important;`}

            justify-content: center;
            gap: ${rem(10)};
            margin-top: ${rem(40)};

            &-bullet {
                background-color: ${p => p.theme.colors.light};
                width: ${rem(14)};
                height: ${rem(5)};
                border-radius: ${rem(2)};
                opacity: 0.3;
                transition: opacity ${p => p.theme.transitions.normal} ease;

                &-active {
                    opacity: 1;
                }
            }
        }
    `,

    SliderIndicator: styled.div`
        position: absolute;
        right: 0;
        color: ${p => p.theme.colors.gray(400)};
    `,
    
    SliderContent: styled.div`
    `,

    SliderNavigationWrapper: styled.div`
        position: relative;
        width: ${fluid(55, 135)};
        height: ${fluid(55, 135)};
        color: ${p => p.theme.colors.light};
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: calc(100% - ${rem(5)});
            height: calc(100% - ${rem(5)});
            border: ${rem(1)} solid ${p => p.theme.colors.primary};
            border-radius: 100%;
            transform: translate(-50%, -50%);
            transition: transform ${p => p.theme.transitions.normal} ease;
            transform-origin: 0 0;
            pointer-events: none;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: ${p => p.theme.colors.dark};
            border-radius: 100%;
            border: ${fluid(4, 8)} solid ${p => p.theme.colors.light};
        }

        &:hover:before {
            transform: scale(1.1) translate(-50%, -50%);
        }
    `,

    SwiperButtonWrapper: styled.div`
        position: relative;
        z-index: 1;
        display: grid;
        gap: ${fluid(5, 12)};
        grid-template-columns: 1fr 1fr;
    `,

    ArrowRight: styled(ArrowRight)`
        width: ${fluid(8, 22)};
        color: ${p => p.theme.colors.light};
        transition: opacity ${p => p.theme.transitions.normal} ease;

        &:hover {
            opacity: 0.7;
        }
    `,

    ArrowLeft: styled(ArrowRight)`
        width: ${fluid(8, 22)};
        color: ${p => p.theme.colors.light};
        transition: opacity ${p => p.theme.transitions.normal} ease;
        transform: scaleX(-1);

        &:hover {
            opacity: 0.7;
        }
    `,
};