import { useRef, useEffect } from 'react';
import Image from 'next/image';
import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/video';
import Wrapper from 'components/wrapper';
import VideoModal from 'modals/video';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function Video({fields}) {
    const {headline, video_url, image} = fields;
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

    const videoModal = useRef();

    const openModal = () => {
        videoModal.current.setIsOpen(true);
    }

    const setRef = ({setIsOpen}) => {
        videoModal.current = {setIsOpen};
    }

	return (
		<>
            {video_url ? <VideoModal
                id='video'
                url={video_url}
                getRef={setRef}
            /> : null}
            <s.Section id={fields.anchor_id}>
                {headline ? (<Wrapper size='xsmall'>
                    <s.Headline {...revealLeftVariants} ref={ref} animate={control}>{headline}</s.Headline>
                </Wrapper>) : null}

                <Wrapper size='full' noPadding={true}>
                    <s.VideoWrapper>
                        <s.Play onClick={openModal}>
                            <s.PlayIcon />
                        </s.Play>

                        {image ? (<s.Thumbnail>
                            <Image src={image.url} alt={image.alt} layout='fill' objectFit='cover' />
                        </s.Thumbnail>) : null}
                    </s.VideoWrapper>
                </Wrapper>
            </s.Section>
        </>
	);
}

sectionsManager.register('video', Video);