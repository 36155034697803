import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import { ratio } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';


export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-top: ${fluid(35, 150)};
    `,

    Wrapper: styled(Wrapper)`
        display: grid;

        ${mediaUp('tablet')} {
            grid-template-columns: ${fluid(360, 785, 'tablet')} auto;
            gap: ${fluid(50, 135)};
        }
    `,

    Figure: styled.figure`
        ${ratio(785, 600)};

        margin-right: ${fluid(10, 50, 'phone-xsmall', 'tablet')};

        ${mediaUp('tablet')} {
            ${ratio(785, 1055)};

            margin-right: 0;
        }
    `,

    Content: styled(motion.div)`
        padding-top: ${fluid(35, 60)};
        padding-bottom: ${fluid(35, 60)};
        padding-left: ${fluid(25, 50, 'phone-xsmall', 'tablet')};
        padding-right: ${fluid(25, 50, 'phone-xsmall', 'tablet')};

        ${mediaUp('tablet')} {
            padding-left: 0;
            padding-right: 0;
            margin-right: ${rem(30)};
        }
    `,

    Headline: styled.h2`
        font-size: ${fluid(50, 205)};
        font-weight: 900;
        text-transform: uppercase;
        color: ${p => p.theme.colors.primary};
        margin-bottom: ${fluid(30, 65)};
    `,

    SmallCallout: styled.p`
        font-weight: 900;
        color: ${p => p.theme.colors.gray('600')};
        text-transform: uppercase;
        margin-bottom: ${fluid(35, 80)};
        
        &:after {
            content: '';
            margin-top: ${rem(25)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
        }
    `,

    ColumnWrapper: styled.div`
        display: grid;

        ${mediaUp('phone-medium')} {
            grid-auto-flow: column;
            gap: ${fluid(50, 70)};
        }
    `,

    Column: styled.div`
        margin-bottom: ${rem(35)};

        ${mediaUp('tablet')} {
            margin-bottom: 0;
        }
    `,

    ColumnTitle: styled.h3`
        font-size: ${p => p.theme.fontSizes.h3};
        font-weight: 400;
        text-align: center;
    `,

    ColumnDescription: styled.p`
        font-size: ${p => p.theme.fontSizes.large};
        color: ${p => p.theme.colors.gray('400')};
        margin-top: ${fluid(10, 35)};
        line-height: 1.4;
    `
});