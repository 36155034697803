import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import {mediaUp, mediaDown} from 'util/media-query';
import Slider from 'components/slider';
import {ProgressBarSliderWrapper} from 'styles/components/progress-bar';
import rem from 'util/rem';
import { motion } from 'framer-motion';


export default Object.freeze({
	Section: styled.section`
        padding-top: ${fluid(35, 150)}
	`,

	IntroWrapper: styled(motion.div)`
        margin: ${fluid(10, 30)} 0;
        padding: 0 ${fluid(150, 200)};
	`,

	Headline: styled.h2`
        font-size: ${props => props.theme.fontSizes.h2};
        font-weight: 400;

        strong {
            color: ${props => props.theme.colors.primary};
            font-weight: 400;
        }

        ${mediaUp('phone')} {
            margin-top: 0;
            margin-bottom: ${fluid(25, 90)};
        }
	`,

	Wrapper: styled(Wrapper)`
        ${mediaDown('phone')} {
            padding-left: 0;
            padding-right: 0;
        }
	`,

	Content: styled.div`
        padding-top: ${fluid(35, 60)};
        padding-bottom: ${fluid(35, 60)};
        padding-left: 0;
        padding-right: 0;

        &:before {
            content: '';
            display: block;
            height: ${rem(12)};
            border-top: ${rem(2)} solid ${p => p.theme.colors.gray('300')};
            border-left: 0;
            z-index: 2;

            ${mediaUp('tablet')} {
                border-top: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
                border-left: ${rem(1)} solid ${p => p.theme.colors.gray('300')};
            }
        }

        ${mediaUp('tablet')} {
            margin-right: ${rem(30)};
        }
	`,

	SliderWrapper: styled.div`
        margin-top: -10px;

        ${mediaUp('tablet')} {
            padding-left: 2px;
        }

        .swiper {
            width: 100%;

            ${mediaUp('tablet')} {
                overflow: initial;
            }
        }

        .swiper-wrapper {
            ${mediaUp('tablet')} {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        .swiper-slide {
            background: ${p => p.theme.colors.light};
            transition: 0.5s;

            &:hover {
                ${mediaUp('tablet')} {
                    background: ${p => p.theme.colors.gray('100')};

                    .learn-more {
                        opacity: 1;
                    }
                }
            }
        }

        .swiper-slide-active {
            ${mediaDown('desktop-small')} {
                background: ${p => p.theme.colors.gray('100')};

                .learn-more {
                    opacity: 1;
                }
            }
        }

        ${ProgressBarSliderWrapper} {
            display: block;
            padding-left: ${fluid(10, 20)};
            padding-right: ${fluid(10, 20)};

            ${mediaUp('tablet')} {
                display: none;
            }
        }
	`,

	TextSlider: styled(Slider)`
        position: relative;
        z-index: 4;
        display: grid;

        ${mediaUp('phone-medium')} {
            grid-auto-flow: column;
            gap: ${fluid(50, 70)};
        }

	`,

	Column: styled.div`
        margin-bottom: ${rem(35)};
        padding: ${fluid(40, 80)} ${fluid(10, 30)};
        z-index: 1;

        ul {
            list-style-type: disc;
            padding-left: ${rem(10)};
        }

        ol {
            list-style-type: decimal;
            padding-left: ${rem(10)};
        }

        ${mediaUp('tablet')} {
            margin-bottom: 0;
        }
	`,

	ColumnTitle: styled.h3`
        font-size: ${p => p.theme.fontSizes.xlarge};
        font-weight: 400;
        text-align: center;
	`,

	ColumnDescription: styled.p`
        font-size: ${p => p.theme.fontSizes.large};
        color: ${p => p.theme.colors.gray('500')};
        margin-top: ${fluid(10, 35)};
        line-height: 1.5;
	`,
});