import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';


export default Object.freeze({
	Section: styled.section`
        position: relative;
        z-index: 1;
        padding-bottom: ${fluid(45, 130)};
        background-color: ${p => p.theme.colors[p.bgColor]};
        color: ${p => p.bgColor == 'dark' ? p.theme.colors.light : p.theme.colors.dark};
    `,

    Stats: styled.div`
        display: grid;
        grid-auto-flow: column;
        gap: ${fluid(10, 75)};
        margin-bottom: ${fluid(30, 170)};

        ${mediaUp('tablet')} {
            margin-top: ${fluid(80, 150, 'tablet')};
        }
    `,

    Stat: styled.div``,
    
    Content: styled.div`
        display: grid;
        row-gap: ${rem(35)};

        ${mediaUp('tablet')} {
            padding-left: ${fluid(40, 200, 'tablet')};
            grid-template-columns: 1fr 1fr;
            column-gap: ${fluid(80, 120, 'tablet')};
        }

        img {
            order: 1;
            object-fit: contain;
            object-position: right;

            ${mediaUp('tablet')} {
                order: initial;
            }
        }
    `,

    FirstColumn: styled(motion.div)`
        padding-left: ${rem(15)};
        padding-right: ${rem(15)};
        order: 2;

        ${mediaUp('tablet')} {
            order: initial;
            padding-left: 0;
            padding-right: 0;
        }
    `,


    StatValue: styled.h3`
        font-size: ${fluid(30, 90)};
        font-weight: 900;
        margin-bottom: ${rem(10)};
    `,
    
    StatDescription: styled.div`
        position: relative;
        font-size: ${p => p.theme.fontSizes.small};
        line-height: 1.4;
        font-weight: 900;
        color: ${p => p.theme.colors.gray('600')};
        text-transform: uppercase;
        padding-left: ${rem(10)};

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            margin-right: ${rem(7)};
            width: ${rem(3)};
            height: 100%;
            background-image: ${p => p.theme.gradient('bottom')}
        }
    `,
    
    Headline: styled.div`
        font-size: ${p => p.theme.fontSizes.h2};
        font-weight: 700;
        margin-bottom: ${fluid(25, 62)};

        ${mediaUp('tablet')} {
            position: relative;
            z-index: 1;
        }

        strong {
            color: ${p => p.theme.colors.primary};
        }
    `,

    AdditionalHeadline: styled.h4`
        margin-top: ${fluid(35, 115)};
        color: ${p => p.theme.colors.gray('600')};
        font-size: ${p => p.theme.fontSizes.small};
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: ${fluid(8, 30)};

        &:after {
            content: '';
            margin-top: ${fluid(8, 30)};
            margin-bottom: ${fluid(12, 30)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${props => props.theme.colors.gray('600')};
            border-left: ${rem(1)} solid ${props => props.theme.colors.gray('600')};
        }
    `,

    AdditionalText: styled.div`
        font-size: ${p => p.theme.fontSizes.xlarge};
        font-weight: 400;
        line-height: 1.4;
    `,
});