import DecorativeText from 'components/decorative-text';
import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import RectDots from 'public/images/rect-dots.svg';

export default Object.freeze({
	Section: styled.section`
        padding-top: ${rem(45)};
        padding-bottom: ${fluid(40, 170)};
        background-color: ${props => props.theme.colors.light};
        position: relative;
        overflow: hidden;
        z-index: 1;
    `,

    Content: styled.div`
        padding-top: ${fluid(100, 190)};
        padding-bottom: ${fluid(40, 100)};
        background-color: ${p => p.theme.colors.gray('100')};
        margin-top: ${rem(-80)};
        
        ${mediaUp('phone-small')} {
            margin-top: ${rem(-90)};
        }
        
        ${mediaUp('phone')} {
            margin-top: ${rem(-160)};
        }

        ${mediaUp('tablet')} {
            margin-top: ${rem(-250)};
            margin-right: ${rem(75)};
        }
    `,

    ContentWrapper: styled.div`
        margin-top: ${rem(-35)};
        
        ${mediaUp('phone-medium')} {
            margin-top: ${rem(-60)}
        }
        
        ${mediaUp('phone')} {
            margin-top: ${rem(-20)}
        }
        
        ${mediaUp('desktop-medium')} {
            margin-top: ${rem(-80)};
        }
    `,

    DecorativeText: styled(DecorativeText)`
        text-transform: uppercase;
        font-size: ${fluid(45, 205)};
        font-weight: 900;
        line-height: 0.9;

        span {
            &:nth-child(odd) {
                color: ${props => props.theme.colors.primary};
            }
        }
    `,

    ColumnWrapper: styled(Wrapper)`
        display: grid;
        gap: ${fluid(40, 100)};
        margin-top: ${fluid(30, 120)};
        
        ${mediaUp('phone-small')} {
            grid-template-columns: 1fr 1fr;
        }

        ${mediaUp('desktop-small')} {
            grid-template-columns: none;
            grid-auto-flow: column;
        }
    `,

    Column: styled.div`
        ${mediaUp('tablet')} {
            min-width: ${rem(240)};
        }
    `,

    ColumnTitle: styled.h3`
        font-size: ${props => props.theme.fontSizes.h3};
        font-weight: 400;
    `,

    ColumnText: styled.p`
        font-size: ${props => props.theme.fontSizes.large};
        font-weight: 400;
        line-height: 1.4;
        color: ${p => p.theme.colors.gray('500')};
        margin-top: ${fluid(15, 30)};
        max-width: ${rem(290)};
    `,
    
    ImageWrapper: styled(Wrapper)`
        position: relative;
        margin-left: ${fluid(10, 130)};

        ${mediaUp('desktop-xlarge')} {
            margin-left: auto;
        }
    `,

    Figure: styled.div`
        height: ${fluid(180, 930)};
        clip-path: url(#mosaic);

        ${mediaUp('desktop-max')} {
            img {
                object-fit: contain;
            }
        }
    `,

    FirstDots: styled(RectDots)`
        display: none;

        ${mediaUp('tablet')} {
            display: block;
            position: absolute;
            left: ${fluid(25, 125, 'tablet')};
            bottom: 100%;
            width: ${fluid(80, 130, 'tablet')};
            height: ${fluid(20, 45, 'tablet')};
            color: ${props => props.theme.colors.dark};
            z-index: 1;
        }
    `,

    SecondDots: styled(RectDots)`
        display: none;

        ${mediaUp('tablet')} {
            display: block;
            position: absolute;
            left: ${fluid(25, 125, 'tablet')};
            top: 0;
            width: ${fluid(80, 130, 'tablet')};
            height: ${fluid(20, 45, 'tablet')};
            color: ${props => props.theme.colors.light};
            transform: scaleY(-1);
            z-index: 1;
        }
    `, 
});