import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp, mediaDown } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';

export default Object.freeze({
      Section: styled.section`
            padding-top: ${rem(25)};
            padding-bottom: ${rem(25)};
            background-color: ${props => props.theme.colors.light};

            ${mediaUp('phone')} {
                  padding-top: ${fluid(70, 150, 'phone')};
                  padding-bottom: ${fluid(70, 150, 'phone')};
            }
      `,

      Headline: styled(motion.h2)`
            font-size: ${props => props.theme.fontSizes.d1};
            font-weight: 400;
            margin-bottom: ${fluid(10, 40)};
            text-align: center;
      `,

      Scroll: styled.div`
            overflow-x: auto;
      `,

      Table: styled.table`
            position:relative;
            width:100%;
            border-collapse: separate; 
            border-spacing: 0 10px;
      `,

      TableHeader: styled.tr`

            th{
                  background: ${props => props.theme.colors.primary};
                  color:${props => props.theme.colors.light};
                  padding-top: ${rem(20)};
                  padding-bottom: ${rem(20)};
                  padding-left: ${rem(10)};
                  padding-right: ${rem(10)};
                  text-align: center;
                  text-transform: uppercase;
                  min-width: ${rem(180)};

                  ${mediaUp('phone')} {
                        padding-top: ${rem(40)};
                        padding-bottom: ${rem(40)};
                  }
            }

      `,

      TableRow: styled.tr`

            td{
                  background: ${props => props.theme.colors.gray('100')};
                  padding-top: ${rem(20)};
                  padding-bottom: ${rem(20)};
                  padding-left: ${rem(10)};
                  padding-right: ${rem(10)};
                  text-align: center;
                  text-transform: capitalize;
                  min-width: ${rem(180)};
                  
                  ${mediaUp('phone')} {
                        padding-top: ${rem(40)};
                        padding-bottom: ${rem(40)};
                  }
            }
      `,
});