import { useEffect, useRef, useState } from "react";
import { mediaQuery } from "util/media-query";
import s from 'styles/components/decorative-text'

export default function DecorativeText({ text, offsetWords = 1, className }) {
    const textRefs = useRef([]);
    const [splitText, setSplitText] = useState('');

    const setRefs = (el, isOdd, index) => {
        if (!isOdd) return;

        textRefs.current[index] = el;
    }

    const breakText = text => {
        if (offsetWords == 0) return [text];

        const broken = text.split(' ');
        if (offsetWords === 1) return broken;

        const firstPos = broken.splice(0, offsetWords);
        const lastPos = broken;

        return [firstPos.join(' '), lastPos.join(' ')];
    }

    useEffect(() => {
        const textPosition = () => {
            const texts = textRefs.current.filter(item => !!item); // Remove empty items
            const widths = texts.map(item => item.clientWidth);
            const largest = Math.max(...widths);
            const gap = 20;
            
            // Avoid stretching the text for wider screens
            const windowWidth = window.innerWidth > mediaQuery['desktop-xlarge'] ? 1920 : window.innerWidth; 
            
            texts.forEach(item => {
                item.style.transform = `translateX(${( windowWidth - largest) - gap}px)`;
            });
        }

        setSplitText(breakText(text));
        window.addEventListener('resize', textPosition);
        setTimeout(textPosition, 50); // Temp

        return () => window.removeEventListener('resize', textPosition);
    }, [text]);

    return text ? (<s.DecorativeText className={className}>
        {splitText.length && splitText.map((item, index) => {
            const odd = (index + 1) % 2 !== 0;
            return (
                <span
                    key={index}
                    ref={el => setRefs(el, odd, index)}>{item}</span>
            )
        })}
    </s.DecorativeText>) : null;
}