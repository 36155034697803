import {createContext, useContext} from 'react';

const SiteOptions = createContext({});

const SiteOptionsProvider = ({children, value}) => (
	<SiteOptions.Provider value={value}>
		{children}
	</SiteOptions.Provider>
);

const useSiteOptions = () => useContext(SiteOptions);

export {SiteOptionsProvider};
export default useSiteOptions;
