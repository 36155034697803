import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';
import Button from 'components/button';

export default Object.freeze({
      Section: styled.section`
            position: relative;
            padding-top: ${fluid(45, 220)};
      `,

      Content: styled(motion.div)`
            display: grid;
            grid-template-columns: 1fr;
            row-gap: ${rem(35)};

            ${mediaUp('phone')} {
                  grid-template-columns: 1fr ${fluid(270, 560, 'phone')};
                  column-gap: ${fluid(40, 100, 'phone')};
                  padding-right: ${rem(50)};
            }
      `,

      Text: styled.div`
            font-size: ${p => p.theme.fontSizes.h2};
            line-height: 1.12;

            strong {
                  color: ${p => p.theme.colors.primary};
            }
      `,

      TextWrapper: styled.div``,

      ButtonWrapper: styled.div`    
            text-align: right;
            padding-right: ${rem(15)};
            margin-top: ${fluid(25, 65)};
      `,

      Button: styled(Button)`
            font-size: ${fluid(12, 32)};
      `,

      Figure: styled.figure`
            ${ratio(700, 375)};
            
            z-index: 2;

            ${mediaUp('phone')} {
                  ${ratio(400, 200)};

                  transform: translateY(calc(${fluid(120, 300, 'phone')} * -1));
            }

            ${mediaUp('tablet')} {
                  ${ratio(560, 410)};
            }
      `,
});
