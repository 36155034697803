import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';
import Button from 'components/button';
import wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';
import Slider from 'components/slider';
import { ProgressBarSlider, ProgressBarSliderWrapper } from 'styles/components/progress-bar';
import Pipe from 'public/images/pipes.svg';

export default Object.freeze({
	Section: styled.section`
            position: relative;
            z-index: 1;
            padding-bottom: ${fluid(20, 40)};
            padding-top: ${fluid(20, 130)};
            background-color: ${p => p.theme.colors.dark};

            ${mediaUp('tablet')} {
                  padding-bottom: ${fluid(50, 130)};
            }
      `,
    
      TopDecoration: styled(Pipe)`
            position: absolute;
            top: 0;
            right: 0;
            width: max-content;
            height: fit-content;
            opacity: 0.15;
            pointer-events: none;
      `,
    
      FirstDots: styled(RectDots)`
            display: block;
            position: absolute;
            right: ${fluid(30, 80)};
            bottom: 100%;
            width: ${fluid(60, 130)};
            height: ${fluid(20, 45)};
            color: ${props => props.theme.colors.dark};
            z-index: 1;
      `,

      SecondDots: styled(RectDots)`
            display: block;
            position: absolute;
            right: ${fluid(30, 80)};
            top: 0;
            width: ${fluid(60, 130)};
            height: ${fluid(20, 45)};
            color: ${props => props.theme.colors.light};
            transform: scaleY(-1);
            z-index: 1;
      `, 

      Wrapper: styled(wrapper)`
            ${mediaDown('phone')} {
                  padding-left: ${fluid(10, 20, 'phone-xsmall', 'phone')};
                  padding-right: ${fluid(10, 20, 'phone-xsmall', 'phone')};
            }
      `,

      Content: styled(motion.div)`
            display: grid;
            grid-template-columns: 1fr;
            row-gap: ${rem(35)};

            ${mediaUp('tablet')} {
                  grid-template-columns: 1fr ${fluid(360, 735, 'phone')};
                  column-gap: ${fluid(20, 100, 'phone')};
            }
      `,

      Headline: styled.h2`
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.h2};
            text-transform: uppercase;
            padding-top: ${fluid(40, 80)};
            padding-bottom: ${fluid(60, 80)};
            padding-right: ${fluid(40, 80)};

            ${mediaUp('phone-medium')} {
                  font-size: ${p => p.theme.fontSizes.d1};
                  padding-bottom: ${fluid(80, 100)};
            }

            ${mediaUp('tablet')} {
                  padding-top: 0;
                  padding-bottom: 0;
                  padding-right: 0;
            }
      `,

      Text: styled.div`
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.h3};
            margin-top: ${fluid(25, 50, 'phone-xsmall', 'phone')};
            padding-right: ${fluid(40, 80)};
            line-height: 1.3;

            ${mediaUp('tablet')} {
                  padding-right: 0;
            }

            strong {
                  color: ${p => p.theme.colors.tertiary};
            }
      `,

      TextWrapper: styled.div`
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding-left: ${fluid(10, 20, 'phone-xsmall', 'phone')};
            padding-right: ${fluid(10, 20, 'phone-xsmall', 'phone')};
            padding-bottom: ${fluid(30, 40, 'phone')};

            ${mediaUp('phone')} {
                  padding-left: 0;
                  padding-right: 0;
            }

            ${ProgressBarSliderWrapper} {
                  padding-right: ${fluid(20, 40)};
                  text-align: start;
                  
                  p {
                        color: ${p => p.theme.colors.light};
                  }
            }

            ${ProgressBarSlider} {
                  margin-top: 0;

                  ${mediaUp('tablet')} {
                        margin-top: ${fluid(10, 20)};
                  }
            }
      `,

      MobileSliderWrapper: styled.div`
            position: relative;
            max-width: 100vw;
            margin-left: -5vw;

            ${mediaUp('tablet')} {
                  display: none;
            }

            .swiper-wrapper{
                  cursor: default;
            }

            .swiper {
                  z-index: 3;
                  margin-right: 0;
                  height: 100%;
                  width: 100%;
            }

      `,

      MobileSlider: styled(Slider)`
            z-index: 4;
            position: initial;
      `,

      SliderWrapper: styled.div`
            position: relative;
            max-width: 100vw;
            margin-left: -3vw;
            display: none;

            ${mediaUp('tablet')} {
                  margin-top: -10px;
                  display: block;
            }

            .swiper-wrapper{
                  cursor: default;
            }

            .swiper {
                  z-index: 3;
                  margin-right: 0;
                  height: 100%;
                  width: 100%;

                  ${mediaUp('tablet')} {
                        margin-right: -3vw;
                  }
            }

            .swiper-nav-wrapper {
                  display: none;
                  position: absolute;
                  bottom: 0;
                  left: 3vw;
                  transform: translateX(-50%);
                  z-index: 1;

                  ${mediaUp('tablet')} {
                        display: inline-flex;
                  }
            }

      `,

      ImageSlider: styled(Slider)`
            z-index: 4;
            position: initial;
      `,

      Button: styled(Button)`
            margin-top: ${fluid(25, 80)};
      `,

      Figure: styled.figure`
            position: initial;
            ${ratio(768, 650)};

            ${mediaUp('phone-small')} {
                  ${ratio(768, 600)};
            }
            
            ${mediaUp('phone')} {
                  ${ratio(1024, 550)};
            }
      
            ${mediaUp('tablet')} {
                  ${ratio(735, 870)};
            }

            img{
                  object-position: bottom;
            }
   
      `,

      VideoButton: styled.button`
            ${ratio(180, 240)};
            height: auto;
            width: 100px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: auto;
            z-index: 1;
            cursor: pointer;

            ${mediaUp('phone-small')} {
                  width: 125px;
            }

            ${mediaUp('phone')} {
                  width: 150px;
            }

            ${mediaUp('tablet')} {
                  width: 115px;
            }

            ${mediaUp('desktop-small')} {
                  width: 135px;
            }

            ${mediaUp('desktop-mlarge')} {
                  width: 180px;
            }
      `,

});