import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/awards';
import Wrapper from 'components/wrapper';
import {revealLeftVariants} from 'animations/framer-motion';
import {useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useEffect} from 'react';

function Awards ({fields}) {
	const {title, back_ribbon_logos, front_ribbon_logos} = fields;
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start('visible');
		}
	}, [control, inView]);

	const sliderItems = items => items.map(logo => (<img src={logo.url} quality={50} blurDataURL={logo.url} placeholder="blur" priority="false" />));

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size={'full'} noPadding={true} style={{overflow: 'hidden'}}>
				{title && (<s.Heading {...revealLeftVariants} ref={ref} animate={control}>{title}</s.Heading>)}

				{back_ribbon_logos.length && (<s.BackSlider>
					<s.Slider
						loop={true}
						navigation={false}
						centeredSlides={true}
						items={sliderItems(back_ribbon_logos)}
						swiperOptions={{
							speed: 6000,
							autoplay: {
								delay: 1,
								reverseDirection: true,
							},
						}}/>
				</s.BackSlider>)}

				{front_ribbon_logos.length && (<s.FrontSlider>
					<s.Slider
						loop={true}
						navigation={false}
						centeredSlides={true}
						items={sliderItems(front_ribbon_logos)}
						swiperOptions={{
							speed: 6000,
							autoplay: {
								delay: 1,
							},
						}}/>
				</s.FrontSlider>)}
			</Wrapper>
		</s.Section>
	);
}

sectionsManager.register('awards', Awards);