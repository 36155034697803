import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/feature-columns-2';
import Image from 'next/image';
import Wrapper from 'components/wrapper';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function FeatureColumns2({fields}) {
    const { large_text, columns, image  } = fields;
	const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size={'full'}>
				<s.Content>
					<s.Figure className='figure'>
						{image.url && <Image src={image.url} alt={image.alt} layout='fill' blurDataURL={image.url} placeholder="blur" priority="false" />}
					</s.Figure>
					<s.TextWrapper 
						className='text-wrapper'
						{...revealLeftVariants}
						ref={ref}
						animate={control}
					>
						{large_text ? (<s.Text dangerouslySetInnerHTML={{__html: large_text}} />) : null}
					</s.TextWrapper>
				</s.Content>
			</Wrapper>
			<s.ColumnContent>
				<s.FirstDots />
				<s.SecondDots className='second-dots' />
				<s.ContentWrapper>
					{columns.length && (
					<s.ColumnWrapper size={'medium'}>
						{columns.map(item => (
							<s.Column>
								{item.title && (<s.ColumnTitle>{item.title}</s.ColumnTitle>)}
								{item.text && (<s.ColumnText>{item.text}</s.ColumnText>)}
							</s.Column>
						))}
					</s.ColumnWrapper>)}
				</s.ContentWrapper>
			</s.ColumnContent>
		</s.Section>
	);
}

sectionsManager.register('feature-columns-2', FeatureColumns2);