import s from 'styles/components/slider';
import {SwiperSlide, useSwiper} from 'swiper/react';
import {Autoplay, Pagination, Navigation, Controller} from 'swiper';
import {mediaQuery} from 'util/media-query';
import {useEffect, useState} from 'react';
import 'swiper/css';

export default function Slider ({
	items = [],
	pagination = false,
	navigation = false,
	loop = false,
	showIndicator,
	mobileOnly = false,
	centeredSlides = false,
	control = null,
	slidesPerView = 'auto',
	spaceBetween = 0,
	breakpoint = 'phone',
	swiperOptions = {},
	getInstance = () => {},
	className,
}) {
	const [controller, setController] = useState(null);
	const [currentSlide, setCurrentSlide] = useState(0);

	const handleSlideChange = (swiper) => {
		setCurrentSlide(swiper.activeIndex);
	};

	useEffect(() => {

		// Passing the current instance to the parent component
		if (controller) {
			getInstance(controller);

			setCustomOptions();
		}
	}, [controller, control]);

	const setCustomOptions = () => {
		if (control) {
			controller.controller.control = control;
		}
	};

	const onSwiper = swiper => {

		// If brekpoint isn't mobile only
		if (mobileOnly && (window.innerWidth >= mediaQuery[breakpoint])) {
			swiper.destroy(false);
		}
	};

	const prepareOptions = () => {
		let options = {
			loop,
			slidesPerView,
			spaceBetween,
			centeredSlides,
			grabCursor: true,
		};

		if (pagination) options.pagination = {clickable: true};

		// Include custom Swiper options.
		if (typeof swiperOptions === 'object') {
			options = Object.assign(options, swiperOptions);
		}

		return options;
	};

	const SwiperButton = ({children, direction}) => {
		const swiper = useSwiper();

		const action = () => {
			switch (direction) {
				case 'prev':
					return swiper.slidePrev();
				case 'next':
					return swiper.slideNext();
			}
		};

		return <button onClick={action}>{children}</button>;
	};

	return <s.Slider
		modules={[Controller, Pagination, Autoplay, Navigation]}
		className={className}
		onSwiper={onSwiper}
		onBeforeInit={swiper => setController(swiper)}
		{...prepareOptions()}
	>
		{items.map((item, index) => (
			<SwiperSlide key={item}>
				<s.SliderContent>{item}</s.SliderContent>
				{showIndicator && <s.SliderIndicator>{index + 1}/{items.length}</s.SliderIndicator>}
			</SwiperSlide>
		))}

		{navigation && (
			<s.SliderNavigationWrapper className={'swiper-nav-wrapper'}>
				<s.SwiperButtonWrapper>
					<SwiperButton direction={'prev'}>
						<s.ArrowLeft/>
					</SwiperButton>
					<SwiperButton direction={'next'}>
						<s.ArrowRight/>
					</SwiperButton>
				</s.SwiperButtonWrapper>
			</s.SliderNavigationWrapper>
		)}
	</s.Slider>;
}
