import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp, mediaDown } from 'util/media-query';
import Button from 'components/button';
import { ratio } from 'styles/mixins';
import rem from 'util/rem';
import { motion } from 'framer-motion';


export default Object.freeze({
	Section: styled.section`
        position: relative;
    `,

    Content: styled.div`
        display: grid;
        grid-template-columns: 1fr;
        row-gap: ${rem(35)};
        padding-top: ${fluid(40, 100)};
        padding-bottom: ${fluid(40, 100)};

        ${mediaUp('phone-medium')} {
            grid-template-columns: 1fr 1fr;
            column-gap: ${fluid(40, 100, 'phone-medium')};
        }

        figure {
            margin-left: -10vw;
        }

        ${mediaDown('phone-medium')} {
            > div {
                order: 1;
            }
    
            figure {
                order: 2;
            }
        }

        ${p => (p.imagePosition === 'right') && `
            > div {
                order: 1;
            }

            figure {
                order: 2;
                margin-right: -10vw;
                margin-left: 0;
            }
        `}
    `,

    Text: styled.div`
        font-size: ${p => p.theme.fontSizes.xlarge};
        font-weight: 400;
        margin-top: ${fluid(25, 50)};
        line-height: 1.4;

        ${mediaUp('phone-medium')} {
            margin-bottom: ${fluid(20, 40)};
            padding-bottom: ${fluid(40, 80)};
        }

        strong {
            color: ${p => p.theme.colors.tertiary};
        }
    `,
    
    Title: styled.div`
        font-size: ${p => p.theme.fontSizes.h2};
        font-weight: 400;
        margin-top: ${fluid(25, 50, 'phone-xsmall', 'phone-medium')};

        strong {
            color: ${p => p.theme.colors.primary};
            font-weight: 400;
        }

    `,

    TextWrapper: styled(motion.div)`    
        ${mediaUp('desktop-small')} {
            max-width: ${rem(625)};
        }
    `,

    Button: styled(Button)`
        margin-top: ${fluid(25, 65)};
    `,

    Figure: styled.figure`
        ${ratio(1085, 689)};
    `,

    ColumnContent: styled.div`
        padding-top: ${fluid(170, 230)};
        padding-bottom: ${fluid(60, 150)};
        background-color: ${p => p.theme.colors.gray('100')};
        margin-top: ${rem(-180)};
        
        ${mediaUp('phone-medium')} {
            margin-top: ${rem(-80)};
            padding-top: ${fluid(100, 150)};
        }
        
        ${mediaUp('phone')} {
            margin-top: ${rem(-80)};
        }

        ${mediaUp('tablet')} {
            margin-top: ${rem(-120)};
        }

        ${mediaUp('desktop-medium')} {
            margin-top: ${rem(-150)};
            padding-top: ${fluid(120, 175)};
        }
    `,

    ContentWrapper: styled.div`
        margin-top: ${rem(-35)};
        
        ${mediaUp('phone-medium')} {
            margin-top: ${rem(-60)}
        }
        
        ${mediaUp('phone')} {
            margin-top: ${rem(-20)}
        }
        
        ${mediaUp('desktop-medium')} {
            margin-top: ${rem(-60)};
        }
    `,

    ColumnWrapper: styled(Wrapper)`
        display: grid;
        gap: ${fluid(40, 100)};
        margin-top: ${fluid(30, 120)};
        padding-left: ${fluid(40, 80)};
        padding-right: ${fluid(40, 80)};
        
        ${mediaUp('phone-small')} {
            grid-template-columns: 1fr 1fr;
        }
        
        ${mediaUp('desktop-small')} {
            grid-template-columns: repeat(${p => p.gridSize}, 1fr);
        }
    `,

    Column: styled.div`
        ${mediaUp('tablet')} {
            min-width: ${rem(240)};

            ul {
                list-style: disc;
                padding-left: ${fluid(20, 30)};
            }

            ol {
                list-style-type: decimal;
            }
        }
    `,

    ColumnTitle: styled.h3`
        font-size: ${props => props.theme.fontSizes.h3};
        font-weight: 400;
        text-align: center;
    `,

    ColumnText: styled.p`
        font-size: ${props => props.theme.fontSizes.large};
        color: ${p => p.theme.colors.gray('500')};
        margin-top: ${fluid(15, 30)};
        line-height: 1.5;
    `,

});