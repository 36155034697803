import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/large-text-image';
import Image from 'next/image';
import Wrapper from 'components/wrapper';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function LargeTextImage({fields}) {
	const {image, large_text, cta} = fields;
	const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size={'large'}>
				<s.Content 
					{...revealLeftVariants}
					ref={ref}
					animate={control}
				>
					<s.TextWrapper className='text-wrapper'>
						{large_text ? (<s.Text dangerouslySetInnerHTML={{__html: large_text}} />) : null}
						<s.ButtonWrapper>
							{cta.title ? (<s.Button
								href={cta.url}
								target={cta.target}
								iconName={'arrow-right'}>
								{cta.title}
							</s.Button>) : null}
						</s.ButtonWrapper>
					</s.TextWrapper>
					<s.Figure className='figure'>
						{image.url && <Image src={image.url} alt={image.alt} layout='fill' />}
					</s.Figure>
				</s.Content>
			</Wrapper>
    	</s.Section>
	);
}

sectionsManager.register('large-text-image', LargeTextImage);