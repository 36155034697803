const mediaQuery = {
    'phone-xsmall': 320,
    'phone-small': 425,
    'phone-medium': 600,
    'phone': 768,
    'tablet': 1024,
    'desktop-small': 1200,
    'desktop-medium': 1440,
    'desktop-mlarge': 1600,
    'desktop-large': 1800,
    'desktop-xlarge': 1920,
    'desktop-full': 2000
}

const mediaUp = size => `@media screen and (min-width: ${mediaQuery[size]}px)`;

const mediaDown = size => `@media screen and (max-width: ${mediaQuery[size] - 0.98}px)`;

export { mediaUp, mediaDown, mediaQuery }