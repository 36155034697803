import styled from 'styled-components';
import ChevronDown from 'public/icons/chevron-down.svg';
import SearchIcon from 'public/icons/search.svg';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import fluid from 'util/fluid';
import { ratio } from 'styles/mixins';
import Loader from 'components/loader';
import ArrowRight from 'public/icons/arrow-right.svg';

export default Object.freeze({
	Section: styled.section``,

    Filters: styled.div`
        background: ${p => p.theme.colors.primary};
        padding-top: ${fluid(25, 40)};
        padding-bottom: ${fluid(25, 40)};

        ${mediaUp('desktop-small')}  {
            margin-left: ${rem(40)};
        }
        
        .wrapper {

            ${mediaUp('phone')} {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: ${fluid(100, 190, 'phone-medium')};
            }
        }
    `,

    Taxonomies: styled.div`
        ${mediaUp('phone-medium')} {
            display: flex;
            align-items: center;
            gap: ${rem(50)};
        }
    `,

    SelectWrapper: styled.div`
        flex: 1 1 ${rem(330)};
        color: ${p => p.theme.colors.light};
        border: ${rem(1)} solid;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: ${fluid(15, 25)};
        height: ${fluid(45, 65)};
        padding-left: ${fluid(15, 20)};

        ${mediaUp('tablet')} {
            margin-bottom: 0;
        }
    `,

    SelectLabel: styled.span`
        white-space: nowrap;
        text-transform: uppercase;
        margin-right: ${rem(5)};
        font-size: ${p => p.theme.fontSizes.large};
        font-weight: 900;
    `,

    Select: styled.select`
        cursor: pointer;
        background: transparent;
        color: currentColor;
        border: none;
        padding-right: ${fluid(25, 50)};
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        font-size: ${p => p.theme.fontSizes.large};
        font-weight: 900;
        color: ${p => p.theme.colors.tertiary};
        -webkit-appearance: none;

        &:focus,
        &:active {
            outline: none;
        }

        option {
            color: ${p => p.theme.colors.primary};
        }
    `,

    ChevronDown: styled(ChevronDown)`
        position: absolute;
        right: ${fluid(15, 25)};
        top: 50%;
        transform: translateY(-50%);
        width: ${fluid(12, 20)};
        color: currentColor;
        pointer-events: none;
    `,

    Search: styled.form`
        flex: 1 1 auto;
    `,
    
    SearchIcon: styled(SearchIcon)`
        width: ${fluid(16, 35)};
        color: ${p => p.theme.colors.light};
    `,

    InputWrapper: styled.fieldset`
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: ${rem(1)} solid ${p => p.theme.colors.light};
        padding-bottom: ${fluid(15, 20)};

        input {
            flex: 1 1 auto;
            background: transparent;
            border: none;
            font-size: ${p => p.theme.fontSizes.xlarge};
            color: ${p => p.theme.colors.light};
            margin-right: ${rem(10)};

            &:focus,
            &:active {
                outline: 0;
            }

            &::placeholder {
                color: ${p => p.theme.colors.light};
            }
        }
    `,

    SearchBtn: styled.button``,

    List: styled.main`
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: ${fluid(50, 180)};
    `,

    Post: styled.article`
        position: relative;
        width: 100%;
        display: grid;
        row-gap: ${rem(35)};
        margin-bottom: ${fluid(25, 85)};
        padding-bottom: ${fluid(25, 85)};
        border-bottom: ${rem(1)} solid ${p => p.theme.colors.gray('300')};

        ${mediaUp('phone')} {
            grid-template-columns: ${fluid(286, 715, 'phone')} 1fr;
            column-gap: ${fluid(50, 100, 'phone')};
        }
    `,

    Link: styled.a`
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    `,

    Thumbnail: styled.figure`
        ${ratio(715, 470)};
    `,

    Content: styled.div``,

    Hat: styled.div`
        display: flex;

        ${mediaUp('phone')} {
            margin-top: ${rem(50)}
        }
    `,

    Taxonomy: styled.div`
        position: relative;
        color: ${p => p.theme.colors.gray('500')};
        font-size: ${p => p.theme.fontSizes.small};
        font-weight: 900;
        text-transform: uppercase;
        padding-right: ${fluid(5, 15)};
        margin-right: ${fluid(5, 15)};

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: ${fluid(1, 3)};
            background-image: ${p => p.theme.gradient('to top')};
        }
    `,

    Date: styled.time`
        font-size: ${p => p.theme.fontSizes.small};
    `,

    Title: styled.h3`
        font-size: ${p => p.theme.fontSizes.h3};
        font-weight: 400;
        margin-top: ${fluid(10, 35)};
    `,

    Description: styled.p`
        font-size: ${p => p.theme.fontSizes.xlarge};
        margin-top: ${fluid(10, 35)};
        line-height: 1.4;
    `,

    SearchByTextLoader: styled(Loader)`
        position: absolute;
        right: 0;
    `,

    PostsLoader: styled(Loader)`
        span {
            color: ${p => p.theme.colors.primary} !important;
        }
    `,

    ClearText: styled.small`
        color: ${p => p.theme.colors.light};
        display: inline-block;
        margin-right: ${rem(25)};
        cursor: pointer;
    `,

    NotFound: styled.h2`
        font-size: ${p => p.theme.fontSizes.h2};
        color: ${p => p.theme.colors.primary};
        text-transform: uppercase;
    `,

    Pagination: styled.div`
        display: flex;
        align-items: center;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding-top: ${rem(15)};
        padding-bottom: ${rem(15)};
    `,

    Prev: styled.div`
        width: ${fluid(30, 60)};
        height: ${fluid(30, 60)};
        border: ${rem(1)} solid currentColor;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${p => p.theme.colors.dark};
        transition: all ${p => p.theme.transitions.normal};
        cursor: pointer;
        border-radius: 100%;
        margin-right: ${fluid(35, 80)};

        &:hover {
            background: ${p => p.theme.colors.dark};
            color: ${p => p.theme.colors.light};
        }

        .arrow {
            color: currentColor;
            width: 1em;
            transform: scaleX(-1);
        }
    `,

    Next: styled.div`
        width: ${fluid(30, 60)};
        height: ${fluid(30, 60)};
        border: ${rem(1)} solid currentColor;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${p => p.theme.colors.dark};
        transition: all ${p => p.theme.transitions.normal};
        cursor: pointer;
        border-radius: 100%;
        margin-left: ${fluid(35, 80)};

        &:hover {
            background: ${p => p.theme.colors.dark};
            color: ${p => p.theme.colors.light};
        }

        .arrow {
            color: currentColor;
            width: 1em;
        }
    `,

    Arrow: styled(ArrowRight)``,

    Pages: styled.div`
        display: flex;
    `,

    Page: styled.div`
        position: relative;
        font-size: ${p => p.theme.fontSizes.large};
        font-weight: 400;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: ${fluid(15, 25)};
        }

        &.not-clickable {
            pointer-events: none;
        }

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: ${fluid(20, 30)};
            height: ${fluid(20, 30)};
            background: transparent;
            transform: scale(0) translate(-50%, -50%);
            transform-origin: 0 0;
            border-radius: 100%;
            transition: all ${p => p.theme.transitions.fast};
            z-index: -1;
        }

        &.active {
            pointer-events: none;
        }

        &:hover,
        &.active {
            color: ${p => p.theme.colors.light};

            &:before {
                transform: scale(1) translate(-50%, -50%);
                background: ${p => p.theme.colors.primary};
            }
        }
    `

});