import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/text-image-2';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function TextImage2({fields}) {
    const {image, content} = fields;
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (<s.Section id={fields.anchor_id}>
        <s.Wrapper size={'xlarge'} className='wrapper'>
            <s.Content
                {...revealLeftVariants}
                ref={ref}
                animate={control}
            >
                <s.TextWrapper className='text-wrapper'>
                    {content.headline ? (<s.Title dangerouslySetInnerHTML={{__html: content.headline}} />) : null}
                    {content.text ? (<s.Text dangerouslySetInnerHTML={{__html: content.text}} />) : null}
                    <s.ButtonWrapper>
                        {content.ctas.length ? (content.ctas.map(cta => 
                            <s.Button
                                styleName={'dark'}
                                href={cta.url}
                                target={cta.target}
                                iconName={'arrow-right'}>
                                {cta.title}
                            </s.Button>
                        )) : null}
                    </s.ButtonWrapper>
                </s.TextWrapper>
                <s.Figure className='figure'>
                    {image.url && <Image src={image.url} alt={image.alt} layout='fill' objectFit='cover' />}
                    <s.FirstDots />
                    <s.SecondDots className='second-dots' />
                </s.Figure>
            </s.Content>
        </s.Wrapper>
    </s.Section>);
}

sectionsManager.register('text-image-2', TextImage2);