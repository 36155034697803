import styled from 'styled-components';

import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import Button from 'components/button';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-top: ${rem(50)};
        padding-bottom: ${rem(40)};
        background-color: ${p => p.theme.colors.light};

        ${mediaUp('phone')} {
            padding-top: ${fluid(70, 150, 'phone')};
            padding-bottom: 0;
        
            ${p => p.hasBgDecoration && `
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 70%;
                height: ${fluid(600, 950, 'phone')};
                background: ${p.theme.colors.gray('100')};
            }
            `}
        }
    `,

    Content: styled(motion.div)`
        position: relative;
        z-index: 2;
        padding-left: ${fluid(25, 100)};
    `,

    Title: styled.h2`
        font-size: ${props => props.theme.fontSizes.h2};
        margin-bottom: ${fluid(25, 90)};
        font-weight: 400;
        
        strong {
            font-weight: 400;
            color: ${props => props.theme.colors.primary};
        }
    `,

    SideContent: styled.div`
        margin-left: ${rem(30)};

        ${mediaUp('phone')} {
            max-width: ${rem(750)};
            margin-left: auto;
        }
    `,

    Text: styled.div`
        font-size: ${props => props.theme.fontSizes.xlarge};
        line-height: 1.4;
        margin-bottom: ${fluid(25, 65)};
    `,

    Button: styled(Button)`
        font-size: ${fluid(12, 32)}
    `
});