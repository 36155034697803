import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/accordion-tabs';
import Wrapper from 'components/wrapper';
import Tabs from 'components/tabs';
import Image from 'next/image';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function AccordionTabs ({fields}) {
    const {decorative_title, tabs, image} = fields;
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	/**
	 * Temp solution to hide siblings.
	 */
	const collapsibleNodes = new Map();
	const hideNonActive = (current) => {
		Array.from(collapsibleNodes.values())
			.map(tab => {
				if (!tab.el.isSameNode(current)) {
					tab.setState(false);
				}
			});
	}

    const collapsibleHeader = title => (<s.CollapsibleHeader>
        <s.CollapsibleTitle>{title}</s.CollapsibleTitle>
        <s.Icon />
    </s.CollapsibleHeader>);

    const collapsible = (item, index) => {
		const firstIndex = index === 0;

        return <s.Collapsible
            getRef={(ref, state, setState) => collapsibleNodes.set(item.title, {el: ref, setState, state})}
			onClick={hideNonActive}
            title={collapsibleHeader(item.title)}
            active={firstIndex}
            key={index}
        >
            <s.CollapsibleBody dangerouslySetInnerHTML={{__html: item.content}} />
        </s.Collapsible>;
    }

    const tabBody = tab => {
        const {items} = tab;

        return <>
            {(items && items.length) ? (items.map(item => collapsible(item))) : null}
        </>
    }

	return (
		<s.Section id={fields.anchor_id}>
			<s.SectionWrapper>
                <Wrapper noPadding={true} size="full">
                    {decorative_title ? (<s.DecorativeTitle {...revealLeftVariants} ref={ref} animate={control}>{decorative_title}</s.DecorativeTitle>) : null}
                </Wrapper>

                <s.AccordionWrapper>
                    {image.url && (<s.Figure>
                        <s.FirstDots />
                        <s.SecondDots />
                        <Image src={image.url} alt={image.alt} quality={50} priority="false" width={580} height={760} objectFit="cover" />
                    </s.Figure>)}
                    <Tabs tabs={tabs} tabBody={tabBody} activeFirst={true} />
                </s.AccordionWrapper>
            </s.SectionWrapper>
		</s.Section>
	);
}

sectionsManager.register('accordion-tabs', AccordionTabs);