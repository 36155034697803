import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/team';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import {Icon} from 'styles/components/tabs';
import ProgressBar from 'components/progress-bar';

function Team({fields}) {
    const {decorative_text, members: allMembers} = fields;
	const [members, setMembers] = useState([]);
	const [memberInstance, setMemberInstance] = useState();
	const [progressBarInstance, setProgressBarInstance] = useState();
    const [departments, setDepartments] = useState([]);
    const [currentDepartment, setCurrentDepartment] = useState('all');

    // Filter Members
    useEffect(() => {
        if (currentDepartment == 'all') {
            setMembers(allMembers);
            return;
        }

        const filteredMembers = allMembers.filter(member => {
            return member.terms.departments.some(department => department.slug === currentDepartment)
        });

        setMembers(filteredMembers);
    }, [currentDepartment]);

    useEffect(() => {
        setMembers(allMembers);
    }, [allMembers]);

    useEffect(() => {
        if (departments.length) return;
        
        // Get the departments from each member
        let terms = members
            .map(({terms}) => terms.departments);
        
        // Spreading departments to a unique array, even if those are duplicate
        let allDepartments = [];
        terms.forEach(term => {
            allDepartments.push(...term);
        });

        // Function responsible to get the index of the duplicate ones.
        let duplicateIndexes = [];
        allDepartments.forEach((department, index, self) => {
            duplicateIndexes.push(self.findIndex((subDep, subIndex) => {
                const sameDepartment = department.ID == subDep.ID;
                const butDifferentIndex = index !== subIndex;
                // If INDEX (first array)
                // is HIGHER than
                // SUBINDEX (inner array)
                // ignore it, in order to avoid duplicity;
                const avoidDuplicity = subIndex > index;

                return sameDepartment && butDifferentIndex && avoidDuplicity;
            }));
        });

        // remove the invalid ones
        duplicateIndexes = duplicateIndexes.filter(index => index > 0);

        // Separate onto unique departments.
        const unique = allDepartments.filter((department, index) => {
            if (duplicateIndexes.includes(index)) return;

            return department;
        });

        setDepartments(unique);
    }, [members]);
	
	const membersSlider = () => members.map(({ fields, thumbnail }, index) => {

		return <>
			{(thumbnail && thumbnail.url) ? (<>
                <s.Figure key={index}>
                    <Image src={thumbnail.url} alt={thumbnail.alt} layout={'fill'} />
                </s.Figure>
                <s.FigCaption>
                    {fields.name ? (<s.Name>{fields.name}</s.Name>) : null}
                    {fields.role ? (<s.Role>{fields.role}</s.Role>) : null}
                </s.FigCaption>
            </>) : null}
		</>
	});

	return (
		<s.Section id={fields.anchor_id}>
            <s.Wrapper size={'full'} noPadding={true}>
	            {decorative_text ? <s.Headline dangerouslySetInnerHTML={{__html: decorative_text}}></s.Headline> : null}

                <s.SliderWrapper>
                    {members.length ? (<>
                        
                        <s.Filter>
                            <s.FilterOption
                                className={currentDepartment === 'all' ? 'active' : null }
                                onClick={() => setCurrentDepartment('all')}
                            >All Departments</s.FilterOption>
                            {(departments && departments.length) ? departments.map((department, index) => (
                                <s.FilterOption
                                    key={index}
                                    onClick={() => setCurrentDepartment(department.slug)}
                                    className={currentDepartment === department.slug ? 'active' : null }
                                >
                                    {department.title}
                                </s.FilterOption>
                            )) : null}
                        </s.Filter>

                        {(departments && departments.length) ? (<s.TabsListMobile>
                            <select onChange={event => setCurrentDepartment(event.target.value)}>
                                {departments.map((department, index) => (
                                    <option key={index} value={department.slug}>
                                        {department.title}
                                    </option>)
                                )}
                            </select>
                            <Icon />
                        </s.TabsListMobile>) : null}
                        
                        <s.MemberSliders
                            navigation={members.length > 1}
                            centeredSlides={true}
                            items={membersSlider()}
                            control={progressBarInstance}
                            getInstance={setMemberInstance}
                        />
                        <ProgressBar
                            items={members}
                            getInstance={setProgressBarInstance}
                            control={memberInstance}
                            showIndicator={true}
                        />
                    </>) : null}
                </s.SliderWrapper>
            </s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('team', Team);