import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/image-features';
import Wrapper from 'components/wrapper';
import Image from 'next/image';
import {useState} from 'react';
import ProgressBar from 'components/progress-bar';
import {revealLeftVariants} from 'animations/framer-motion';
import {useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';
import {useEffect} from 'react';

function ImageFeatures ({fields}) {
	const {features, image} = fields;
	const [progressBarInstance, setProgressBarInstance] = useState();
	const [sliderInstance, setSliderInstance] = useState();
	const control = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			control.start('visible');
		}
	}, [control, inView]);

	const sliderItems = items => (
		items.map((feature, featureKey) => (
			<s.Feature
				key={featureKey}
				className="feature">
				<s.Decoration className="decoration"/>
				<s.Overline dangerouslySetInnerHTML={{__html: feature.overline}}/>
				<s.Title>{feature.title}</s.Title>
				<s.Text dangerouslySetInnerHTML={{__html: feature.text}}/>
			</s.Feature>
		))
	);

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size={'large'}>
				<s.Figure>
					<Image src={image.url} alt={image.alt} layout={'fill'} objectFit={'contain'}/>
				</s.Figure>
				{features && features.length ? (
					<>
						<s.Slider
							spaceBetween={20}
							slidesPerView={1.4}
							items={sliderItems(features)}
							getInstance={setSliderInstance}
							control={progressBarInstance}
							mobileOnly={true}
						/>
						<ProgressBar
							items={features}
							getInstance={setProgressBarInstance}
							control={sliderInstance}
						/>
					</>
				) : null}
			</Wrapper>
		</s.Section>
	);
}

sectionsManager.register('image-features', ImageFeatures);