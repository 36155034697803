import sectionsManager from 'util/sections-manager';
import useSiteOptions from 'providers/useOptions';
import {useEffect} from 'react';
import {revealLeftVariants} from 'animations/framer-motion';
import {useAnimation} from 'framer-motion';
import {useInView} from 'react-intersection-observer';

import s from 'styles/sections/form-callout';
import Button from 'components/button';

function FormCallout ({fields}) {
	const {headline, small_text, cta, form_embed} = fields;
	const {hubspot_portal_id, hubspot_form_id} = form_embed;
	const control = useAnimation();
	const [ref, inView] = useInView();

	const headlineHTML = headline.replace(/(-)/, '<span class="trace"></span>');

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://js.hsforms.net/forms/v2.js';
		document.body.appendChild(script);

		script.addEventListener('load', () => {
			// @TS-ignore
			if (window.hbspt) {
				// @TS-ignore
				window.hbspt.forms.create({
					portalId: hubspot_portal_id,
					formId: hubspot_form_id,
					target: '#hubspotForm',
				});
			}
		});

		if (inView) {
			control.start('visible');
		}
	}, [control, inView]);

	return (
		<s.Section bgColor={fields.background_color} id={fields.anchor_id}>
			<s.Wrapper size="xlarge">
				<s.LeftContent>
					{fields.show_decoration && <>
						<s.FirstDots/>
						<s.SecondDots/>
					</>}

					<s.Headline
						{...revealLeftVariants}
						ref={ref}
						animate={control}
						dangerouslySetInnerHTML={{__html: headlineHTML}}
					/>

					<s.Content>
						<s.SmallText dangerouslySetInnerHTML={{__html: small_text}}></s.SmallText>

						{cta ? (<s.ButtonWrapper>
							<s.Icon/>
							<Button
								styleName={'light'}
								target={cta.target}
								href={cta.url}>
								{cta.title}
							</Button>
						</s.ButtonWrapper>) : null}

					</s.Content>
				</s.LeftContent>

				<div>
					<div id="hubspotForm"></div>
				</div>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('form-callout', FormCallout);