import {useEffect, useRef, useState} from 'react';

import m from 'styles/components/modal';
import useModals from 'providers/useModals';

export default function Modal ({
	mRef,
	id,
	children,
	isGlobal = false,
	onClose = () => {},
	open = false,
	hideCloseButton = false,
	className
}) {
	const initialized = useRef(false);

	const modals = useModals();

	const [isOpen, setIsOpen] = useState(open);

	// On click close the current modal
	const close = () => {
		setIsOpen(false)
		
		// Call outside callback in case fn need extra stuff
		onClose()
	}

	// Register this modal on render.
	useEffect(() => {
		if (isGlobal) {
			const handleOpen = () => setIsOpen(true);
			const handleClose = () => setIsOpen(false);
			
			modals.register(id, {open: handleOpen, close: handleClose, isOpen: () => isOpen});

			initialized.current = true;
		}
	}, []);

	/**
	 * Closes the modal when the Escape key is pressed and no inputs are focused.
	 *
	 * @param {KeyboardEvent} e
	 */
	function handleEscapeKey(e) {
		if (e.key !== 'Escape') return;

		if (
			document.activeElement &&
			['INPUT', 'TEXTAREA', 'SELECT', 'OPTION'].includes(document.activeElement.tagName)
		) return;

		modals.close(id)();
	}

	// Close the current modal when pressing Escape.
	useEffect(() => {
		if (initialized.current) {
			document[isOpen ? 'addEventListener' : 'removeEventListener']('keydown', handleEscapeKey);
		}
	}, [modals.current]);

	// Prepare the modal contents.
	const contents = typeof children === 'function' ? children({close: handleClose}) : children;

	return (
		<m.Modal
			isOpen={isOpen}
			className={isOpen ? 'open' + className : className}
			ref={() => (typeof mRef === 'function') && mRef({ setIsOpen })}>
			{!hideCloseButton && (
				<m.CloseButton className='close-btn'>
					<button
						type="button"
						onClick={close}
					>
						Close <i></i>
					</button>
				</m.CloseButton>
			)}

			{contents}
		</m.Modal>
	);
}

export {m};