import styled from 'styled-components';
import fluid from 'util/fluid';
import {mediaUp} from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import RectDots from 'public/images/rect-dots.svg';
import {ratio} from 'styles/mixins';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        z-index: 1;
        padding-top: ${fluid(45, 130)};
        background-color: ${p => p.theme.colors.dark};
	`,

	Content: styled.div`
        color: ${p => p.theme.colors.light};
        display: grid;
        row-gap: ${rem(35)};
        margin-top: ${fluid(-10, -50)};
        padding-top: ${rem(10)};

        ${mediaUp('desktop-small')} {
            grid-template-columns: ${rem(500)} ${fluid(500, 1040, 'desktop-small')};
            column-gap: ${fluid(80, 120, 'tablet')};
        }

        ${mediaUp('tablet')} {
            margin-top: 0;
            padding-top: 0;
        }
	`,

	Left: styled.div`
        padding-left: ${rem(15)};
        padding-right: ${rem(15)};
		grid-row: 2;

        ${mediaUp('tablet')} {
	        grid-row: 1;
            padding-left: 0;
            padding-right: 0;
        }
	`,

	Title: styled.h2`
        font-size: ${p => p.theme.fontSizes.h2};
        line-height: 1;
        font-weight: 400;
	`,

	Description: styled.p`
        font-size: ${p => p.theme.fontSizes.xlarge};
        line-height: 1.4;
        margin-top: ${rem(60)};
	`,

	Stats: styled.div`
        display: grid;
        justify-content: center;
        grid-auto-flow: column;
        gap: ${fluid(10, 75)};
        margin-bottom: ${fluid(30, 170)};
        margin-top: ${fluid(30, 90)};

        ${mediaUp('desktop-small')} {
	        grid-column: 2;
        }
	`,

	Stat: styled.div``,

	StatValue: styled.h3`
        font-size: ${fluid(30, 90)};
        font-weight: 900;
        margin-bottom: ${rem(10)};
	`,

	StatDescription: styled.p`
        position: relative;
        font-size: ${p => p.theme.fontSizes.small};
        font-weight: 900;
        color: ${p => p.theme.colors.gray('600')};
        text-transform: uppercase;
        padding-left: ${rem(10)};
        line-height: 1.2;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: ${fluid(1, 3)};
            background-image: ${p => p.theme.gradient('to top')};
        }
	`,

	DecotativeText: styled(motion.h2)`
        position: relative;
        z-index: 2;
        font-size: ${p => p.size === 'default' ? `${fluid(35, 165)}` : p.theme.fontSizes[p.size]};
        font-weight: 900;
        margin-bottom: ${fluid(25, 90)};
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;

        ${mediaUp('tablet')} {
            position: relative;
            z-index: 1;
        }

        strong {
            color: ${p => p.theme.colors.primary};
        }
	`,

        ImageWrapper: styled.div`
        margin-top:0;
        ${mediaUp('desktop-small')} {
            margin-top: ${fluid(-75, -125)};
        }    
        `,

	Figure: styled.figure`
        ${ratio(1040, 700)};
	`,

	FirstDots: styled(RectDots)`
        display: none;
        position: absolute;
        right: ${fluid(30, 80)};
        bottom: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        z-index: 1;

        ${mediaUp('phone')}{
                display: block;
        }
	`,

	SecondDots: styled(RectDots)`
        display: none;
        position: absolute;
        right: ${fluid(30, 80)};
        top: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.dark};
        transform: scaleY(-1);
        z-index: 1;

        ${mediaUp('phone')}{
                display: block;
        }
	`,
});