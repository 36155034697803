import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import RectDots from 'public/images/rect-dots.svg';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import ArrowRight from 'public/icons/arrow-right.svg';
import Button from 'components/button';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-left: ${fluid(10, 40)};
        padding-right: ${fluid(10, 40)};
        margin-top: ${rem(20)};
        background-image: linear-gradient(to bottom, ${p => p.theme.colors.light} 95%, ${p => p.theme.colors.dark} 95%);

        ${mediaUp('tablet')} {
            padding-left: 0;
            padding-right: 0;
            background-image: linear-gradient(to bottom, ${p => p.theme.colors.light} 30%, ${p => p.theme.colors.dark} 30%);
        }
	`,

    Wrapper: styled(Wrapper)`
        ${mediaUp('tablet')} {
            display: grid;
            grid-template-columns: ${fluid(500, 900, 'tablet')} 1fr;
        }
    `,

    Content: styled(motion.div)`
        background-color: ${p => p.theme.colors.primary};
        color: ${p => p.theme.colors.light};
        padding: ${fluid(50, 100)} ${fluid(25, 100)};

        ${mediaUp('tablet')} {
            position: relative;
            padding-right: ${fluid(100, 150, 'tablet')};
            padding-bottom: ${fluid(150, 220, 'tablet')};

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: ${p => p.theme.colors.dark};
                height: ${fluid(80, 105, 'tablet')};
                width: ${fluid(170, 270, 'tablet')};
            }
        }
    `,

    Listing: styled.div`
        text-align: center;
        position: relative;
        padding: ${fluid(40, 180)}
            ${fluid(15, 80)}
            ${fluid(45, 115)}
            ${fluid(15, 170)};

            
        ${mediaUp('tablet')} {
            text-align: initial;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-color: ${p => p.theme.colors.primary};
                height: ${fluid(80, 105, 'tablet')};
                width: ${fluid(80, 105, 'tablet')};
                z-index: 1;
            }
        }
    `,

    Overline: styled.small`
        display: block;
        font-size: ${p => p.theme.fontSizes.normal};
        margin-bottom: ${fluid(15, 40)};
    `,

    Headline: styled.h2`
        font-size: ${p => p.theme.fontSizes.d1};
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: ${fluid(20, 40)};
    `,

    SmallText: styled.p`
        font-size: ${p => p.theme.fontSizes.xlarge};
        line-height: 1.4;
    `,

    FirstDots: styled(RectDots)`
        display: none;

        ${mediaUp('tablet')} {
            display: block;
            position: absolute;
            right: ${rem(30)};
            width: ${fluid(60, 130)};
            height: ${fluid(20, 45)};
            z-index: 1;
            bottom: 100%;
            color: ${p => p.theme.colors.primary};
        }
    `,

    SecondDots: styled(RectDots)`
        display: none;

        ${mediaUp('tablet')} {
            display: block;
            position: absolute;
            right: ${rem(30)};
            top: 0;
            width: ${fluid(60, 130)};
            height: ${fluid(20, 45)};
            transform: scaleY(-1);
            z-index: 1;
            color: ${p => p.theme.colors.light};
        }
    `,

    MobileFirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${rem(30)};
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        z-index: 1;
        bottom: 100%;
        color: ${p => p.theme.colors.primary};

        ${mediaUp('tablet')} {
            display: none;
        }
    `,

    MobileSecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${rem(30)};
        top: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        transform: scaleY(-1);
        z-index: 1;
        color: ${p => p.theme.colors.light};

        ${mediaUp('tablet')} {
            display: none;
        }
    `,

    Job: styled.a`
        display: block;
        position: relative;
        color: ${p => p.theme.colors.light};
        padding-bottom: ${fluid(25, 20)};
        border-bottom: ${rem(2)} solid currentColor;

        &:not(:last-child) {
            margin-bottom: ${fluid(25, 70)};
        }

        &:hover {
            .icon {
                transform: translateX(${rem(5)});
            }
        }
        
        ${mediaUp('tablet')} {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    `,

    JobContent: styled.div`
        flex: 1 1 auto;
        margin-bottom: ${rem(15)};

        ${mediaUp('tablet')} {
            margin-bottom: 0;
        }
    `,

    JobTitle: styled.p`
        font-size: ${fluid(18, 32)};
        margin-bottom: ${fluid(15, 25)};
    `,

    JobRole: styled.p`
        font-size: ${p => p.theme.fontSizes.normal};
        text-transform: uppercase;
    `,

    Icon: styled(ArrowRight)`
        transition: transform ease ${p => p.theme.transitions.normal};
        display: none;

        ${mediaUp('tablet')} {
            display: block;
            width: ${rem(32)};
        }
    `,

    Button: styled(Button)`
        ${mediaUp('tablet')} {
            display: none;
        }
    `,
    
    ButtonWrapper: styled.div`
        ${mediaUp('tablet')}{
            text-align: right;
        }
    `
});