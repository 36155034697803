import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import ArrowRight from 'public/icons/arrow-right.svg'
import HalfCircleDashed from 'public/icons/half-circle-dashed.svg'
import CircledText from 'public/icons/circled-text.svg';
import Button from 'components/button';
import Wrapper from 'components/wrapper';

const s = Object.freeze({
	Section: styled.section`
            position: relative;
            z-index: 1;
            background: ${p => p.theme.colors.dark};
            padding-bottom: ${fluid(35, 150)};
            padding-top: ${fluid(45, 150)};
            color: ${p => p.theme.colors.light};

            &:before {
                  content: '';
                  position: absolute;
                  top: 100%;
                  left: 0;
                  height: ${fluid(150, 300)};
                  width: 100%;
                  background-color: ${p => p.theme.colors.dark};
            }
            
            &:after {
                  content: '';
                  position: absolute;
                  top: 100%;
                  left: 0;
                  height: ${fluid(150, 300)};
                  width: ${fluid(50, 100)};
                  background-color: ${p => p.theme.colors.dark};
                  z-index: 3;
            }
	`,

      Headline: styled(motion.h2)`
            position: relative;
            z-index: 5;
            font-size: ${p => p.theme.fontSizes.d1};
            font-weight: 400;
            margin-bottom: ${fluid(30, 80)};
            text-align: center;
      `,

      Wrapper: styled(Wrapper)`
            ${mediaUp('desktop-large')} {
                  padding-left: 0;
                  padding-right: 0;
            }
      `,

      Post: styled.article`
            display: grid;
            grid-template-columns: auto ${fluid(85, 250, 'phone-xsmall', 'phone')};
            position: relative;
            padding-top: ${fluid(25, 55)};
            padding-bottom: ${fluid(25, 55)};
            border-bottom: ${rem(1)} solid ${p => p.theme.colors.gray('900')};
            gap: ${rem(20)};
            
            ${mediaUp('phone')} {
                  display: block;
                  border-top: ${rem(1)} solid ${p => p.theme.colors.gray('900')};
            }

            &:hover {
                  .figure {
                        opacity: 1;
                  }
            }
      `,

      CoverLink: styled.a`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
      `,

      Author: styled.p`
            text-transform: uppercase;
            margin-bottom: ${fluid(10, 40)};
            font-size: ${p => p.theme.fontSizes.small};

            strong {
                  color: ${p => p.theme.colors.tertiary};
            }
      `,

      Title: styled.h3`
            font-size: ${p => p.theme.fontSizes.h3};
            font-weight: 400;
            max-width: ${fluid(360, 730)};
      `,

      Icon: styled(ArrowRight)`
            display: inline-block;
            width: ${fluid(10, 25)};
            margin-left: ${rem(10)};
      `,

      Content: styled.div``,

      Figure: styled.figure`
            ${mediaUp('phone')} {
                  z-index: 1;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: ${fluid(50, 220, 'phone')};
                  transition: opacity ease ${p => p.theme.transitions.normal};
                  opacity: 0;

                  img {
                        pointer-events: none;
                  }
            }
      `,

      Thumbnail: styled.div`
            pointer-events: none;

            ${mediaUp('phone')} {
                  max-width: ${fluid(190, 395, 'phone')};
            }
      `,

      PostList: styled.div``,

      Button: styled(Button)`
            margin-top: ${fluid(50, 80)};
      `
});

const ArrowRightWrapper = styled.span`
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${fluid(80, 95, 'phone')};
      height: ${fluid(80, 95, 'phone')};
      border-radius: 100%;
      border: ${rem(1)} solid currentColor;
      transition: all ${p => p.theme.transitions.normal} ease;
`;

const StyledArrowRight = styled(ArrowRight)`
      width: ${fluid(30, 35, 'phone')};
      height: ${fluid(30, 35, 'phone')};
`;

const StyledCircledText = styled(CircledText)`
      position: absolute;
      top: ${rem(-30)};
      right: ${rem(-30)};
`;

const StyledHalfCircleDashed = styled(HalfCircleDashed)`
      color: ${p => p.theme.colors.secondary};
      position: absolute;
      left: ${rem(-15)};
      bottom: ${rem(-20)};
      width: ${fluid(130, 165, 'tablet')};
`;

const ReadMore = styled.span`
      display: none;

      ${mediaUp('phone')} {
            cursor: pointer;
            color: ${p => p.theme.colors.light};
            display: flex;
            align-items: center;
            justify-content: center;
            width: ${fluid(90, 135, 'phone')};
            height: ${fluid(90, 135, 'phone')};
            z-index: 2;
            position: absolute;
            right: ${rem(-60)};
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                  ${ArrowRightWrapper} {
                        background: ${p => p.theme.colors.light};
                        color: ${p => p.theme.colors.dark};
                  }
            }
      }
`;

export {
      s,
      StyledHalfCircleDashed,
      StyledCircledText,
      StyledArrowRight,
      ArrowRightWrapper,
      ReadMore
}