import { ThemeProvider } from 'styled-components';
import Reset from 'styles/reset';
import Fonts from 'styles/fonts';
import Globals from 'styles/globals';
import fluid from "../util/fluid";

const theme = {
    colors: {
        primary: '#4608F5',
        secondary: '#EC5BDB',
        tertiary: '#F7C844',
        dark: '#000000',
        light: '#ffffff',
        gray: level => {
            const levels = {
                100: '#F7F7F7',
                200: '#EBEAEA',
                300: '#BCBCBC',
                400: '#949494',
                500: '#716E7C',
                600: '#757575',
                700: '#494949',
                900: '#151515'
            }
    
            return levels[level];
        },
    },
    fonts: {
        text: 'F37Bolton',
        heading: 'F37Bolton'
    },
    fontSizes: {
        d1: fluid(40, 100),
        d2: fluid(30, 85),
        h2: fluid(20, 70),
        h3: fluid(16, 42),
        h4: fluid(16, 32),
        xlarge: fluid(16, 24),
        large: fluid(12, 20),
        normal: fluid(12, 18),
        small: fluid(10, 16)
    },
    gradient: (direction = 'to right') => `linear-gradient(${direction}, #4D00FF -8%, #BD30EB 22%, #FF61BB 52%, #FF8F65 83%, #FFC600 100%);`,
    transitions: {
        slow: '600ms',
        normal: '300ms',
        fast: '100ms'
    },
    wrapper: {
        full: 1920,
        xlarge: 1750,
        large: 1650,
        medium: 1550,
        small: 1280,
        xsmall: 1100
    },
    gutter: fluid(20, 40)
};

export {theme};

export default function Theme({children}) {
    return <ThemeProvider theme={theme}>
        <Reset />
        <Fonts />
        <Globals />
        {children}
    </ThemeProvider>
};
