import sectionsManager from 'util/sections-manager';
import Wrapper from 'components/wrapper';
import s from 'styles/sections/table';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function Table({fields}) {
	const { headline, table } = fields;
	const control = useAnimation();
    const [ref, inView] = useInView();

	useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size='large'>
				{headline ? <s.Headline {...revealLeftVariants} ref={ref} animate={control}>{headline}</s.Headline> : null}
				<s.Scroll>
					<s.Table>
						<s.TableHeader>
							{table.header ? (table.header.map((text, index) => 
								<th key={index}>{text.c}</th>
							)) : null}
						</s.TableHeader>
						{table.body ? (table.body.map((data) => 
							<s.TableRow>
								{data.map((tableBody, index) => (
									<td key={index}>{tableBody.c}</td>
								))}
							</s.TableRow>
						)) : null}
					</s.Table>
				</s.Scroll>
			</Wrapper>
		</s.Section>
	);
}

sectionsManager.register('table', Table);