import Slider from 'components/slider';
import styled from 'styled-components';
import { ProgressBarSliderWrapper, ProgressBarSlider } from 'styles/components/progress-bar';
import { ratio } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import Wrapper from 'components/wrapper';
import {TabsListMobile, Icon} from 'styles/components/tabs';

export default Object.freeze({
	Section: styled.section`
        background: ${p => p.theme.colors.light};
        padding: ${fluid(50, 180)} 0 ${fluid(30, 130)} 0;
	`,

    Wrapper: styled(Wrapper)`
        ${mediaUp('tablet')} {
            margin-left: ${fluid(20, 130, 'tablet')};
            margin-right: auto;
        }

        ${mediaUp('desktop-full')} {
            margin-left: auto;
        }
    `,

    Headline: styled(motion.h2)`
        font-size: ${fluid(50, 180)};
        font-weight: 900;
        text-transform: uppercase;
        color: ${p => p.theme.colors.primary};
        padding-left: ${fluid(20, 40)};
        padding-right: ${fluid(20, 40)};
        margin-bottom: ${rem(25)};

        ${mediaUp('tablet')} {
            padding: 0;
            margin-left: ${rem(-20)};
            margin-bottom: 0;
        }
    `,

    Filter: styled.ul`
        display: none;
    
        ${mediaUp('tablet')} {
            display: block;
        }
    `,

    FilterOption: styled.li`
        cursor: pointer;
        position: relative;
        font-size: ${p => p.theme.fontSizes.xlarge};
        text-transform: uppercase;
        margin-bottom: ${fluid(15, 25)};
        color: ${p => p.theme.colors.gray('500')};

        &:before {
            content: '';
            width: 0;
            height: ${rem(3)};
            position: absolute;
            bottom: ${rem(-5)};
            left: 0;
            background-image: ${p => p.theme.gradient()};
            transition: width ${p => p.theme.transitions.fast} ease;
        }

        &.active::before {
            width: 100%;
        }
    `,

    SliderWrapper: styled.div`
        display: grid;
        align-items: center;

        ${mediaUp('tablet')} {
            grid-template-columns: ${fluid(280, 335, 'tablet')} 1fr;
            column-gap: ${fluid(70, 100, 'tablet')};
        }

        .swiper {
            width: 100%;
        }

        ${ProgressBarSliderWrapper} {
            padding-left: ${fluid(10, 20, 'phone-xsmall', 'tablet')};
            padding-right: ${fluid(10, 20, 'phone-xsmall', 'tablet')};
            
            ${mediaUp('tablet')} {
                padding-left: 0;
                padding-right: ${fluid(20, 130, 'tablet')};
                grid-area: 2/1/2/3;
            }
        }

        ${ProgressBarSlider} {
            margin-top: ${fluid(10, 25)};
        }
    `,

    MemberSliders: styled(Slider)`    
        ${mediaUp('tablet')} {
            transform: translateY(-5%);
        }

        .swiper-wrapper {
            ${mediaUp('tablet')} {
                padding-left: ${fluid(50, 80, 'tablet')};
                margin-left: calc(${fluid(160, 450, 'tablet')} * -1);
            }
        }

        .swiper-slide {
            width: ${fluid(220, 450)};
            margin-right: ${fluid(10, 40, 'phone-xsmall', 'desktop-small')};
            transition: all ${p => p.theme.transitions.normal} ease;
            
            ${mediaUp('desktop-small')} {
                margin-right: ${fluid(50, 100, 'tablet')};
            }

            ${mediaUp('phone')} {
                &:is(.swiper-slide-active, .swiper-slide-next) {
                    margin-top: ${fluid(40, 100, 'phone')};
                }
    
                &:not(.swiper-slide-active) {
                    margin-top: 0;
                    transition-delay: ${p => p.theme.transitions.normal};
                }
            }
        }

        .swiper-nav-wrapper {
            position: absolute;
            display: flex;
            top: 50%;
            left: 80%;
            z-index: 1;
            transform: translate(-50%, -50%);

            ${mediaUp('phone-small')} {
                left: 75%;
            }

            ${mediaUp('phone-medium')} {
                left: 68%;
            }

            ${mediaUp('tablet')} {
                left: ${rem(5)};
                transform: translateY(-50%);
            }
        }
    `,

    FigureWrapper: styled.div``,

    Figure: styled.figure`
        ${ratio(450, 535)};
    `,

    FigCaption: styled.div`
        margin-top: ${fluid(25, 65)};
    `,

    Name: styled.p`
        font-size: ${p => p.theme.fontSizes.h3};
    `,

    Role: styled.p`
        position: relative;
        font-size: ${p => p.theme.fontSizes.small};
        color: ${p => p.theme.colors.gray('500')};
        margin-top: ${fluid(5, 10)};
        text-transform: uppercase;

        &:before {
            content: '';
            display: inline-block;
            background-image: ${p => p.theme.gradient('to top')};
            margin-right: ${fluid(5, 7)};
            width: ${fluid(2, 3)};
            height: ${rem(16)};
        }
    `,

    TabsListMobile: styled(TabsListMobile)`
        margin-left: ${fluid(10, 20, 'phone-xsmall', 'tablet')};
        margin-right: ${fluid(10, 20, 'phone-xsmall', 'tablet')};        
    `,
});
