import styled from 'styled-components';
import {rgba} from 'polished';
import {ratio} from 'styles/mixins';
import Wrapper from 'components/wrapper';
import Picture from 'components/picture';
import ArrowRight from 'public/icons/arrow-right.svg';
import Button from 'components/button';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import {mediaDown, mediaUp} from 'util/media-query';
import fluid from 'util/fluid';

export default Object.freeze({
	Section: styled.section`
        overflow: hidden;
	`,

	PostIntro: styled.div`
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 190px;
            left: 0;
            z-index: -1;
            background: black;

            ${mediaUp('phone')} {
                left: 20px;
            }
        }

        ${p => (!p.display_latest_posts) && `
                  z-index: 2;
                  background: ${p => p.theme.colors.light});
            `}
        ${mediaUp('desktop-medium')} {
            &::after {
                background: linear-gradient(90deg, white 60%, ${p => p.theme.colors.gray('100')} 50%);
            }
        }

        ${mediaUp('desktop-xlarge')} {
            &::after {
                background: linear-gradient(90deg, white 57%, ${p => p.theme.colors.gray('100')} 50%);
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: -2;
            height: 100%;
            background: ${p => p.theme.colors.light});
        }
	`,

	PostIntroWrapper: styled(Wrapper)`
        z-index: 1;
        display: flex;
        flex-flow: column;
        gap: ${fluid(40, 60)};
        color: white;
        padding: ${fluid(40, 60)} 0;

        ${mediaUp('phone')} {
            align-items: center;
        }
	`,

	BackLink: styled.a`
        position: absolute;
        top: 20px;
        left: 30px;
        display: flex;
        align-items: center;
        color: white;
        font-size: ${rem(12)};
        text-decoration: underline;

        ${mediaDown('phone')} {
            display: none;
        }
	`,

	BackLinkIcon: styled.span`
        display: block;
        margin-right: 10px;

        svg {
            height: ${rem(8)};
        }
	`,

	ContentWrapper: styled.div`
        display: flex;
        align-items: flex-start;
        flex-flow: column;
        gap: 30px;
        padding-right: ${fluid(40, 80)};
        padding-left: ${fluid(60, 100)};

        ${mediaUp('phone')} {
            align-items: center;
            padding: 0 15%;
        }

        ${mediaUp('desktop-small')} {
            padding: 0 18%;
        }
	`,

	Meta: styled.div`
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 7px;
        border-radius: 24px;
        background: ${rgba('white', 0.1)}
	`,

	MetaCategory: styled.a`
        display: inline-block;
        padding: 8px 14px 7px;
        color: ${p => p.theme.colors.gray('600')};
        font-size: ${p => p.theme.fontSizes.small};
        font-weight: bold;
        text-transform: uppercase;
        background: white;
        border-radius: 14px;
	`,

	MetaDate: styled.span`
        font-size: ${fluid(12, 14)};
        padding: 0 12px;
	`,

	PostTitle: styled.h2`
        font-size: ${p => p.theme.fontSizes.h3};
        font-weight: 900;
        text-transform: uppercase;
        line-height: 1.25;

        ${mediaUp('phone')} {
            text-align: center;
        }
	`,

	AuthorInfo: styled.div`
        position: relative;
        left: 3px;
        font-size: ${fluid(10, 12)};
        font-weight: bold;
        text-transform: uppercase;

        ${mediaUp('phone')} {
            position: static;
            text-align: center;
        }

        strong {
            color: ${p => p.theme.colors.tertiary};
        }
	`,

	ImageWrapper: styled.div`
        position: relative;
        width: calc(100% - 20px);
        margin: 0 auto;

        ${mediaUp('phone')} {
            width: 85%;
        }
	`,

	Picture: styled(Picture)`
        aspect-ratio: 15 / 9;

        ${mediaUp('phone')} {
            aspect-ratio: 25 / 9;
        }
	`,

	ImageDecoration: styled.span`
        position: absolute;
        top: calc(100% + 10px);
        left: 20px;
        color: black;

        svg {
            width: ${fluid(60, 130)};
            transform: rotateX(180deg) rotateY(180deg);
        }
	`,

	Share: styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: 0 0;
        transform: rotate(90deg);
	`,

	ShareText: styled.span`
        color: ${p => p.theme.colors.gray('400')};
        font-size: ${rem(10)};
        font-weight: bold;
        text-transform: uppercase;
        transform: rotate(90deg);
        display: block;
        margin-bottom: 30px;
        margin-left: -5px;

        ${mediaUp('phone')} {
            font-size: ${rem(12)};
            margin-bottom: 25px;
            margin-left: -1px;
        }
    }
	`,

	ShareLinks: styled.div`
        display: flex;
        align-items: center;
        gap: 12px;
	`,

	ShareLink: styled.a`
        display: block;
        color: white;
        transform: rotate(-90deg);
	`,

	SocialMediaWrapper: styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 135px;
        left: 10px;

        ${mediaUp('phone')} {
            left: 35px;
            top: 100px;
        }

        ${mediaUp('desktop-small')} {
            top: auto;
            bottom: 225px;
        }

        ${mediaUp('desktop-mlarge')} {
            bottom: 275px;
            left: 50px;
        }
	`,

	MediaLink: styled.a`
        display: block;
        color: ${prop => prop.theme.colors.light};
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: relative;

        ${mediaUp('phone')} {
            width: 45px;
            height: 45px;
        }

        &,
        svg {
            transition: all ${prop => prop.theme.transitions.normal} ease;
        }

        svg {
            width: 12px;
            height: 12px;
            position: absolute;
            left: 26%;
            top: 28%;

            ${mediaUp('phone')} {
                width: 20px;
                height: 20px;
            }

        }

        .social-facebook_svg__icn-facebook {
            left: 35%;
            top: 28%;
        }


        &:not(:last-child) {
            margin-right: 0 ${mediaUp('tablet')} {
                margin-right: ${fluid(5, 10)};
            } ${mediaUp('desktop-small')} {
                margin-right: ${fluid(25, 40)};
            }
        }

        &:hover {
            color: ${prop => prop.theme.colors.primary};

            svg {
                transform: translateY(${rem(-5)});
            }
        }
	`,

	PostContent: styled(motion.div)`
        position: relative;
        background: ${p => p.theme.colors.light};
        padding: ${fluid(40, 60)} 0;

        img {
            max-width: 100%;
            object-fit: cover;
            width: auto;
            height: auto;
        }

        ${p => (!p.display_latest_posts) && `
                  background: ${p => p.theme.colors.light});
            `}
        ${mediaUp('desktop-medium')} {
            background: linear-gradient(90deg, white 60%, ${p => p.theme.colors.gray('100')} 50%);
        }

        ${mediaUp('desktop-xlarge')} {
            background: linear-gradient(90deg, white 57%, ${p => p.theme.colors.gray('100')} 50%);
        }
	`,

	AllContentWrapper: styled(Wrapper)`
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0;

        ${mediaUp('desktop-medium')} {
            grid-template-columns: 2fr 1fr;
            padding-left: ${fluid(20, 40)};
            padding-right: ${fluid(20, 40)};
        }

        ${p => (!p.display_latest_posts) && `
                  ${mediaUp('desktop-medium')} {
                        grid-template-columns: 1fr;
                  }
            `}
	`,

	PostContentWrapper: styled.div`
        padding-left: ${fluid(20, 40)};
        padding-right: ${fluid(20, 40)};
        padding-bottom: ${fluid(40, 80)};

        ${mediaUp('phone')} {
            padding-left: ${fluid(110, 120)};
            padding-right: ${fluid(110, 120)};
        }

        ${mediaUp('desktop-medium')} {
            padding-left: ${fluid(160, 180)};
            padding-right: ${fluid(160, 180)};
            padding-bottom: 0;
        }
	`,

	ContentTitle: styled.h2`
        font-size: ${p => p.theme.fontSizes.h2};
        font-weight: 900;
        padding-bottom: ${fluid(25, 50)};

        ${mediaUp('phone')} {
            font-size: ${p => p.theme.fontSizes.h3};
        }
	`,

	Content: styled.div`
        font-size: ${fluid(12, 20)};

        > * {
            line-height: 1.4;
            font-size: ${fluid(14, 16)};

            &:not(:last-child) {
                margin-bottom: ${fluid(20, 32)};
            }

            a {
                color: ${p => p.theme.colors.primary};
                transition: color 300ms;

                &:hover {
                    color: ${p => p.theme.colors.secondary};
                }
            }
        }
		
		.wp-caption {
			width: auto !important;
		}
		
		figcaption {
			font-size: ${fluid(12, 14)};
			padding-top: 10px;
			font-style: italic;
		}
		
		ul {
			list-style-type: disc;
			padding-left: ${rem(20)};
		}
	`,

	FeaturedContentWrapper: styled.div`
        background: ${p => p.theme.colors.gray('100')};
        padding-left: ${fluid(20, 40)};
        padding-right: ${fluid(20, 40)};

        ${mediaUp('phone')} {
            padding-left: ${fluid(110, 120)};
            padding-right: ${fluid(110, 120)};
        }


        ${mediaUp('desktop-medium')} {
            padding-left: 0;
            padding-right: 0;
        }

	`,

	FeaturedColumns: styled.div` `,

	ColumnContentWrapper: styled.div``,

	ColumnImageWrapper: styled.div`
        width: 100%;
	`,

	FeaturedSectionTitle: styled.span`
        display: block;
        font-size: ${p => p.theme.fontSizes.h3};
        padding-top: ${fluid(40, 80)};
        position: relative;


        ${mediaUp('desktop-medium')} {
            padding-top: 0;
            padding-bottom: ${fluid(40, 80)};

            &:after {
                content: '';
                display: block;
                height: ${rem(12)};
                border-top: ${rem(1)} solid ${p => p.theme.colors.gray('400')};
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
	`,

	Column: styled.div`
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        gap: ${fluid(20, 40)};
        position: relative;
        align-items: center;
        padding-top: ${fluid(20, 40)};
        padding-bottom: ${fluid(30, 60)};

        ${mediaUp('tablet')} {
            grid-template-columns: 1fr 1fr;
            padding-top: ${fluid(40, 80)};
        }


        ${mediaUp('desktop-medium')} {
            align-items: flex-start;
        }

        &:first-of-type {
            ${mediaUp('tablet')} {
                padding-top: ${fluid(20, 40)};
            }
        }

        &:after {
            content: '';
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('400')};
            position: absolute;
            bottom: 0;
            width: 100%;
        }
	`,

	ResourceHeader: styled.div`
        display: flex;
        align-items: center;
        font-size: ${p => p.theme.fontSizes.normal};
	`,

	ResourceTitle: styled.a`
        padding-top: ${fluid(10, 20)};
        display: flex;
        align-items: center;
        font-size: ${p => p.theme.fontSizes.xlarge};
        text-decoration: none;
        line-height: 1.5;
        color: ${p => p.theme.colors.dark};

        &:hover {
            text-decoration: underline;

            .arrow-link {
                transform: translateX(${rem(5)});
            }
        }

	`,

	LinkWrapper: styled.span`

        font-size: ${p => p.theme.fontSizes.h3};

        svg {
            display: inline-block;
            vertical-align: middle;
            width: 15px;
            color: ${p => p.theme.colors.dark};

            ${mediaUp('tablet')} {
                width: 20px;
            }
        }

        ${mediaUp('desktop-medium')} {
            font-size: ${p => p.theme.fontSizes.large};
        }
	`,

	ResourceType: styled.span`
        text-transform: uppercase;
        color: ${p => p.theme.colors.gray('400')};
        font-size: ${p => p.theme.fontSizes.normal};
        font-weight: 900;
	`,

	Spacer: styled.div`
        width: 3px;
        height: 16px;
        margin-left: ${fluid(10, 20)};
        margin-right: ${fluid(8, 18)};
        background: linear-gradient(1.79deg, #4D00FF -13.64%, #BD30EB 18.07%, #FF61BB 48.47%, #FF8F65 81.31%, #FFC600 110.61%);
        transform: matrix(1, 0, 0, -1, 0, 0);
	`,

	ResourceDate: styled.span`
        font-size: ${p => p.theme.fontSizes.small};
	`,

	Figure: styled.figure`
        ${ratio(950, 450)};

        ${mediaUp('tablet')} {
            ${ratio(450, 250)};
        }

        ${mediaUp('desktop-medium')} {
            ${ratio(250, 150)};
        }
	`,

	ArrowRight: styled(ArrowRight)`
        margin-left: ${fluid(10, 15, 'tablet')};
        width: ${fluid(10, 20)};
        color: ${props => props.theme.colors.light};
        transition: all ${p => p.theme.transitions.normal} ease-out;
        opacity: 1;

	`,

	Button: styled(Button)`
        padding-top: ${fluid(20, 40)};
        margin-bottom: ${fluid(40, 80)};
	`,
});
