import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import Wrapper from 'components/wrapper';
import Button from 'components/button';
import RectDots from 'public/images/rect-dots.svg';
import StripeAnimation from 'components/stripe-animation';

export default Object.freeze({
    Section: styled.section`
        position: relative;
        z-index: 1;
    `,

    Wrapper: styled(Wrapper)`
        position: relative;
    `,

    Main: styled.div`
        display: grid;
    `,

    Content: styled.div`
        position: relative;
        background: ${p => p.theme.colors.dark};
        padding-left: ${fluid(20, 40)};
        padding-right: ${fluid(20, 40)};
        padding-top: ${fluid(40, 80)};
        display:block;
        padding-bottom: ${rem(25)};
        
        ${mediaUp('tablet')} {
            padding-top: 0;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
            grid-gap: ${rem(160)};
        }
    `,

    ImageWrapper: styled.div`
        z-index: 1;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 80%, ${p => p.theme.colors.dark} 100%);
            z-index: 2;
        }
    `,

    Figure: styled.figure`
        position: relative;
        order: initial;
        max-height: 100%;
        height: 35vh;

        ${mediaUp('phone')} {
            height: 50vh;
        }

        ${mediaUp('desktop-small')} {
            height: 70vh;
        }
    `,

    Headline: styled.h1`
        padding-top: ${rem(50)};
        font-size: ${p => p.theme.fontSizes.d1};
        font-weight: 900;
        position: relative;
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        
        ${mediaUp('desktop-small')} {
            margin-top: ${fluid(50, 100, 'phone-xsmall', 'desktop-small')};
        }
        
        ${mediaUp('phone')} {
            padding-bottom: ${rem(65)};
            padding-left: ${fluid(20, 40)};
        }
    `,

    Body: styled.div`
        position: relative;
        z-index: 1;
        
        ${mediaUp('desktop-small')} {
            display: grid;
            grid-auto-flow: row;
            align-items: center;
            column-gap: ${fluid(35, 100, 'tablet')};
        }
    `,

    DescriptionWrapper: styled.div`
        padding-right: ${fluid(40, 80)};
        padding-top: ${fluid(20, 40)};

        &:before {
            content: '';
            margin-bottom: ${rem(5)};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.light};
            border-left: ${rem(1)} solid ${p => p.theme.colors.light};
        }

        ${mediaUp('desktop-small')} {
            margin-top: ${fluid(25, 50)};
        }

        ${mediaUp('phone')} {
            padding-top: ${rem(65)};
            padding-left: ${fluid(20, 40)};
        }
    `,

    Description: styled.div`
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.normal};
        line-height: 1.4;

        a {
            color: ${p => p.theme.colors.light};
            text-decoration: underline;
        }

        ${mediaUp('phone')} {
            font-size: ${p => p.theme.fontSizes.xlarge};
        }
    `,

    ButtonWrapper: styled.div`
        text-align:left;
        margin-top: ${rem(20)};
        padding-bottom: ${rem(30)};

        ${mediaUp('phone')} {
            padding-bottom: ${rem(50)};
            padding-left: ${fluid(20, 40)};
            padding-top: ${fluid(20, 40)};
        }
    `,

    Button: styled(Button)``,

    FirstDots: styled(RectDots)`
        display: none;
        position: absolute;
        left: ${fluid(30, 80)};
        top: calc(100% + ${rem(5)});
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.dark};
        z-index: 1;
        transform: scale(-1, -1);

        ${mediaUp('phone')} {
            display: block;
        }
    `,

    SecondDots: styled(RectDots)`
        display: none;
        position: absolute;
        left: ${fluid(30, 80)};
        bottom: ${rem(5)};
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        z-index: 1;
        transform: scaleX(-1);

        ${mediaUp('phone')} {
            display: block;
        }
    `,

    Stripe: styled(StripeAnimation)`
        right: 0;
        bottom: 0;
        transform: scaleY(-1);
        transform-origin: right bottom;
        z-index: 2;
    `,

});