import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/text-columns';
import Image from 'next/image';
import Wrapper from 'components/wrapper';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function TextColumns({fields}) {
    const {heading, text, cta, columns, image, image_position, columns_size } = fields;
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (
		<s.Section id={fields.anchor_id}>
			<Wrapper size={'full'}>
				<s.Content imagePosition={image_position}>
					<s.Figure className='figure'>
						{image.url && <Image src={image.url} alt={image.alt} layout='fill' />}
					</s.Figure>
					<s.TextWrapper className='text-wrapper' {...revealLeftVariants} ref={ref} animate={control}>
						{heading ? (<s.Title dangerouslySetInnerHTML={{__html: heading}} />) : null}
						{text ? (<s.Text dangerouslySetInnerHTML={{__html: text}} />) : null}
						{cta.title ? (<s.Button
							styleName={'dark'}
							href={cta.url}
							target={cta.target}
							iconName={'arrow-right'}>
							{cta.title}
						</s.Button>) : null}
					</s.TextWrapper>
				</s.Content>
			</Wrapper>
			<s.ColumnContent>
				<s.ContentWrapper>
					{columns.length && (
					<s.ColumnWrapper size={'medium'} gridSize={columns_size}>
						{columns.map((item, itemIndex) => (
							<s.Column key={itemIndex}>
								{item.title && (<s.ColumnTitle>{item.title}</s.ColumnTitle>)}
								{item.text && (<s.ColumnText dangerouslySetInnerHTML={{ __html: item.text }} />)}
							</s.Column>
						))}
					</s.ColumnWrapper>)}
				</s.ContentWrapper>
			</s.ColumnContent>
		</s.Section>
	);
}

sectionsManager.register('text-columns', TextColumns);