import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { ratio } from 'styles/mixins';
import Button from 'components/button';
import wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';
import Pipe from 'public/images/pipes.svg';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        z-index: 1;
        padding-bottom: ${fluid(50, 130)};
        padding-top: ${fluid(20, 130)};
        background-color: ${p => p.theme.colors.dark};
    `,
    
    TopDecoration: styled(Pipe)`
        position: absolute;
        top: 0;
        right: 0;
        width: max-content;
        height: fit-content;
        opacity: 0.15;
        pointer-events: none;
    `,
    
    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        bottom: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.dark};
        z-index: 1;
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        top: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.light};
        transform: scaleY(-1);
        z-index: 1;
    `, 

    DecorativeText: styled.h2`
        position: relative;
        z-index: 1;
        font-size: ${fluid(45, 200)};
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        font-weight: 900;

        span {
            display: block;

            &:nth-child(even) {
                text-align: right;
            }
        }
    `,

    Wrapper: styled(wrapper)`
        ${mediaDown('phone')} {
            padding-left: ${fluid(10, 20, 'phone-xsmall', 'phone')};
            padding-right: ${fluid(10, 20, 'phone-xsmall', 'phone')};
        }
    `,

    Content: styled.div`
        display: grid;
        grid-template-columns: 1fr;
        row-gap: ${rem(35)};

        ${mediaUp('phone')} {
            grid-template-columns: ${fluid(360, 735, 'phone')} 1fr;
            column-gap: ${fluid(80, 200, 'phone')};
        }

        ${p => (p.imagePosition === 'right') && `
        > div {
            order: 1;
        }

        figure {
            order: 2;
        }
        `}
    `,

    Text: styled.div`
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.h3};
        line-height: 1.4;
        margin-top: ${fluid(25, 50, 'phone-xsmall', 'phone')};

        strong {
            color: ${p => p.theme.colors.tertiary};
        }

        ${mediaUp('phone')} {
            margin-top: ${fluid(80, 170)};
        }
    `,

    TextWrapper: styled.div`
        padding-left: ${fluid(10, 20, 'phone-xsmall', 'phone')};
        padding-right: ${fluid(10, 20, 'phone-xsmall', 'phone')};

        ${mediaUp('phone')} {
            padding-left: 0;
            padding-right: 0;
        }
    `,

    ButtonWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(35)};
        margin-top: ${fluid(25, 80)};
    `,

    Button: styled(Button)`
    `,

    Figure: styled.figure`
        ${ratio(735, 870)};
        position: relative;

        ${p => p.pullTop && `
        margin-top: ${rem(-20)};

        ${mediaUp('phone')} {
            margin-top: ${rem(-115)};
        }
        ${mediaUp('desktop-small')} {
            margin-top: ${rem(-230)};
        }
        `}
    `,

    FigCaption: styled.figcaption`
        position: absolute;
        max-width: ${rem(580)};
        width: 90%;
        left: 50%;
        bottom: ${fluid(5, 70)};
        color: ${p => p.theme.colors.light};
        font-size: ${p => p.theme.fontSizes.large};
        transform: translateX(-50%);
        line-height: 1.4;

        ${mediaUp('phone')} {
            width: 80%;
        }

        &:before {
            content: '';
            margin-bottom: ${fluid(20, 45, 'phone')};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${props => props.theme.colors.light};
            border-left: ${rem(1)} solid ${props => props.theme.colors.light};
        }
    `
});