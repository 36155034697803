function buildSectionsManager () {
	const sections = {};

	return Object.freeze({
		register,
		get,
	});

	function register (name, Component) {
		sections[name] = Component;
	}

	function get (name) {
		return sections[name];
	}
}

export default buildSectionsManager();
