import s from 'styles/components/address-form';
import {setSessionStorage} from 'providers/sessionStorage';
import { useRef, useEffect, useState } from 'react';
import useModals from 'providers/useModals';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default function AddressForm ({fieldText = '', buttonText = '', buttonStyles, placeholder= 'Enter property address'}) {
	const geocoderRef = useRef();
	const modals = useModals();
	const geocoderContainerId = `geocoder-container-${Math.random().toString(36).substr(2, 9)}`;
	const [address, setAddress] = useState('');	 

	useEffect(() => {
		const geocoder = new MapboxGeocoder({
			accessToken: 'pk.eyJ1IjoiY3NjaGxvZXIiLCJhIjoiY2pkZjBuY3FzMDI2aDJ3bzAzdTR5cDNtaiJ9.azhbou2TXu-rpUmUF7TZFw',
			mapboxgl: window.mapboxgl,
			marker: false,
			countries: 'us',
			types: 'address,postcode',
			placeholder: placeholder,
		});

		geocoder.addTo(`#${geocoderContainerId}`);

		geocoder.on("result", function (result) {
			setAddress(result);
		})
	}, []);

	// Editing URL, The server that receives this request can then extract this information from the URL parameters to track the source of the submission.
	const triggerExternalIntake = address => {
    address = encodeURIComponent(address);
    const submissionSource = encodeURIComponent('WEBSITE');
    window.open(`https://upgrade.blocpower.io/?address=${address}&submission_source=${submissionSource}`, "_self");
	}

	const onSubmit = event => {
		event.preventDefault();

		const {value} = geocoderRef.current.querySelector('input');
		if (address) triggerExternalIntake(value);
	};

	return <s.Form onSubmit={onSubmit}>
		<div ref={geocoderRef} className="geocoder" id={geocoderContainerId}>
			<s.Label>{fieldText}</s.Label>
		</div>

		<s.Submit
			styleName={buttonStyles}
			type="submit"
			iconName="arrow-right"
		>
			{buttonText}
		</s.Submit>
	</s.Form>;
}