import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { ratio } from 'styles/mixins';
import wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';
import ArrowRight from 'public/icons/arrow-right.svg';
import Pipe from 'public/images/pipes.svg';



export default Object.freeze({
	Section: styled.section`
            position: relative;
            padding-top: ${fluid(80, 100)};
            padding-bottom: ${fluid(50, 100)};
            background: ${p => p.theme.colors.dark};

            ${mediaUp('desktop-small')}  {
                  margin-left: ${rem(40)};
            }      
	`,

      TopDecoration: styled(Pipe)`
            display: none;

            ${mediaUp('tablet')} {
                  display: block;
                  position: absolute;
                  top: -75px;
                  right: 0;
                  width: max-content;
                  height: fit-content;
                  opacity: 0.15;
                  pointer-events: none;
            }
      `,

      FirstDots: styled(RectDots)`
            display: block;
            position: absolute;
            left: ${rem(20)};
            bottom: ${rem(10)};
            width: ${fluid(60, 130)};
            height: ${fluid(20, 45)};
            z-index: 1;
            transform: scaleX(-1);
            color: ${p => p.theme.colors.light};

            ${mediaUp('tablet')} {
                  left: ${rem(10)};
            }
      `,

      Wrapper: styled(wrapper)``,

      Content: styled.div`
            display: grid;
            grid-template-columns: 1fr;
            row-gap: ${rem(35)};
            align-items: center;

            ${mediaUp('tablet')} {
                  grid-template-columns: repeat(6, 1fr);
                  grid-gap: 5px;
            }

      `,

      MainInfo: styled.span`
            background: #191919;
            width: fit-content;
            padding-top: 10px;
            padding-bottom: 12px;
            padding-left: 5px;
            padding-right: 10px;
            border-radius: 85px;

            ${mediaUp('tablet')} {
                  padding-top: 15px;
                  padding-bottom: 15px;
                  padding-left: 5px;
                  padding-right: 35px;
            }
      `,

      MainInfoType: styled.span`
            text-transform: uppercase;
            background: ${p => p.theme.colors.light};
            border-radius: 85px;
            margin-right: ${fluid(10, 20)};
            font-size: ${p => p.theme.fontSizes.small};
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 15px;
            padding-right: 15px;
            color: ${p => p.theme.colors.gray('600')};

      `,

      MainInfoDate: styled.span`
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.small};
      `,

      TextWrapper: styled.div`
            z-index: 1;

            ${mediaUp('tablet')} {
                  grid-column: 1 / 4; 
                  grid-row: 1;
                  padding-left: ${fluid(20, 40)};
            }
      `,

      Title: styled.div`
            font-size: ${fluid(20, 50)};
            font-weight: 900;
            color: ${p => p.theme.colors.light};
            padding-top: ${fluid(30, 60)};
            text-transform: uppercase;
            line-height: 1.5;
            max-width: ${rem(800)};

            strong {
                  color: ${p => p.theme.colors.tertiary};
            }

            ${mediaUp('tablet')} {
                  padding-top: ${fluid(40, 80)};
            }

      `,

      Text: styled.div`
            font-size: ${p => p.theme.fontSizes.normal};
            color: ${p => p.theme.colors.light};
            margin-top: ${fluid(25, 50)};
            line-height: 1.5;

            strong {
                  color: ${p => p.theme.colors.tertiary};
            }

            &:before {
                  content: '';
                  margin-bottom: ${rem(5)};
                  display: block;
                  height: ${rem(12)};
                  border-top: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
                  border-left: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
                  margin-bottom:${fluid(10, 20)};

                  
            }

            ${mediaUp('tablet')} {
                  width: 50%;
                  line-height: 1.5;
            }
      `,

      ImageWrapper: styled.div`
            position: relative;

            ${mediaUp('tablet')} {
                  grid-column: 3 / 5; 
                  grid-row: 1;
            }
      `,

      Figure: styled.figure`
            ${ratio(768, 800)};

            ${mediaUp('phone')} {
                  ${ratio(1024, 700)};
            }

            ${mediaUp('tablet')} {
                  ${ratio(735, 970)}; 
            }
      `,

      FeaturedWrapper: styled.div`
            ${mediaUp('tablet')} {
                  padding-left: ${rem(50)};
                  grid-column: 5 / 7; 
                  grid-row: 1;
            }
      `,

      FeaturedLabel: styled.span`
            color: ${p => p.theme.colors.gray('600')};
            font-size: ${p => p.theme.fontSizes.normal};
            text-transform: uppercase;
            padding-bottom:${fluid(25, 50)};
            display: block;
      `,

      ButtonWrapper: styled.div`
            position: absolute;
            bottom: 0;
            left: 0px;
            background: ${p => p.theme.colors.dark};
            padding-top: ${rem(20)};
            padding-right: ${rem(30)};
      `,

      RowWrapper: styled.div`
      
      `,

      Row: styled.div`
            color: ${p => p.theme.colors.light};

            &:after {
                  content: '';
                  display: block;
                  height: ${rem(12)};
                  border-top: ${rem(1)} solid ${p => p.theme.colors.gray('700')};

                  ${mediaUp('tablet')} {
                        border-left: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
                        border-top: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
                        margin-bottom: ${rem(5)};
                  }
            }

            ${mediaUp('tablet')} {
                  padding-top:${fluid(10, 20)};
                  padding-bottom:${fluid(10, 20)};
            }
      `,

      ResourceHeader: styled.div`
            display: flex;
            align-items: center;
            font-size: ${p => p.theme.fontSizes.small};

            ${mediaUp('tablet')} {
                  font-size: ${p => p.theme.fontSizes.normal};
            }
      `,

      ResourceTitle: styled.a`
            padding-top:${fluid(20, 40)};
            padding-bottom:${fluid(20, 40)};
            display: flex;
            align-items: center;
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.xlarge};
            line-height: 1.5;

            &:hover {
                  text-decoration: underline;
                  
                  .arrow-link {
                    transform: translateX(${rem(5)});
                  }
            }

            ${mediaUp('tablet')} {
                  text-decoration: none; 
                  padding-top:${fluid(15, 30)};
                  padding-bottom:${fluid(15, 30)};
            }

      `,

      LinkWrapper: styled.span`
            svg {
                  display: inline-block;
                  vertical-align: middle;
                  width: ${fluid(15, 20)};
            }
      `,

      ResourceType: styled.span`
            text-transform: uppercase;
      `,

      Spacer: styled.div`
            width: 3px;
            height: 16px;
            margin-left: ${fluid(10, 20)};
            margin-right: ${fluid(8, 18)};
            background: linear-gradient(1.79deg, #4D00FF -13.64%, #BD30EB 18.07%, #FF61BB 48.47%, #FF8F65 81.31%, #FFC600 110.61%);
            transform: matrix(1, 0, 0, -1, 0, 0);
      `,

      ResourceDate: styled.span`
      `,

      ArrowRight: styled(ArrowRight)`
            margin-left: ${fluid(10, 15, 'tablet')};
            width: ${fluid(10, 20)};
            color: ${props => props.theme.colors.light};
            transition: all ${p => p.theme.transitions.normal} ease-out;
            opacity: 1;
            
      `,

});