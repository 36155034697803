import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaDown, mediaUp } from 'util/media-query';
import rem from 'util/rem';
import { motion } from 'framer-motion';
import { ratio } from 'styles/mixins';
import Button from 'components/button';
import wrapper from 'components/wrapper';
import RectDots from 'public/images/rect-dots.svg';

export default Object.freeze({
	Section: styled.section`
        position: relative;
        padding-top: ${fluid(50, 130)};
        padding-bottom: ${fluid(50, 130)};
    `,

    FirstDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        top: 100%;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        color: ${props => props.theme.colors.secondary};
        z-index: 1;
        transform: scaleY(-1);
    `,

    SecondDots: styled(RectDots)`
        display: block;
        position: absolute;
        right: ${fluid(30, 80)};
        bottom: 0;
        width: ${fluid(60, 130)};
        height: ${fluid(20, 45)};
        z-index: 1;
        color: ${props => props.theme.colors.light};
    `,

    Wrapper: styled(wrapper)``,

    Content: styled(motion.div)`
        display: grid;
        grid-template-columns: 1fr;
        row-gap: ${rem(35)};

        ${mediaUp('phone-medium')} {
            grid-template-columns: 1fr 1fr;
            column-gap: ${fluid(80, 300, 'phone-medium')};
        }
    `,

    Text: styled.div`
        font-size: ${p => p.theme.fontSizes.xlarge};
        margin-top: ${fluid(25, 50)};
        line-height: 1.4;

        strong {
            color: ${p => p.theme.colors.secondary};
        }
        
        ${mediaUp('tablet')} {
            margin-left: ${rem(60)};
        }
    `,
    
    Title: styled.div`
        font-size: ${p => p.theme.fontSizes.h2};
        margin-top: ${fluid(25, 50, 'phone-xsmall', 'phone-medium')};

        strong {
            color: ${p => p.theme.colors.primary};
            font-weight: 400;
        }

        ${mediaUp('phone-medium')} {
            margin-top: ${fluid(80, 170)};
        }
    `,

    TextWrapper: styled.div`    
        ${mediaUp('desktop-small')} {
            max-width: ${rem(765)};
        }
    `,

    ButtonWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
        gap: ${rem(35)};
        margin-top: ${fluid(25, 65)};

        ${mediaUp('tablet')} {
            margin-left: ${rem(60)};
        }
    `,

    Button: styled(Button)`
    `,

    Figure: styled.figure`
        ${ratio(710, 800)};
    `,
});