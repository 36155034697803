import sectionsManager from 'util/sections-manager';
import s from 'styles/sections/hero-4';
import Image from 'next/image';
import animationData from 'animations/stripe-animation-2.json' assert {type: 'json'};
import AddressForm from 'components/address-form';

function Hero4 ({fields}) {
	const {cta, headline, image, small_text, cta_type} = fields;

	return (
		<s.Section id={fields.anchor_id}>
			<s.Wrapper size="full" noPadding={true}>
				<s.Main>
					<s.Content>
						{headline ? <s.Headline className="headline">{headline}</s.Headline> : null}
						<s.Body className="body">
							{small_text ? (<s.DescriptionWrapper>
								<s.Description dangerouslySetInnerHTML={{__html: small_text}}/>
							</s.DescriptionWrapper>) : null}
							<s.ButtonWrapper>
								{cta_type === 'address_input' ? <>
									<AddressForm
										fieldText={'Get a custom report for your property'}
										buttonText={'Get Report'}
										buttonStyles={'light'}
									/>
								</> : null}

								{cta_type === 'link' ? <>
									{cta.title && (<s.Button
										styleName={'light'}
										href={cta.url}
										target={cta.target}
										iconName={'arrow-right'}>
										{cta.title}
									</s.Button>)}
								</> : null}
							</s.ButtonWrapper>
						</s.Body>
						<s.Stripe animationData={animationData}/>
					</s.Content>
					<s.ImageWrapper>
						<s.Figure className="figure">
							<Image
								src={image.url}
								alt={image.alt}
								placeholder="blur"
								blurDataURL={image.url}
								layout="fill"
								objectFit="cover"
							/>
						</s.Figure>
					</s.ImageWrapper>
				</s.Main>
				<s.FirstDots/>
				<s.SecondDots/>
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('hero-4', Hero4);